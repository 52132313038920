/* eslint-disable no-useless-escape */
import moment from "moment";
import "moment-timezone";
import { SimpleObjectSelect } from "../interfaces/reservation";
import PaymentStatus from "../constants/PaymentStatus";
import {RenderTree} from "../pages/dashboard/product/product-categories";

export const generateUniqueID = (menu: string) => {
  return `${menu}-${Date.now()}-${
    Math.floor(Math.random() * (9e12 - 1)) + 1e12
  }-${Math.floor(Math.random() * (9e12 - 1)) + 1e12}`;
};

export const formatDateTime = (date: string, hasHour: boolean = false) => {
  return hasHour
    ? moment(date).format("DD-MMM-YYYY@hh:mm")
    : moment(date).format("DD-MMM-YYYY");
};

export const formatDateTimeDay = (date: string, hasHour: boolean = false) => {
  return hasHour
    ? moment(date).format("dddd, DD/MMM/YYYY hh:mm")
    : moment(date).format("dddd, DD/MMM/YYYY");
};

export const convertUTCtoLocalTime = (
  date: string,
  format: string = "DD-MMM-YYYY @ HH:mm"
) => {
    const tz = localStorage.getItem("timeZone") ??
  Intl.DateTimeFormat().resolvedOptions().timeZone;
  const options = {
    year: 'numeric',
     month: 'numeric',
      day: 'numeric',
    hour: 'numeric',
     minute: 'numeric',
      second: 'numeric',
    hour12: false,
    timeZone: tz
  };

  const strUTC = new Date(new Date(date).toLocaleString("en-US", {timeZone:  "UTC"})).toISOString()
  // return new Intl.DateTimeFormat('en-US', {dateStyle: 'short', timeStyle: 'short', hour12: true, timeZone:  tz}).format(new Date(strUTC))
  // return moment(new Date((typeof date === "string" ? new Date(strUTC) : strUTC).toLocaleString("en-US", {timeZone:  tz}))).format(format);



  return moment
    .utc(date)
    .tz(
      localStorage.getItem("timeZone") ??
        Intl.DateTimeFormat().resolvedOptions().timeZone
    )
    .format(format);
};

export const getDateTimeOrNull = (data: string | null) => {
  if (data == null) return null;
  return convertUTCtoLocalTime(data, "YYYY-MM-DD");
};

export const priceFormat = (price: number) => {
  return new Intl.NumberFormat("en-IN").format(price);
};

export const getReservationType = (key?: string) => {
  if (key) return key === "QUOTE" ? "QUOTATION" : "BOOKING";
  return "";
};

export const getCurrentDate = () => {
  return moment()
    .tz(localStorage.getItem("timeZone") ?? "UTC")
    .format("DD-MMM-YYYY");
};
export const makeSlug = (str: string) => {
  str = str
    .replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, " ")
    .toLowerCase();

  str = str.replace(/^\s+|\s+$/gm, "");

  str = str.replace(/\s+/g, "-");

  return str;
};

export const makeCamelCase = (str: string) => {
  // converting all characters to lowercase
  let ans = str.toLowerCase();

  // Returning string to camelcase
  return ans
    .split(" ")
    .reduce((s, c) => s + (c.charAt(0).toUpperCase() + c.slice(1)));
};

export function makeTitle(slug: string) {
  var words = slug.split("_");

  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(" ").replace(/&amp;/g, "&");
}

export function makeTitleProductType(slug: string) {
  var words = slug.split("-");

  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(" ").replace(/&amp;/g, "&");
}
export function buildQueryString(object: any) {
  if (typeof object !== "object") return "";
  const args: any = [];
  for (const key in object) {
    destructure(key, object[key]);
  }
  return args.join("&");

  function destructure(key: any, value: any) {
    if (key && (value || value === false || value === 0)) {
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          destructure(key + "[" + i + "]", value[i]);
        }
      } else if (String(value) === "[object Object]") {
        for (const i in value) {
          destructure(key + "[" + i + "]", value[i]);
        }
      } else
        args.push(
          encodeURIComponent(key) +
            (value !== null && value !== "" && value !== undefined
              ? "=" + encodeURIComponent(value)
              : "")
        );
    }
  }
}

// export function parseParams(queryString: string = "", options: any = {}) {
//   let queryObject: any = {};
//   queryString && decodeURIComponent(queryString.replace('?', '')).split('&').map((itemString) => {
//     let [itemKey, itemValue] = itemString.split("=");
//     if (options.hasOwnProperty(itemKey)) {
//       if (!queryObject[itemKey] && Array.isArray(options[itemKey])) {
//         queryObject[itemKey] = [];
//       }
//       Array.isArray(options[itemKey]) ? queryObject[itemKey].push(itemValue) : queryObject[itemKey] = typeof options[itemKey] === "number" ? parseInt(itemValue) : itemValue;}
//   });
//   return queryObject;
// };
export const parseParams = (querystring: string) => {
  const params: any = new URLSearchParams(querystring);
  const obj: any = {};
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      if (params.get(key) !== "undefined") obj[key] = params.getAll(key);
    } else {
      if (params.get(key) !== "undefined") obj[key] = params.get(key);
    }
  }

  return obj;
};



export function getProductNameByType(type: string) {
  switch (type) {
    case "tour":
      return "TOUR";
    case "golf":
      return "GOLF PACKAGE";
    case "cruise":
      return "CRUISE";
    case "sightseeing-activity":
      return "SIGHTSEEING ACTIVITY";
    case "package":
      return "PACKAGE";
    case "insurance":
      return "TRAVEL INSURANCE";
    case "visa":
      return "VISA";
      case "accommodation":
        return "ACCOMMODATION";
      case "transfer":
        return "TRANSFER";
      case "flight":
        return "FLIGHT";
      case "other":
        return "OTHER SERVICE";
        case "safety-net":
          return "SAFETY NET";
    default:
      return "UNDEFINED";
  }
}

export function getCapitalType(type: string) {
  switch (type) {
    case "tour":
      return "Tour";
    case "golf":
      return "Golf Package";
    default:
      return "undefined";
  }
}

export function getValueFromCapitalizeString(string: string) {
  return string
    ?.replaceAll("_", " ")
    ?.trim()
    ?.toLowerCase()
    ?.split(",")
    ?.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(", ");
}

export const getValueString = (data: string | undefined | null) => {
  return data === null || data === undefined ? "" : data;
};

export const isEmptyVar = (data: any) =>
  data === null || data === undefined || !data;

export const getArrStringFromArrContent = (
  data: { content?: string | null }[] | null | undefined
) => {
  return data
    ? data
        .map((item) => {
          return item.content && item.content !== "false" ? item.content : "";
        })
        .filter((item) => item)
    : [];
};

export function makeid(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function randomString(length: number) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function randomNumber(length: number) {
  let result = "";
  const characters = "0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const getPaymentMethodValue = (
  paymentMethod: SimpleObjectSelect[],
  jsonValue: string | null | undefined
) => {
  try {
    if (jsonValue == null || undefined) return null;
    let jsonStringArr: string[] = JSON.parse(jsonValue);
    if (jsonStringArr.length > 1)
      return paymentMethod.find(
        (item) => item.label === "Multi Payment Method"
      );
    return paymentMethod.find((item) => item.label === jsonStringArr[0]);
  } catch (e) {
    return paymentMethod.find((item) => item.label === jsonValue);
  }
};

export const getCurrentUser = () => {
  let userLocal = localStorage.getItem("user");
  if (userLocal) {
    return JSON.parse(userLocal);
  }
  return null;
};

export const checkPaymentStatus = (paymentStatus: string) => {
  let OUTSTANDING = [
    PaymentStatus.OUTSTANDING_TOTAL,
    PaymentStatus.OUTSTANDING_1st_DEPOSIT,
    PaymentStatus.OUTSTANDING_2nd_DEPOSIT,
    PaymentStatus.OUTSTANDING_3rd_DEPOSIT,
    PaymentStatus.OUTSTANDING_4th_DEPOSIT,
    PaymentStatus.OUTSTANDING_5th_DEPOSIT,
    PaymentStatus.OUTSTANDING_BALANCE,
  ];
  let DEPOSIT = [
    PaymentStatus._1st_DEPOSIT_PAID,
    PaymentStatus._2nd_DEPOSIT_PAID,
    PaymentStatus._3rd_DEPOSIT_PAID,
    PaymentStatus._4th_DEPOSIT_PAID,
    PaymentStatus._5th_DEPOSIT_PAID,
    PaymentStatus._1st_DEPOSIT_OUTSTANDING_PAID,
    PaymentStatus._2nd_DEPOSIT_OUTSTANDING_PAID,
    PaymentStatus._3rd_DEPOSIT_OUTSTANDING_PAID,
    PaymentStatus._4th_DEPOSIT_OUTSTANDING_PAID,
    PaymentStatus._5th_DEPOSIT_OUTSTANDING_PAID,
  ];
  // let PAID = [PaymentStatus.FULLY_PAID, PaymentStatus.BALANCE_PAID]
  if (paymentStatus === PaymentStatus.UNPAID) return "UNPAID";
  if (OUTSTANDING.includes(paymentStatus)) return "OUTSTANDING";
  if (DEPOSIT.includes(paymentStatus)) return "DEPOSIT";
  return "PAID";
};

export const getColorPaymentStatus = (
  paymentStatus: "OUTSTANDING" | "DEPOSIT" | "PAID" | "UNPAID"
) => {
  if (paymentStatus === "OUTSTANDING") return "#ffa500";
  if (paymentStatus === "UNPAID") return "#ff0000";
  if (paymentStatus === "DEPOSIT") return "#800080";
  return "#000000";
};

export const isShowGolf = () => {
  const companyCode = localStorage.getItem("companyCode") as string;
  return process.env.REACT_APP_PARTNER_COMPANY?.split(",")?.includes(
    companyCode
  )
    ? true
    : false;
};
export const checkHasData = (data: string) => {
  if(!data) {return false}
  if(data === "[]") {return false}
  return true
};
export const convertMinToHour = (mins: number) => {
  if(!mins) return ``
  if(mins <= 60) {
    return `${mins} mins`
  } else {
    let hours = Math.floor(mins/60);
    let remainMins = mins - hours*60;
    return `${hours} hrs ${remainMins ? `${remainMins} mins` : ''}`;
  }
};
export const convertHrsToDays = (hours: number) => {
  if(!hours) return ``
  if(hours <= 24) {
    return `${hours} hrs`
  } else {
    let days = Math.floor(hours/24);
    let remainHrs = hours - days*24;
    return `${days} days ${remainHrs ? `${remainHrs} hrs` : ''}`;
  }
};
export function toNested(data: RenderTree[], parentId = null) {
  return data.reduce((r: RenderTree[], e: any) => {
    if (parentId === e.parentId) {
      const object = { ...e };
      const children = toNested(data, e.id);

      if (children.length) {
        object.children = children;
      }

      r.push(object);
    }

    return r;
  }, []);
}


export const lowercaseLetter = (string: string) => {
  return string[0] + string.slice(1).toLowerCase();
}

export const getCompanyId = () => {
  try{
    return JSON.parse(localStorage.getItem("user") ?? JSON.stringify({})).companyId;
  }catch (e){
    return null;
  }
}

export const decodeJson =(string: string | null) => {
  if (!string) {
    return [];
  }
  return JSON.parse(string);
}

