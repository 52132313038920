import { createContext, useContext } from "react";

export interface CompanyEditModalProps {
  companyId: number | null;
  openEditUser: boolean;
  showDetail: boolean;
  isEditButton: boolean;
}
export interface ContactEditModalProps {
  contactId: number | null;
  openEditUser: boolean;
  showDetail: boolean;
  isEditButton: boolean;
  editInReservation: boolean;
  getListContact: () => void;
}

export interface PackageProgramProps {
  packageItem: {
    id: number;
    name: string;
  } | null;
  isOpenPackageProgram: boolean;
  isOpenEditProgram: boolean;
  disabled: boolean;
  type: string;
  reservation: {
    id: number;
    reference: string;
  } | null;
  showActionButtons: boolean;
}
export interface PackagePurchaseProgramProps {
  packageItem: {
    id: number;
    name: string;
  } | null;
  isOpenPackageProgram: boolean;
  isOpenEditProgram: boolean;
  disabled: boolean;
  type: string;
  reservation: {
    id: number;
    reference: string;
  } | null;
  showActionButtons: boolean;
}
export type GlobalContent = {
  // product type
  productType: {
    label: string;
    value: string;
  }[];
  setProductType: (
    c: {
      label: string;
      value: string;
    }[]
  ) => void;
  // language
  language: {
    label: string;
    value: string;
  };
  setLanguage: (c: { label: string; value: string }) => void;
  // currency
  currency: {
    label: string;
    value: string;
  };
  setCurrency: (c: { label: string; value: string }) => void;
  companyLogo: string;
  companyName: string;
  setCompanyLogo: (logo: string) => void;
  setCompanyName: (name: string) => void;

  // theme
  mode: string;
  setMode: (m: string) => void;

  // chart User
  openChartUser: boolean;
  setOpenChartUser: (m: boolean) => void;

  // user guide
  userGuide: boolean;
  setUserGuide: (m: boolean) => void;

  // company edit modal
  companyEditModal: CompanyEditModalProps;
  setCompanyEditModal: (b: CompanyEditModalProps) => void;

  // contact edit modal
  contactEditModal: ContactEditModalProps;
  setContactEditModal: (b: ContactEditModalProps) => void;

  showPackageProgram: PackageProgramProps;
  setShowPackageProgram: (b: PackageProgramProps) => void;
  showPackagePurchaseProgram: PackagePurchaseProgramProps;
  setShowPackagePurchaseProgram: (b: PackagePurchaseProgramProps) => void;

  newNotification: boolean;
  setNewNotification: (b: boolean) => void;
};

export const MyGlobalContext = createContext<GlobalContent>({
  productType: [
    {
      label: "Tour",
      value: "tour",
    },
    {
      label: "Golf Package",
      value: "golf-package",
    },
  ],
  setProductType: () => { },
  language: {
    label: "English",
    value: "en",
  },
  setLanguage: () => { },
  currency: {
    label: "USD",
    value: "$",
  },
  setCurrency: () => { },
  companyLogo: localStorage.getItem("companyLogo") ?? "",
  companyName: localStorage.getItem("companyName") ?? "",
  setCompanyLogo: () => { },
  setCompanyName: () => { },

  // theme
  mode: "light",
  setMode: () => { },

  // openChartUser
  openChartUser: false,
  setOpenChartUser: () => { },

 // theme
 userGuide: false,
 setUserGuide: () => { },

  // company edit modal
  companyEditModal: {
    companyId: null,
    openEditUser: false,
    showDetail: false,
    isEditButton: false,
  },
  setCompanyEditModal: () => { },

  contactEditModal: {
    contactId: null,
    openEditUser: false,
    showDetail: false,
    isEditButton: false,
    editInReservation: false,
    getListContact: () => { },
  },
  setContactEditModal: () => { },

  showPackageProgram: {
    packageItem: null,
    isOpenPackageProgram: false,
    isOpenEditProgram: false,
    disabled: false,
    type: "",
    reservation: null,
    showActionButtons: false,
  },
  setShowPackageProgram: () => { },
  showPackagePurchaseProgram: {
    packageItem: null,
    isOpenPackageProgram: false,
    isOpenEditProgram: false,
    disabled: false,
    type: "",
    reservation: null,
    showActionButtons: false,
  },
  setShowPackagePurchaseProgram: () => { },

  newNotification: false,
  setNewNotification: () => { },
});

export const useGlobalContext = () => useContext(MyGlobalContext);
