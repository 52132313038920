/* eslint-disable react-hooks/exhaustive-deps */
import { Add, Search } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Tab,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import TableHeading from "./TableHeading";
import TableItem from "./TableItem";
import GolfCourseForm from "./GolfCourseForm";
import ContentLayout from "../../../../components/TableComponent/ContentLayout";
import { CountryType, DestinationType } from "../../../../interfaces/product";
import LoadingPage from "../../../../components/Loading";
import { UserCreate } from "../../../../interfaces/roleAndPermissionInterface";
import productService from "../../../../services/productPostService";
import { GolfCourseItem } from "../../../../interfaces/golfCourse";
import { useSearchParams } from "react-router-dom";

export interface GolfCourseItemInterface {
  userCreate?: UserCreate;
  userUpdate: any;
  id: number;
  articleCode: string;
  title: string;
  status: string;
  publishExpiredDate?: string;
  type: string;
  country: string;
  location?: string;
  championship: string;
  designer: string;
  holes: string;
  par: string;
  metres?: string;
  facilities: string;
  coverImage?: string;
  pdfUrl?: string;
  createdBy?: number;
  createdAt: string;
  updatedBy: any;
  updatedAt: string;
}

const GolfCoursePage = () => {
  const [openDialogCreate, setOpenDialogCreate] = useState(false);
  const [listProductPosts, setListProductPosts] = useState<GolfCourseItem[]>(
    []
  );
  const [, setSearchparams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [filterText, setFilterText] = useState("");
  const [keyWord, setKeyWord] = useState("");
  const [country, setCountry] = useState<CountryType>();
  const [city, setCity] = useState<string>("");
  const [status, setStatus] = useState("");
  const [listAllCountry, setListAllCountry] = useState<CountryType[]>([]);
  const [listCountryFilter, setListCountryFilter] = useState<
    (CountryType | null)[]
  >([]);
  const [locations, setLocations] = useState<string[]>([]);
  const [loadingLocations, setLoadingLocations] = useState<boolean>(false);
  const fetchLocation = async (country: CountryType) => {
    setLoadingLocations(true);
    try {
      if (country.id) {
        let response = await productService.getListDestinationsByCountries({
          countriesId: [country.id],
        });
        if (response?.status === 200) {
          let data: DestinationType[] = response?.data;
          setLocations(data.map((item) => item.name));
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingLocations(false);
  };

  const fetchCountries = async () => {
    let response = await productService.getListCountries();
    if (response && response?.status === 200) {
      const countries = response.data;
      setListAllCountry(countries);
    }
  };
  const fetchData = async (payload: any) => {
    setLoading(true);
    const response = await productService.getListGolfCourse(payload);
    if (response && response?.status === 200) {
      setListProductPosts(response?.data.data);
      setTotal(response?.data.total);
    }
    setLoading(false);
  };

  useEffect(() => {
    const payload = {
      keyword: keyWord,
      status: status,
      pageNumber: page,
      pageSize: perPage,
      country: country?.name,
    };
    fetchData(payload);
  }, [page, perPage]);
  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (listAllCountry && listProductPosts) {
      const dataCountry = Array.from(
        new Set(
          listProductPosts.map((item: GolfCourseItem) => {
            const existCountry = listAllCountry.find(
              (country: CountryType) =>
                country.name === item.information?.country
            );
            if (existCountry) {
              return existCountry;
            } else {
              return null;
            }
          })
        )
      );

      const filterCountry = dataCountry.filter((item: any) => item !== null);
      setListCountryFilter(filterCountry);
    }
  }, [listAllCountry, listProductPosts]);
  useEffect(() => {
    if (country) {
      fetchLocation(country);
    } else {
      setLocations([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return (
    <>
      <Box className="w-full h-full">
        <ContentLayout
          isReverse={true}
          quickSearchComponent={
            <TextField
              size="small"
              placeholder="Search here"
              InputLabelProps={{ shrink: true }}
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            />
          }
          extraActions={
            <Stack mb={2} direction={"row"} spacing={2}>
              <Box className="section-btn-add box-shadow-1">
                <Button
                  variant={"contained"}
                  size="large"
                  onClick={() => {
                    setOpenDialogCreate(true);
                  }}
                  className="text-capitalize"
                  endIcon={<Add />}
                >
                  Add New
                </Button>
              </Box>
              <Paper sx={{ p: 2, flex: 1, borderRadius: "16px", pt: 4 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={3} xl={4}>
                    <TextField
                      fullWidth
                      label={"Keyword"}
                      value={keyWord}
                      placeholder="Enter keyword"
                      onChange={(e) => setKeyWord(e?.target?.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={3} xl={3}>
                    <Autocomplete
                      sx={{
                        ".MuiOutlinedInput-root .MuiAutocomplete-input": {
                          padding: "16.5px 14px",
                        },
                      }}
                      disablePortal
                      id="country"
                      getOptionLabel={(option) => String(option?.name)}
                      value={country ? country : null}
                      onChange={(e, value) => {
                        if (value) {
                          setCountry(value);
                        } else {
                          setCountry(undefined);
                        }
                      }}
                      options={listCountryFilter ?? []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Country"}
                          defaultValue={""}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="Select Country"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={3} xl={2}>
                    <Autocomplete
                      sx={{
                        ".MuiOutlinedInput-root .MuiAutocomplete-input": {
                          padding: "16.5px 14px",
                        },
                      }}
                      disablePortal
                      id="status"
                      value={status ? status : null}
                      onChange={(e, value) => {
                        if (value) {
                          setStatus(value);
                        } else {
                          setStatus("");
                        }
                      }}
                      options={["DRAFT", "PUBLISH"]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Status"}
                          defaultValue={""}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="Select status"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3} xl={3}>
                    <Stack direction={"row"} spacing={2}>
                      <Button
                        variant={"contained"}
                        size="large"
                        onClick={() => {
                          fetchData({
                            keyword: keyWord,
                            status: status,
                            pageNumber: page,
                            pageSize: perPage,
                            country: country?.name,
                          });
                          setSearchparams({
                            ...(keyWord.length > 0 && { keyWord: keyWord }),
                            ...(status && {
                              status: status,
                            }),
                            ...(page && {
                              pageNumber: String(page),
                            }),
                            ...(perPage && { pageSize: String(perPage) }),
                            ...(country && { country: country.name }),
                          });
                        }}
                        className="w-half h-50 text-capitalize"
                        endIcon={<Search />}
                      >
                        {"Search"}
                      </Button>
                      <Button
                        variant={"contained"}
                        color="inherit"
                        size="large"
                        onClick={() => {
                          setKeyWord("");
                          setCountry(undefined);
                          setStatus("");
                          fetchData({
                            keyword: "",
                            status: "",
                            pageNumber: 1,
                            pageSize: 20,
                            country: "",
                          })
                          setSearchparams({});
                        }}
                        className="w-half h-50 text-capitalize"
                      >
                        {"Clear"}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>
            </Stack>
          }
          data={listProductPosts}
          total={total}
          getListTable={() => {}}
          tableHeading={<TableHeading type="Tour" />}
          tableContent={
            loading ? (
              <TableRow className="item-hover">
                <TableCell colSpan={15} align="center">
                  <LoadingPage />
                </TableCell>
              </TableRow>
            ) : !listProductPosts ? (
              <TableRow className="item-hover">
                <TableCell colSpan={15} align="center">
                  No results found !
                </TableCell>
              </TableRow>
            ) : listProductPosts?.length > 0 ? (
              listProductPosts.map((e: GolfCourseItem, i: number) => (
                <TableItem
                  key={i}
                  data={e}
                  dataKey={i + (page - 1) * perPage}
                  childrenCallback={() => {
                    fetchData({
                      keyword: keyWord,
                      status: status,
                      pageNumber: page,
                      pageSize: perPage,
                      country: country?.name,
                    });
                  }}
                  listAllCountry={listAllCountry}
                />
              ))
            ) : (
              <TableRow className="item-hover">
                <TableCell colSpan={15} align="center">
                  No results found !
                </TableCell>
              </TableRow>
            )
          }
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          filterText={filterText}
          setFilterText={setFilterText}
        />
      </Box>

      {openDialogCreate && (
        <GolfCourseForm
          open={openDialogCreate}
          setOpen={setOpenDialogCreate}
          callback={() =>
            fetchData({
              keyword: keyWord,
              status: status,
              pageNumber: page,
              pageSize: perPage,
              country: country?.name,
            })
          }
          {...{ listAllCountry }}
        />
      )}
    </>
  );
};

export default GolfCoursePage;
