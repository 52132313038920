import {
  Box,
  Grid,
  MenuItem,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TextField,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import { useLocation } from "react-router-dom";

interface Props {
  heading?: string;
  extraActions?: ReactNode;
  data?: any;
  total?: number;
  getListTable: () => void;
  tableHeading: ReactNode;
  tableContent: ReactNode;
  page?: number;
  setPage: (data: number) => void;
  perPage?: number;
  setPerPage: (data: number) => void;
  quickSearchComponent?: ReactNode;
  filterText?: string;
  setFilterText?: (data: string) => void;
  companyName?: string;
  isReverse?: boolean;
  pricingTable?: boolean;
}
const ContentLayout = ({
  heading = "",
  extraActions,
  total,
  tableHeading,
  tableContent,
  page,
  setPage,
  perPage,
  setPerPage,
  quickSearchComponent,
  companyName,
  isReverse = false,
  pricingTable = false,
}: Props) => {
  const location = useLocation()
  return (
    <Box className="w-full h-full">
      {extraActions}
      <Box className="body-content" sx={{height: {
        lg: location.pathname.split("/").includes("post") ? "calc(100% - 190px)" : "calc(100% - 120px)",
        xl: "calc(100% - 120px)"
      }}}>
        <Grid
          container
          spacing={1}
          flexDirection={!isReverse ? "row-reverse" : "row"}
        >
          <Grid item xs={pricingTable ? 12 : 2}>
            {page && perPage && Number(total) > 0 && (
              <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={isReverse ? "flex-start" : "flex-end"}>              
              
                <p>Show</p>
                <TextField
                  select
                  value={perPage}
                  onChange={(e) => {
                    setPerPage(Number(e?.target?.value));
                    setPage(1);
                  }}
                  className="mt-8"
                >
                  {[5, 10, 15, 20, 30, 50, 100, 150]?.map(
                    (e: number, i: number) => (
                      <MenuItem key={i} value={e}>
                        {e}
                      </MenuItem>
                    )
                  )}
                </TextField>
               <b>of {Number(total)}</b>
              </Stack>
            )}
          </Grid>{" "}
          <Grid item xs={pricingTable ? 12 : 10}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: isReverse ? "flex-end" : "flex-start",
              }}
            >
              {quickSearchComponent}
            </Box>
          </Grid>
        </Grid>{" "}
        <Box sx={{ width: '100%', overflowX: 'auto', height: 'calc(100% - 120px)'}}>
      <TableContainer sx={{ height: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
        <TableHead>{tableHeading}</TableHead>
        <TableBody>{tableContent}</TableBody>
        </Table>
      </TableContainer>
    </Box>
         
        
      
        {page && perPage && Number(total) > 0 && (
          <Stack
            className="pt-10"
            direction={"row"}
            alignItems={"center"}
            sx={{
              mt: 1,
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Pagination
              count={Math.ceil(Number(total) / perPage)}
              onChange={(event, value) => setPage(value)}
              page={page}
            />
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default ContentLayout;
