import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationUS from './localization/locales/en_US.json'
// import translationES from './localization/locales/es_ES.json'
// import translationSA from './localization/locales/ar_SA.json'
import translationFR from './localization/locales/fr_FR.json'
// import translationIT from './localization/locales/it_IT.json'
// import translationZH from './localization/locales/zh-Hans.json'

const resources = {
  en: { translation: translationUS },
  // es: { translation: translationES },
  // sa: { translation: translationSA },
  fr: { translation: translationFR },
  // it: { translation: translationIT },
  // zh: { translation: translationZH },
};

i18next.use(initReactI18next).init({
  lng: 'en',
  debug: false,
  resources
})
