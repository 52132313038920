import { ArrowDropDown, Language } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Collapse,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
} from "@mui/material";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { CountryType } from "../../../../interfaces/product";
import GolfCourseForm from "./GolfCourseForm";
import productService from "../../../../services/productPostService";
import PreviewGolfCourse from "./PreviewGolfCourse";
import {
  ContentGolfCourse,
  GolfCourseItem,
} from "../../../../interfaces/golfCourse";
import { convertUTCtoLocalTime } from "../../../../utils";
import dataCountryAndCity from "../../../../utils/data/dataCountryAndCity";
const options = ["Preview", "View", "Edit", "Delete", "Add Other Language"];

const StyledTableCell = styled(TableCell)(({ width }: { width?: string }) => ({
  ...(width && { width: width, maxWidth: width }),
  fontSize: 14,
  padding: 8,
  "&:first-of-type": {
    paddingLeft: 20,
  },
  "&:last-of-type": {
    paddingRight: 20,
  },
}));

interface Props {
  index: number;
  data: GolfCourseItem;
  otherLanguage: ContentGolfCourse;
  childrenCallback: () => void;
  listAllCountry: CountryType[];
}

const TableItemLang = ({
  data,
  index,
  otherLanguage,
  childrenCallback,
  listAllCountry,
}: Props) => {
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isView, setIsView] = React.useState(false);
  const [openDialogShowHistory, setOpenDialogShowHistory] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [isNewLanguage, setIsNewLanguage] = React.useState(false);

  const handleDelete = (id: number) => {
    Swal.fire({
      icon: "question",
      title: "Warning!",
      text: "Delete this? You can't revert this.",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      confirmButtonColor: "#d32f2f",
    }).then(async (res) => {
      if (res?.isConfirmed) {
        const response = await productService.deleteGolfCourse(id);
        if (response?.status === 200) {
          Swal.fire("", "Delete successully!", "success");
          childrenCallback();
        }
      }
    });
  };

  const editProduct = () => {
    setIsEdit(true);
    setIsView(false);
    setIsNewLanguage(false)
  };
  const handleView = () => {
    setIsEdit(true);
    setIsView(true);
    setIsNewLanguage(false)
  };
  const handleAddOtherLanguage = () => {
    setIsEdit(true);
    setIsView(false);
    setIsNewLanguage(true);
  };

  const handleClick = (content: ContentGolfCourse) => {
    switch (options[selectedIndex]) {
      case "Edit":
        return editProduct();
      case "Delete":
        return handleDelete(Number(content.detail.id));
      case "View History":
        return setOpenDialogShowHistory(true);
      case "View":
        return handleView();
      case "Preview":
        return setOpenPreview(true);
        case "Add Other Language":
          return handleAddOtherLanguage();
      default:
        return;
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
    content: ContentGolfCourse
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    switch (options[index]) {
      case "Edit":
        return editProduct();
      case "Delete":
        return handleDelete(Number(content.detail.id));
      case "View History":
        return setOpenDialogShowHistory(true);
      case "View":
        return handleView();
      case "Preview":
        return setOpenPreview(true);
        case "Add Other Language":
          return handleAddOtherLanguage();
      default:
        return;
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <TableRow key={index} className={"item-hover"}>
        <StyledTableCell align="left" className="w-300 text-ellipsis">
          {otherLanguage.detail.title?.toUpperCase()}
        </StyledTableCell>
        <StyledTableCell align="left">
          {data.information.country}
        </StyledTableCell>

        <StyledTableCell align="left" className="w-200 text-capitalize">
          {JSON.parse(data.information.location ?? "[]").join(" - ")}
        </StyledTableCell>
        <StyledTableCell align="center" className="w-80 text-capitalize">
          {data.information.status}
        </StyledTableCell>
        <StyledTableCell align="center" className="w-100 text-capitalize">
          {
            dataCountryAndCity.languageList.find(
              (item) => item.value === otherLanguage.detail.language
            )?.label
          }
        </StyledTableCell>
        <StyledTableCell align="center" className="w-250">
          {otherLanguage?.userCreate?.firstName}
          {otherLanguage?.userCreate?.lastName}
          <br />
          {otherLanguage?.detail?.createdAt && convertUTCtoLocalTime(otherLanguage.detail.createdAt)}
        </StyledTableCell>
        <StyledTableCell align="center" className="w-250">
          {otherLanguage?.userUpdate?.firstName}
          {otherLanguage?.userUpdate?.lastName}
          <br />
          {otherLanguage?.detail?.updatedAt && convertUTCtoLocalTime(otherLanguage.detail.updatedAt)}
        </StyledTableCell>

        <TableCell align="center">
          <Box className="flex-center flex-1">
            <ButtonGroup
              variant="contained"
              ref={anchorRef}
              aria-label="split button"
              color={options[selectedIndex] === "Delete" ? "error" : "inherit"}
              className="box-unset rounded-20"
            >
              <Button
                onClick={() =>
                  handleClick(
                    otherLanguage
                  )
                }
                sx={{ borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }}
              >
                {options[selectedIndex]}
              </Button>
              <Button
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
                sx={{
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 20,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                <ArrowDropDown />
              </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) =>
                          handleMenuItemClick(
                            event,
                            index,
                            otherLanguage
                          )
                        }
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Popper>
            {isEdit && (
              <GolfCourseForm
                open={isEdit}
                setOpen={setIsEdit}
                dataDetail={data}
                callback={childrenCallback}
                listAllCountry={listAllCountry}
                isView={isView}
                setIsView={setIsView}
                defaultLanguage={otherLanguage.detail.language}
                isNewLanguage={isNewLanguage}
              />
            )}
            {openPreview && (
              <PreviewGolfCourse
                open={openPreview}
                setOpen={setOpenPreview}
                detailData={data}
                defaultLanguage={otherLanguage.detail.language}
              />
            )}
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableItemLang;
