const PaymentStatus = {
    UNPAID : "UNPAID",
    TOTAL_PAID: "TOTAL_PAID",
    OUTSTANDING_TOTAL: "OUTSTANDING_TOTAL",
    TOTAL_OUTSTANDING_PAID: "TOTAL_OUTSTANDING_PAID",
    _1st_DEPOSIT_PAID: "_1st_DEPOSIT_PAID",
    OUTSTANDING_1st_DEPOSIT: "OUTSTANDING_1st_DEPOSIT",
    _1st_DEPOSIT_OUTSTANDING_PAID: "_1st_DEPOSIT_OUTSTANDING_PAID",
    _2nd_DEPOSIT_PAID: "_2nd_DEPOSIT_PAID",
    OUTSTANDING_2nd_DEPOSIT: "OUTSTANDING_2nd_DEPOSIT",
    _2nd_DEPOSIT_OUTSTANDING_PAID: "_2nd_DEPOSIT_OUTSTANDING_PAID",
    _3rd_DEPOSIT_PAID: "_3rd_DEPOSIT_PAID",
    OUTSTANDING_3rd_DEPOSIT: "OUTSTANDING_3rd_DEPOSIT",
    _3rd_DEPOSIT_OUTSTANDING_PAID: "_3rd_DEPOSIT_OUTSTANDING_PAID",
    _4th_DEPOSIT_PAID: "_4th_DEPOSIT_PAID",
    OUTSTANDING_4th_DEPOSIT: "OUTSTANDING_4th_DEPOSIT",
    _4th_DEPOSIT_OUTSTANDING_PAID: "_4th_DEPOSIT_OUTSTANDING_PAID",
    _5th_DEPOSIT_PAID: "_5th_DEPOSIT_PAID",
    OUTSTANDING_5th_DEPOSIT: "OUTSTANDING_5th_DEPOSIT",
    _5th_DEPOSIT_OUTSTANDING_PAID: "_5th_DEPOSIT_OUTSTANDING_PAID",
    BALANCE_PAID: "BALANCE_PAID",
    OUTSTANDING_BALANCE: "OUTSTANDING_BALANCE",
    BALANCE_OUTSTANDING_PAID: "BALANCE_OUTSTANDING_PAID",
    FULLY_PAID: "FULLY_PAID",
    NO_REFUND: "NO_REFUND",
    REFUND_PENDING: "REFUND_PENDING",
    REFUNDED: "REFUNDED"
}

export default PaymentStatus;
