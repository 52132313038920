import TableCell from "@mui/material/TableCell";
import TableHeader from "../../../../components/TableComponent/TableHeader";

interface Props {
  type?: string;
}
const TableHeading = ({ type }: Props) => {
  return (
    <TableHeader>
      <TableCell className="bg-slight-blue text-left" sx={{width: "100px !important"}}>Language</TableCell>
      <TableCell className="bg-slight-blue text-left">#</TableCell>
      <TableCell className="bg-slight-blue text-left">Code</TableCell>
      <TableCell className="bg-slight-blue text-left max-w-100">Title</TableCell>
      <TableCell className="bg-slight-blue text-left">Country</TableCell>
      <TableCell className="bg-slight-blue text-left">City</TableCell>
      <TableCell className="bg-slight-blue text-center">Status</TableCell>
      <TableCell className="bg-slight-blue text-center">Language</TableCell>
      <TableCell className="bg-slight-blue text-center">Created</TableCell>
      <TableCell className="bg-slight-blue text-center">Updated</TableCell>
      <TableCell className="bg-slight-blue text-center">Actions</TableCell>
    </TableHeader>
  );
};

export default TableHeading;
