import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";

const userService = {
    getCurrent: async () => {
        return await apiInstance.get(endPointAPI.USER.CURRENT);
    },
    getCompany: async () => {
        return await apiInstance.get(endPointAPI.USER.COMPANY);
    },
    getSimple: async () => {
        return await apiInstance.post(endPointAPI.USER.FIND_SIMPLE);
    }
}

export default userService;
