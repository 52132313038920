import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TemplateHeader from "../../components/template/Header";
import TemplateFooter from "../../components/template/Footer";
import ContentComponent from "../../components/Editor/ContentComponent";
import { Close } from "@mui/icons-material";
import * as yup from "yup";
import validationMessage from "../../constants/validationMessage";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import dataSelect from "../../utils/data/dataSelect";
import Swal from "sweetalert2";
import authenticationService from "../../services/authenticationService";
import { requestRegistration } from "../../interfaces/authentication";
import { useNavigate } from "react-router-dom";

interface RegistrationForm {
  companyName: string;
  companyTradingName: string;
  companyWebsite: string;
  companyBusinessRegistrationNo: string;
  companyBusinessLicenceNo: string;
  companyType: string;
  companySpeciality: any[];
  companyCurrency: string;
  companyPricingMethod?: string | null;
  companyAddress: string;
  companyPostcode?: string | null;
  companyCity: string;
  companyState?: string | null;
  companyCountry: string;
  companyCountryCode: string;
  companyPhone: string;
  companySupplyDetailEmail?: string | null;
  companyNotes?: string | null;
  firstName: string;
  surname: string;
  jobTitle: string;
  email: string;
  password: string;
  passwordConfirm: string;
}
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = yup.object({
  companyName: yup.string().required(validationMessage.required),
  companyTradingName: yup.string().required(validationMessage.required),
  companyWebsite: yup.string().required(validationMessage.required),
  companyBusinessRegistrationNo: yup.string().required(validationMessage.required),
  companyBusinessLicenceNo: yup.string().required(validationMessage.required),
  companyType: yup.string().required(validationMessage.required),
  companySpeciality: yup.array().required(validationMessage.required),
  companyCurrency: yup.string().required(validationMessage.required),
  companyPricingMethod: yup
    .string()
    .nullable()
    .transform((value) => (value === undefined ? null : value)),
  companyAddress: yup.string().required(validationMessage.required),
  companyPostcode: yup
    .string()
    .nullable()
    .transform((value) => (value === undefined ? null : value)),
  companyCity: yup.string().required(validationMessage.required),
  companyState: yup
    .string()
    .nullable()
    .transform((value) => (value === undefined ? null : value)),
  companyCountry: yup.string().required(validationMessage.required),
  companyCountryCode: yup.string().required(validationMessage.required),
  companyPhone: yup.string().required(validationMessage.required)
  .matches(phoneRegExp, validationMessage.phone),
  companySupplyDetailEmail: yup
    .string()
    .nullable()
    .transform((value) => (value === undefined ? null : value)),
  companyNotes: yup
    .string()
    .nullable()
    .transform((value) => (value === undefined ? null : value)),
  firstName: yup.string().required(validationMessage.required),
  surname: yup.string().required(validationMessage.required),
  jobTitle: yup.string().required(validationMessage.required),
  email: yup.string().required(validationMessage.required),
  password: yup
    .string()
    .required(validationMessage.required)
    .matches(
      new RegExp(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g
      ),
      { message: validationMessage.passwordRule }
    ),
  passwordConfirm: yup
    .string()
    .required(validationMessage.required)
    .oneOf([yup.ref("password")], validationMessage.passwordConfirmNotMatch),
});
const RegistrationPage = () => {
  const [hasReadTerm, setHasReadTerm] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<RegistrationForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const submit: SubmitHandler<RegistrationForm> = async (data) => {
    try {
      const payload: requestRegistration = {
        ...data,
        companySpeciality: JSON.stringify(data.companySpeciality),
      }

      let response = await authenticationService.registration(payload);
      if(response?.status === 200) {
        Swal.fire({
          icon: "success",
          text: response?.data?.message,
          showCloseButton: true,
        });
        navigate("/sign-in");
      }
    } catch (e: any) {
      Swal.fire({
        icon: "error",
        width: 700,
        text: e?.response?.data?.message,
        showCloseButton: true,
      });
    }
    console.log(data);
  };
  const error: SubmitErrorHandler<RegistrationForm> = async (data) => {
    console.log(data);
  };
  return (
    <form onSubmit={handleSubmit(submit, error)}>
      <Box>
        <TemplateHeader />
        <Container className="mb-40 mt-100">
          <Typography variant="h1" className="text-28 text-center fw-700 mb-20">
            Supplier Registration Form
          </Typography>
          <Paper sx={{ p: 2, mb: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  className="mb-10 text-18 text-uppercase fw-600"
                >
                  Supply Details
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size={"small"}
                  {...register("companyName")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  defaultValue={localStorage.getItem("companyName")}
                  label={"Company Name (*)"}
                  placeholder="Company Name"
                  error={errors.companyName !== undefined}
                  helperText={
                    errors.companyName !== undefined
                      ? errors.companyName.message
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size={"small"}
                  {...register("companyTradingName")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label={"Trading Name (*)"}
                  placeholder="Supplier Trading Name"
                  error={errors.companyTradingName !== undefined}
                  helperText={
                    errors.companyTradingName !== undefined
                      ? errors.companyTradingName.message
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size={"small"}
                  {...register("companyWebsite")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label={"Company Website (*)"}
                  placeholder="Company Website"
                  error={errors.companyWebsite !== undefined}
                  helperText={
                    errors.companyWebsite !== undefined
                      ? errors.companyWebsite.message
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size={"small"}
                  {...register("companyBusinessRegistrationNo")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label={"Business Registration No (*)"}
                  placeholder="ABN or ACN"
                  error={errors.companyBusinessRegistrationNo !== undefined}
                  helperText={
                    errors.companyBusinessRegistrationNo !== undefined
                      ? errors.companyBusinessRegistrationNo.message
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size={"small"}
                  {...register("companyBusinessLicenceNo")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  defaultValue={localStorage.getItem("companyBusinessLicenceNo")}
                  label={"Business Licence No (*)"}
                  placeholder="Licence No"
                  error={errors.companyBusinessLicenceNo !== undefined}
                  helperText={
                    errors.companyBusinessLicenceNo !== undefined
                      ? errors.companyBusinessLicenceNo.message
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  name={`companyType`}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <Autocomplete
                        fullWidth
                        size={"small"}
                        disablePortal
                        id={`companyType`}
                        options={[
                          "Tour Operator",
                          "Cruise Operator",
                          "Cruise Line",
                          "Destination Management Company",
                          "Inbound Tour Operator",
                          "Outbound Tour Operator",
                          "Accommodation",
                          "Airline",
                          "Supplier",
                        ]}
                        value={value ? value : undefined}
                        onChange={(event: any, newValue: string | null) => {
                          if (newValue) {
                            onChange(newValue);
                          } else {
                            onChange(undefined);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            error={!!errors.companyType}
                            helperText={
                              errors.companyType
                                ? errors.companyType?.message
                                : ""
                            }
                            {...params}
                            label={"Company Type (*)"}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Controller
                  control={control}
                  name={`companySpeciality`}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <Autocomplete
                        fullWidth
                        size={"small"}
                        disablePortal
                        multiple
                        id={`companySpeciality`}
                        options={[
                          "Air",
                          "Train",
                          "High-speed rail",
                          "SIC Transfers",
                          "Bus/Van/Car",
                          "Boat/Ferry",
                          "Airport Transfer",
                          "Private Transfers",
                          "Sea Plane",
                          "Helicopter",
                        ]}
                        onChange={(event, newValue) => {
                          if (newValue && newValue.length > 0) {
                            onChange(newValue);
                          } else {
                            onChange(undefined);
                          }
                        }}
                        value={value ? value : undefined}
                        renderInput={(params) => (
                          <TextField
                            error={!!errors.companySpeciality}
                            helperText={
                              errors.companySpeciality
                                ? errors.companySpeciality?.message
                                : ""
                            }
                            {...params}
                            label={"Speciality (*)"}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  control={control}
                  name={`companyCurrency`}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <Autocomplete
                        fullWidth
                        size={"small"}
                        disablePortal
                        id={`companyCurrency`}
                        options={dataSelect.currency.map((item) => item.value)}
                        value={value ? value : undefined}
                        onChange={(event: any, newValue: string | null) => {
                          if (newValue) {
                            onChange(newValue);
                          } else {
                            onChange(undefined);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            error={!!errors.companyCurrency}
                            helperText={
                              errors.companyCurrency ? errors.companyCurrency?.message : ""
                            }
                            {...params}
                            label={"Product Currency (*)"}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  control={control}
                  name={`companyPricingMethod`}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <Autocomplete
                        fullWidth
                        size={"small"}
                        disablePortal
                        id={`companyPricingMethod`}
                        options={["NET", "GROSS"]}
                        value={value ? value : undefined}
                        onChange={(event: any, newValue: string | null) => {
                          if (newValue) {
                            onChange(newValue);
                          } else {
                            onChange(undefined);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            error={!!errors.companyPricingMethod}
                            helperText={
                              errors.companyPricingMethod
                                ? errors.companyPricingMethod?.message
                                : ""
                            }
                            {...params}
                            label={"Pricing Method"}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size={"small"}
                  {...register("companyAddress")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label={"Street Address (*)"}
                  placeholder="Street Address"
                  error={errors.companyAddress !== undefined}
                  helperText={
                    errors.companyAddress !== undefined ? errors.companyAddress.message : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  size={"small"}
                  {...register("companyState")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label={"State"}
                  placeholder="State/Region"
                  error={errors.companyState !== undefined}
                  helperText={
                    errors.companyState !== undefined ? errors.companyState.message : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  size={"small"}
                  {...register("companyCity")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label={"City (*)"}
                  placeholder="City"
                  error={errors.companyCity !== undefined}
                  helperText={
                    errors.companyCity !== undefined ? errors.companyCity.message : ""
                  }
                />
              </Grid>{" "}
              <Grid item xs={12} md={2}>
                <TextField
                  size={"small"}
                  {...register("companyPostcode")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label={"Postcode"}
                  placeholder="Postcode"
                  error={errors.companyPostcode !== undefined}
                  helperText={
                    errors.companyPostcode !== undefined ? errors.companyPostcode.message : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  control={control}
                  name="companyCountryCode"
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="companyCountryCode"
                        getOptionLabel={(option) =>
                          `(${option.value}) ${option.label}`
                        }
                        onChange={(e, value) => onChange(value?.value)}
                        options={dataSelect.country}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Country Code (*)"
                            defaultValue={""}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={errors.companyCountryCode !== undefined}
                            helperText={
                              errors.companyCountryCode !== undefined
                                ? errors.companyCountryCode.message
                                : ""
                            }
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  size={"small"}
                  {...register("companyPhone")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label={"Phone (*)"}
                  placeholder="Phone"
                  error={errors.companyPhone !== undefined}
                  helperText={
                    errors.companyPhone !== undefined ? errors.companyPhone.message : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size={"small"}
                  {...register("companySupplyDetailEmail")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label={"Email"}
                  placeholder="Email"
                  error={errors.companySupplyDetailEmail !== undefined}
                  helperText={
                    errors.companySupplyDetailEmail !== undefined
                      ? errors.companySupplyDetailEmail.message
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  name={`companyCountry`}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <Autocomplete
                        fullWidth
                        size={"small"}
                        disablePortal
                        id={`companyCountry`}
                        getOptionLabel={(option) => `${option.label}`}
                        onChange={(e, value) => onChange(value?.label)}
                        options={dataSelect.country}
                        renderInput={(params) => (
                          <TextField
                            error={!!errors.companyCountry}
                            helperText={
                              errors.companyCountry ? errors.companyCountry?.message : ""
                            }
                            {...params}
                            label={"Country (*)"}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className="text-14 fw-600 mb-10">
                  Notes/Messages
                </Typography>
                <Controller
                  control={control}
                  name={`companyNotes`}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <ContentComponent
                        data={value ? value : ""}
                        onChange={(data) => onChange(data)}
                        disabled={false}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper sx={{ p: 2, mb: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  className="mb-10 text-18 text-uppercase fw-600"
                >
                  Account Manager Details
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size={"small"}
                  {...register("firstName")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  defaultValue={localStorage.getItem("firstName")}
                  label={"First Name (*)"}
                  placeholder="First Name"
                  error={errors.firstName !== undefined}
                  helperText={
                    errors.firstName !== undefined
                      ? errors.firstName.message
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size={"small"}
                  {...register("surname")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label={"Surname (*)"}
                  placeholder="Surname"
                  error={errors.surname !== undefined}
                  helperText={
                    errors.surname !== undefined ? errors.surname.message : ""
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  name={`jobTitle`}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <Autocomplete
                        fullWidth
                        size={"small"}
                        disablePortal
                        id={`jobTitle`}
                        options={["Consultant", "Assistant Team Leader", "Team Leader/Manager", "Director", "Owner"]}
                        value={value ? value : undefined}
                        onChange={(event: any, newValue: string | null) => {
                          if (newValue) {
                            onChange(newValue);
                          } else {
                            onChange(undefined);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            error={!!errors.jobTitle}
                            helperText={
                              errors.jobTitle ? errors.jobTitle?.message : ""
                            }
                            {...params}
                            label={"Job Title (*)"}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  size={"small"}
                  {...register("email")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label={"Email (*)"}
                  placeholder="Email"
                  error={errors.email !== undefined}
                  helperText={
                    errors.email !== undefined
                      ? errors.email.message
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size={"small"}
                  type="password"
                  {...register("password")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label={"Password (*) (At least 8 characters)"}
                  placeholder="Password"
                  error={errors.password !== undefined}
                  helperText={
                    errors.password !== undefined ? errors.password.message : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size={"small"}
                  type="password"
                  {...register("passwordConfirm")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label={"Confirm Password (*)"}
                  placeholder="Confirm Password"
                  error={errors.passwordConfirm !== undefined}
                  helperText={
                    errors.passwordConfirm !== undefined
                      ? errors.passwordConfirm.message
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography className="text-14 mb-10">
                  Password must contain the following:
                </Typography>
                <Stack spacing={2}>
                  <Typography className="d-flex align-center text-14 text-danger">
                    {" "}
                    <Close color="error" sx={{ mr: 1 }} />{" "}
                    <span>
                      A <b>lowercase</b> letter
                    </span>{" "}
                  </Typography>
                  <Typography className="d-flex align-center text-14 text-danger">
                    {" "}
                    <Close color="error" sx={{ mr: 1 }} />{" "}
                    <span>
                      A <b>capital (uppercase)</b> letter
                    </span>{" "}
                  </Typography>
                  <Typography className="d-flex align-center text-14 text-danger">
                    {" "}
                    <Close color="error" sx={{ mr: 1 }} />{" "}
                    <span>
                      A <b>number</b>
                    </span>{" "}
                  </Typography>
                  <Typography className="d-flex align-center text-14 text-danger">
                    {" "}
                    <Close color="error" sx={{ mr: 1 }} />{" "}
                    <span>
                      Minimum <b>8 characters</b>
                    </span>{" "}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Paper>
          <Paper sx={{ p: 2, mb: 3 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!hasReadTerm}
                  onChange={(e, checked) => setHasReadTerm(checked)}
                />
              }
              label={
                <Typography>
                  I have read and agree to the{" "}
                  <b style={{ color: "#1e87f0" }}>Terms & Conditions*</b>
                </Typography>
              }
            />
          </Paper>
          <Box sx={{ p: 2, mb: 3 }}>
            <Stack alignItems={"flex-end"}>
              <Button variant="contained" type="submit" disabled={!hasReadTerm}>
                Register
              </Button>
            </Stack>
          </Box>
        </Container>
        <TemplateFooter />
      </Box>
    </form>
  );
};

export default RegistrationPage;
