import {
  CalendarMonth,
  Email,
  Lock,
  Mail,
  People,
  Person,
  Phone,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Social from "./Social";
import { useGlobalContext } from "../../context/globalContext";
import { CompanyData, CompanySetting } from "../../interfaces/passengerForm";
import settingService from "../../services/setting";

const TemplateFooter = () => {
  const { companyLogo, mode } = useGlobalContext();
  const [companyData, setCompanyData] = useState<CompanySetting>();
  // const getGeneralData = async () => {
  //   try {
  //     const companySetting = await settingService.companySettings.getCompany();
  //
  //     if (companySetting.status === 200 && companySetting.data.length > 0) {
  //       const obj = companySetting.data.reduce(
  //         (o: any, cur: CompanyData) => ({ ...o, [cur.key]: cur.value }),
  //         {}
  //       );
  //
  //       setCompanyData(obj);
  //     }
  //   } catch (e: any) {
  //     console.log(e);
  //   }
  // };
  // useEffect(() => {
  //   getGeneralData()
  // }, [])

  console.log(companyData);

  return (
    <Box sx={{ backgroundColor: mode === "dark" ? "#536370" : "#fff",
          color: mode === "dark" ? "#fff" : "#4a4e57",}}>
      <Divider />
      <Container
        sx={{
          px: 2,
          py: 5,

        }}
        maxWidth="xl"
      >
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Button
                variant={"text"}
                href={"terms-conditions"}

                sx={{ textTransform: "capitalize", color: "inherit" }}
            >
              Term & Conditions
            </Button>
            <Button
                variant={"text"}
                href={"privacy-policy"}
                sx={{ textTransform: "capitalize", color: "inherit" }}
            >
              Privacy Policy
            </Button>
          </Stack>
          <p>Powered By TKG Platform Technology</p>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Typography>We Accept</Typography>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <img src="https://agentportal.tweetworldtravel.com/images/payment-methods-icons/amex.png" />
              <img src="https://agentportal.tweetworldtravel.com/images/payment-methods-icons/mastercard.png" />
              <img src="https://agentportal.tweetworldtravel.com/images/payment-methods-icons/paypal.png" />
              <img src="https://agentportal.tweetworldtravel.com/images/payment-methods-icons/visa.png" />
            </Stack>
          </Stack>

        </Box>
      </Container>
    </Box>
  );
};

export default TemplateFooter;
