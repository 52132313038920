import {Box, CircularProgress, LinearProgress} from "@mui/material";
import {useParams} from "react-router";
import {useEffect, useState} from "react";
import userService from "../../services/userService";
import {useNavigate, useSearchParams} from "react-router-dom";

const  OneTimeLogin = () => {
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [initClose, setInitClose] = useState(false);

    const token = searchParams.get("token") ?? "";
    const companyCode = params.code ?? "";
    localStorage.setItem("companyCode", companyCode)
    localStorage.setItem("accessToken", token)

    const getCurrentUser = async () => {
        userService.getCurrent().then(response => {
            localStorage.setItem("user", JSON.stringify(response.data));
            navigate("/");
        }).catch(e => {
            setInitClose(true);
        })
    }

    useEffect(() => {
        getCurrentUser();
    }, []);

    return <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%"}}>
        {!initClose ? <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", maxWidth: "500px"}}>
            <LinearProgress sx={{width: "500px"}} />
            <p style={{marginTop: "20px", fontSize: "20px"}}>Verifying....</p>
        </Box> : <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", }}>
            <p style={{marginTop: "20px", fontSize: "20px"}}>Authentication failed !</p>
        </Box>}
    </Box>
}

export default OneTimeLogin;
