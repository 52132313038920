export default {
  country: [
    {
      name: "Afghanistan",
      cityList: [
        "Herat",
        "Kabul",
        "Kandahar",
        "Molah",
        "Rana",
        "Shar",
        "Sharif",
        "Wazir Akbar Khan",
      ],
    },
    {
      name: "Albania",
      cityList: [
        "Elbasan",
        "Petran",
        "Pogradec",
        "Shkoder",
        "Tirana",
        "Ura Vajgurore",
      ],
    },
    {
      name: "Algeria",
      cityList: [
        "Algiers",
        "Annaba",
        "Azazga",
        "Batna City",
        "Blida",
        "Bordj",
        "Bordj Bou Arreridj",
        "Bougara",
        "Cheraga",
        "Chlef",
        "Constantine",
        "Djelfa",
        "Draria",
        "El Tarf",
        "Hussein Dey",
        "Illizi",
        "Jijel",
        "Kouba",
        "Laghouat",
        "Oran",
        "Ouargla",
        "Oued Smar",
        "Relizane",
        "Rouiba",
        "Saida",
        "Souk Ahras",
        "Tamanghasset",
        "Tiaret",
        "Tissemsilt",
        "Tizi",
        "Tizi Ouzou",
        "Tlemcen",
      ],
    },
    {
      name: "Andorra",
      cityList: [
        "Andorra la Vella",
        "Canillo",
        "Encamp",
        "Engordany",
        "Escaldes-Engordany",
        "La Massana",
        "Llorts",
        "Ordino",
        "Santa Coloma",
        "Sispony",
        "Soldeu",
      ],
    },

  ],
  productTypes: [
    { label: "Tour", value: "Tour" },
    { label: "Cruise", value: "Cruise" },
    { label: "Accommodation", value: "Accommodation" },
  ],
  tourTypes: [
    { label: "Luxury", value: "Luxury" },
    { label: "Premium", value: "Premium" },
    { label: "Standard", value: "Standard" },
  ],
  policyTypes: [
    { label: "Single Trip", value: "Single Trip" },
    { label: "Annual Multi-Trip", value: "Annual Multi-Trip" },
    { label: "Inbound", value: "Inbound" },
  ],
  visaTypes: [
    { label: "Single entries", value: "Single entries" },
    { label: "Multiple entries", value: "Multiple entries" },
  ],
  roomTypes: [
    { label: "Single Room", value: "Single Room" },
    { label: "Double Room", value: "Double Room" },
    { label: "Family Room", value: "Family Room" },
  ],
  activeLevel: ["Leisurely", "Active", "Challenging"],
  homePage: [
    "Section 1",
    "Section 2",
    "Section 3",
    "Section 4",
  ],
  languageList: [
    { label: "English", value: "en" },
    { label: "Korean", value: "kr" },
    { label: "Spanish", value: "es" },
    { label: "French", value: "fr" },
    { label: "Italian", value: "it" },
    { label: "Chinese", value: "cn" },
  ],
  currencyList: [
    { label: "AUD", value: "AUD" },
    { label: "NZD", value: "NZD" },
    { label: "CAD", value: "CAD" },
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "GBP", value: "GBP" },
  ],
  siteAppearanceLocation: [
   "Website 1",
    "Website 2",
    "Website 3",
  ],
  guideType: [
   "Fully Guided",
    "Self Guided",
    "Partially Guided",
  ],
  productExclusive: [
    { label: "Company 1", value: 1 },
    { label: "Company 2", value: 2 },
    { label: "Company 3", value: 3 },
    { label: "Company 4", value: 4 },
  ],
  portTransfer: [
    { label: "Inclusion", value: "Inclusion" },
    { label: "Exclusion", value: "Exclusion" },
  ],
};
