import axios from "axios";

const httpInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 20000,
    headers: {
        "Content-Type": "application/json"
    }
});

export const httpMultipartInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 20000,
    headers: {
        "Content-Type": "multipart/form-data"
    }
});

httpInstance.interceptors.request.use(function (config){
    const token = localStorage.getItem("accessToken");
    const companyCode = localStorage.getItem("companyCode");
    const language = localStorage.getItem("language");
    const timeZone = localStorage.getItem("timeZone");
    config.headers["Content-Type"] = "application/json"
    if (token) config.headers.Authorization = `Bearer ${token}`;
    if (companyCode) config.headers["Company-Code"] = companyCode;
    if (language) config.headers["language"] = language;
    if (timeZone) config.headers["timeZone"] = timeZone;
    return config;
});

httpInstance.interceptors.response.use(function (response){
    if (response.status === 200) return response;
    console.log(response);
    return response;
})


httpMultipartInstance.interceptors.request.use(function (config){
    const token = localStorage.getItem("accessToken");
    const companyCode = localStorage.getItem("companyCode");
    const language = localStorage.getItem("language");
    const timeZone = localStorage.getItem("timeZone");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    if (companyCode) config.headers["Company-Code"] = companyCode;
    if (language) config.headers["Language"] = language;
    if (timeZone) config.headers["Timezone"] = timeZone;
    return config;
});

httpMultipartInstance.interceptors.response.use(function (response){
    if (response.status === 200) return response;
    console.log(response);
    return response;
})


export default httpInstance;
