import httpInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";
import {
  requestRegistration,
  requestSendForgotPassword,
  requestSendSignInCode,
  requestSendVerifyForgotPasswordToken,
  requestUpdatePassword,
  requestVerifySignInCode,
} from "../interfaces/authentication";

const authenticationService = {
  signIn: async (data: requestSendSignInCode) => {

    localStorage.setItem("companyCode", data.companyCode);
    return await httpInstance.post(
     endPointAPI.AUTHENTICATION.SEND_SIGN_IN_CODE,
     data
    );
  },

  verifySignInCode: async (data: requestVerifySignInCode) => {
    return await httpInstance.post(
      endPointAPI.AUTHENTICATION.VERIFY_SIGN_IN_CODE,
      data
    );
  },
  sendForgotPassword: async (data: requestSendForgotPassword) => {
    return await httpInstance.post(
      endPointAPI.AUTHENTICATION.SEND_FORGOT_PASSWORD,
      data
    );
  },
  verifyForgotPasswordToken: async (
    data: requestSendVerifyForgotPasswordToken
  ) => {
    return await httpInstance.post(
      endPointAPI.AUTHENTICATION.VERIFY_FORGOT_PASSWORD_TOKEN,
      data
    );
  },
  updatePassword: async (data: requestUpdatePassword) => {
    return await httpInstance.put(
      endPointAPI.AUTHENTICATION.UPDATE_PASSWORD,
      data
    );
  },
  registration: async (data: requestRegistration) => {
    return await httpInstance.post(
      endPointAPI.AUTHENTICATION.REGISTRATION,
      data
    );
  },
};

export default authenticationService;
