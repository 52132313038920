/* eslint-disable react-hooks/exhaustive-deps */

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ProgramSightseeingActivity,
} from "../../interfaces/reservation";
import reservationService from "../../services/reservationService";
import { useLocation } from "react-router-dom";
import settingService from "../../services/setting";
import AppBarItinerary from "./AppBarItinerary";
import { ExpandMore } from "@mui/icons-material";
import { ClockIcon } from "@mui/x-date-pickers";
import { convertMinToHour } from "../../utils";

const ViewShoreExcursion = () => {
  const location = useLocation();
  const pathNameUrl = location.pathname.split("/");

  const [packageProgram, setPackageProgram] = useState<
    ProgramSightseeingActivity[] | null
  >(null);
  const [companyData, setCompanyData] = useState<any>([]);
  const [listImages, setListImages] = useState<string[]>([]);

  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const response =
      await reservationService.packageService.getProgramSightseeingActivity(
        Number(pathNameUrl[2])
      );

    if (response?.status === 200) {
      setPackageProgram(response.data);
      const images = response.data.map((item: ProgramSightseeingActivity) => {
        if (item.activity.images) {
          return JSON.parse(item.activity.images ?? "[]");
        }
        return [];
      });
      setListImages(images.flat());
    }
    setCompanyData(await settingService.companySettings.getCompany());
    setLoading(false);
  };

  useEffect(() => {
    localStorage.setItem("companyCode", pathNameUrl[3]);
    setLoading(true);
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {packageProgram && (
            <>
              <AppBarItinerary data={companyData} />
              <Box className="wrapper-itinerary-sendmail">
                <Box mt={3} />
                <Container maxWidth="xl">
                  <Box sx={{ width: "100%" }}>
                    <Grid container spacing={2} alignItems={"center"}>
                      <Grid item md={6}>
                        {listImages && listImages.length > 0 && (
                          <Box
                            component="img"
                            sx={{
                              borderRadius: 4,
                              width: "100%",
                              height: { md: 460 },
                              maxHeight: { md: 460 },
                            }}
                            src={
                              !listImages[0].includes("https")
                                ? `${process.env.REACT_APP_URL_S3_IMAGE}/${process.env.REACT_APP_BUCKET_FOLDER}${listImages[0]}`
                                : listImages[0]
                            }
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        md={6}
                        sx={{
                          display: { xs: "none", sm: "none", md: "block" },
                        }}
                      >
                        <Grid container spacing={2}>
                          {listImages &&
                            listImages.slice(1, 5).map((item, i) => (
                              <Grid item md={6} key={i}>
                                <Box
                                  component="img"
                                  sx={{
                                    borderRadius: 4,
                                    width: "100%",
                                    height: { lg: 220 },
                                    maxHeight: { lg: 220 },
                                  }}
                                  src={
                                    !item.includes("https")
                                      ? `${process.env.REACT_APP_URL_S3_IMAGE}/${process.env.REACT_APP_BUCKET_FOLDER}${item}`
                                      : item
                                  }
                                />
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={5} />

                  {/* <Grid container>
                      <Typography variant={"h5"} sx={{ mb: 5 }}>
                        {packageProgram?.name}
                      </Typography>
                    </Grid> */}

                  <Paper
                    sx={{
                      p: 2,
                      mt: 5,
                      boxShadow: "0px 1px 4px 1px rgb(191 210 213 / 50%)",
                      borderRadius: 3,
                      display: packageProgram.length === 0 ? "none" : "block",
                    }}
                  >
                    <Typography
                      variant={"h6"}
                      sx={{
                        fontWeight: 600,
                        mb: 2,
                        mx: 2,
                        background: "#174d75",
                        px: 3,
                        py: 0.5,
                        display: "inline-block",
                        borderRadius: 4,
                        color: "#fff",
                      }}
                    >
                      SELECTED SHORE EXCURSION
                    </Typography>

                    {packageProgram &&
                      packageProgram.map((item, index: number) => {
                        let images = [];
                        if (item.activity.images) {
                          images = JSON.parse(item.activity.images ?? "[]");
                        }
                        return (
                          <Accordion
                            key={index}
                            disableGutters
                            sx={{
                              "&:before": { height: "0px" },
                              boxShadow: "0px 1px 4px 1px #bfd2d552 !important",
                              borderRadius: "10px !important",
                              mb: 2,
                            }}
                            defaultExpanded={index === 0}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls="panel-content"
                              id="panel-header"
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                {/* <Avatar
                                    sx={{
                                      width: 48,
                                      height: 48,
                                      fontSize: 13,
                                      fontWeight: 600,
                                      color: "#2a8cb7",
                                      background: "#e5f0fd",
                                      mr: 2,
                                    }}
                                  >
                                    Day {index + 1}
                                  </Avatar> */}
                                <Box>
                                  {/* <Typography
                                      variant="body2"
                                      color={"#174d75"}
                                      fontSize={14}
                                      fontStyle={"italic"}
                                      fontWeight={500}
                                    >
                                      {formatDateTimeDay(itinerary.date)}
                                    </Typography> */}
                                  <Typography
                                    variant="body1"
                                    fontSize={18}
                                    fontWeight={600}
                                  >
                                    {`${item.activity.code} - ${item.activity.name}`}
                                  </Typography>
                                </Box>
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container spacing={3}>
                                <Grid
                                  item
                                  xs={12}
                                  md={images && images.length > 0 ? 9 : 12}
                                >
                                  {item.summary && (
                                    <div
                                      className="preview-content"
                                      style={{
                                        height: "fit-content",
                                        padding: 0,
                                        fontStyle: "italic",
                                        marginBottom: "30px",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: item.summary.content ?? "",
                                      }}
                                    />
                                  )}

                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Grid container spacing={1}>
                                      {!!item.activity.duration && (
                                        <Grid mb={1} item xs={6}>
                                          <Box className="d-flex align-center">
                                            <ClockIcon sx={{ mr: 1 }} />
                                            <p className="text-16">
                                              <b>{"Duration: "}</b>
                                              {convertMinToHour(
                                                item.activity.duration
                                              )}
                                            </p>
                                          </Box>
                                        </Grid>
                                      )}

                                      {/* <Grid mb={1} item xs={6}>
                                                <p className="text-16">
                                                  <b>
                                                    {"Price: "}
                                                  </b>
                                                  {``}
                                                </p>
                                              </Grid> */}
                                    </Grid>
                                  </Box>
                                  <Divider sx={{ my: 1 }} />
                                </Grid>
                                {images && images.length > 0 && (
                                  <Grid item xs={12} md={3}>
                                    <img
                                      src={
                                        !images[0].includes("https")
                                          ? `${process.env.REACT_APP_URL_S3_IMAGE}/${process.env.REACT_APP_BUCKET_FOLDER}${images[0]}`
                                          : images[0]
                                      }
                                      alt=""
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                  </Paper>
                  <Box sx={{ borderTop: "1px solid #ccc", pt: 3 }} mt={5}>
                    <Grid container>
                      <Grid item md={6} mb={3}>
                        <Typography variant="body1">
                          © Copyright 2024 - Powered by{" "}
                          {
                            companyData?.data.find(
                              (item: any) => item.key === "companyName"
                            )?.value
                          }
                        </Typography>
                      </Grid>
                      <Grid item md={6} mb={3}>
                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems={"center"}
                          justifyContent={"flex-end"}
                        >
                          <Typography variant="body2">We Accept</Typography>
                          <img
                            src="/assets/payment-methods/amex.png"
                            alt="amex"
                          />
                          <img
                            src="/assets/payment-methods/visa.png"
                            alt="visa"
                          />
                          <img
                            src="/assets/payment-methods/paypal.png"
                            alt="paypal"
                          />
                          <img
                            src="/assets/payment-methods/mastercard.png"
                            alt="mastercard"
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </Box>
            </>
          )}{" "}
        </>
      )}
    </>
  );
};

export default ViewShoreExcursion;
