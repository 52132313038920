import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Box, Button, Typography } from '@mui/material';
import { Phone } from '@mui/icons-material';
interface Props {
    data: any
}

const AppBarItinerary = ({ data }: Props) => {

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <img
                        width={185}
                        src={`${process.env.REACT_APP_URL_S3_IMAGE}/${process.env.REACT_APP_BUCKET_FOLDER}${data?.data.find((item: any) => item.key === "companyLogo")?.value}`}
                        alt="img"
                        className="img-ratio"
                        style={{ objectFit: "contain" }}
                    />
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}>
                            <Phone sx={{ mr: 0.5, fontSize: 20 }} />
                            <Typography >{data?.data.find((item: any) => item.key === "companyPhoneNumber")?.value}</Typography>
                        </Box>
                    </Box>
                    <Button sx={{ ml: 2 }} color="inherit">Login</Button>

                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default AppBarItinerary