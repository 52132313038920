/* eslint-disable react-hooks/exhaustive-deps */

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  AccommodationResponse,
  ProgramSightseeingActivity,
} from "../../interfaces/reservation";
import reservationService from "../../services/reservationService";
import { useLocation } from "react-router-dom";
import settingService from "../../services/setting";
import AppBarItinerary from "./AppBarItinerary";
import {
  CalendarMonth,
  ExpandMore,
  LocationCity,
  PinDrop,
  RadioButtonChecked,
} from "@mui/icons-material";
import { ClockIcon } from "@mui/x-date-pickers";
import { convertMinToHour } from "../../utils";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { timelineItemClasses } from "@mui/lab/TimelineItem";

import moment from "moment";

const ViewAccommodation = () => {
  const location = useLocation();
  const pathNameUrl = location.pathname.split("/");

  const [packageProgram, setPackageProgram] = useState<
    AccommodationResponse[] | null
  >(null);
  const [companyData, setCompanyData] = useState<any>([]);
  const [listImages, setListImages] = useState<{ content: string }[]>([]);

  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const response = await reservationService.packageService.getAccommodation(
      Number(pathNameUrl[2])
    );

    if (response?.status === 200) {
      setPackageProgram(response.data);
      const images = response.data.map((item: AccommodationResponse) => {
        if (item.content.images) {
          return JSON.parse(item.content.images ?? "[]");
        }
        return [];
      });
      setListImages(images.flat());
    }
    setCompanyData(await settingService.companySettings.getCompany());
    setLoading(false);
  };

  useEffect(() => {
    localStorage.setItem("companyCode", pathNameUrl[3]);
    setLoading(true);
    fetchData();
  }, []);
  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {packageProgram && (
            <>
              <AppBarItinerary data={companyData} />
              <Box className="wrapper-itinerary-sendmail">
                <Box mt={3} />
                <Container maxWidth="xl">
                  <Box sx={{ width: "100%" }}>
                    <Grid container spacing={2} alignItems={"center"}>
                      <Grid
                        item
                        xs={12}
                        md={listImages.length <= 1 ? 12 : 6}
                        py={0}
                      >
                        {listImages && listImages.length > 0 && (
                          <Box
                            component="img"
                            sx={{
                              borderRadius: 4,
                              width: "100%",
                              height: { md: 576 },
                              maxHeight: { md: 576 },
                            }}
                            src={
                              !listImages[0].content.includes("https")
                                ? `${process.env.REACT_APP_URL_S3_IMAGE}/${process.env.REACT_APP_BUCKET_FOLDER}${listImages[0].content}`
                                : listImages[0].content
                            }
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        md={6}
                        sx={{
                          display: { xs: "none", sm: "none", md: "block" },
                        }}
                      >
                        <Grid container spacing={2}>
                          {listImages &&
                            listImages.slice(1, 5).map((item, i) => (
                              <Grid
                                item
                                md={listImages.length > 3 ? 6 : 12}
                                key={i}
                              >
                                <Box
                                  component="img"
                                  sx={{
                                    borderRadius: 4,
                                    width: "100%",
                                    height: {
                                      lg: listImages.length < 3 ? 576 : 280,
                                    },
                                    maxHeight: {
                                      lg: listImages.length < 3 ? 576 : 280,
                                    },
                                  }}
                                  src={
                                    !item.content.includes("https")
                                      ? `${process.env.REACT_APP_URL_S3_IMAGE}/${process.env.REACT_APP_BUCKET_FOLDER}${item.content}`
                                      : item.content
                                  }
                                />
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={5} />
                  <Box mt={1} className="wrapper-itinerary-sendmail">
          {packageProgram &&
            packageProgram.map((item, index: number) => {
              let images = [];
              if (item.content.images) {
                images = JSON.parse(item.content.images ?? "[]");
              }
              return (
                <Box key={index} mb={2}>
                  {/* {packageProgram.length > 1 && (
                    <Typography className="fw-700 text-tkg-blue">{`SEGMENT ${
                      index + 1
                    }`}</Typography>
                  )} */}
                  <Paper
                    sx={{
                      p: 2,
                      boxShadow: "0px 1px 4px 1px rgb(191 210 213 / 50%)",
                      borderRadius: 3,
                      mt: 2,
                    }}
                  >

                    <Grid container spacing={1}>
                      <Timeline>
                        <TimelineItem
                          key={index}
                          className={"timeline-left"}
                          sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                              flex: 0,
                              padding: 0,
                            },
                          }}
                        >
                          <TimelineSeparator>
                            <CalendarMonth sx={{ marginTop: "3px" }} />
                            <TimelineConnector />

                            <RadioButtonChecked />
                          </TimelineSeparator>
                          <TimelineContent sx={{ pt: 0 }}>
                            <Typography
                              sx={{
                                backgroundColor: "#103551",
                                color: "white",
                                width: "fit-content",
                                padding: "5px 10px",
                                borderRadius: "5px",
                                fontWeight: "bold",
                                mb: 2,
                              }}
                            >
                              {`${moment(item.detail.checkIn).format(
                                "DD MMM, YYYY HH:ss A"
                              )} - ${moment(item.detail.checkOut).format(
                                "DD MMM, YYYY HH:ss A"
                              )}`}
                            </Typography>
                            <Box sx={{ pl: 2, mb: 2 }}>
                              <Typography
                                sx={{
                                  color: "#103551",
                                  fontWeight: "bold",
                                  mb: 1,

                                  paddingBottom: "5px",
                                }}
                              >
                                {item.detail.accommodation}
                              </Typography>

                              <Grid container spacing={1}>
                                <Grid item xs={8}>
                                  {item.content ? (
                                    <div
                                      className="included-view"
                                      style={{
                                        height: "fit-content",
                                        // padding: 0,
                                        // fontStyle: "italic",
                                        marginBottom: "30px",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item.content.description.replace(
                                            `<p>&nbsp;</p>`,
                                            `<p></p>`
                                          ),
                                      }}
                                    />
                                  ) : null}

                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Grid container spacing={1}>
                                      {!!item.detail.accommodationType && (
                                        <Grid mb={1} item xs={6}>
                                          <Box className="d-flex align-center">
                                            <LocationCity sx={{ mr: 1 }} />
                                            <p className="text-16">
                                              <b>{"Accommodation Type: "}</b>
                                              {item.detail.accommodationType}
                                            </p>
                                          </Box>
                                        </Grid>
                                      )}

                                      {!!item.detail.address && (
                                        <Grid mb={1} item xs={12}>
                                          <Box className="d-flex align-center">
                                            <PinDrop sx={{ mr: 1 }} />
                                            <p className="text-16">
                                              <b>{"Address: "}</b>
                                              {item.detail.address}
                                            </p>
                                          </Box>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Box>
                                  {/* <Divider sx={{ my: 1 }} /> */}
                                </Grid>
                                {images && images.length > 0 && (
                                  <Grid item xs={4}>
                                    <img
                                      src={
                                        !images[0].content.includes("https")
                                          ? `${process.env.REACT_APP_URL_S3_IMAGE}/${process.env.REACT_APP_BUCKET_FOLDER}${images[0].content}`
                                          : images[0].content
                                      }
                                      alt=""
                                    />
                                  </Grid>
                                )}
                              </Grid>
                              {item.inclusions.length > 0 && (
                                <Box>
                                <Divider sx={{ mb: 2 }} textAlign="left">
                                  <Chip
                                    sx={{
                                      background: "#F4F8FB",
                                      fontWeight: 600,
                                      color: "#0d675b",
                                    }}
                                    label="INCLUSIONS"
                                  />
                                </Divider>
                                <Grid container spacing={1}>
                                  {item.inclusions.map((inclusion, index) => {
                                    return (
                                      <Grid item xs={6} key={index}>
                                        <Typography
                                          variant="h5"
                                          sx={{
                                            fontSize: 18,
                                            fontWeight: 600,
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          {inclusion.icon && (
                                            <img
                                              alt="svg icon"
                                              src={`/${inclusion.icon}`}
                                              width={20}
                                            />
                                          )}
                                          <span style={{ marginLeft: "5px" }}>
                                            {inclusion.title}
                                          </span>
                                        </Typography>
                                        <Box
                                          className="included-view"
                                          sx={{ fontSize: "16px" }}
                                          dangerouslySetInnerHTML={{
                                            __html: inclusion.content ?? "",
                                          }}
                                        />
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                                </Box>
                              )}
                              {item.term && (
                                <Box>
                                  <Divider sx={{ mb: 2 }} textAlign="left">
                                    <Chip
                                      sx={{
                                        background: "#F4F8FB",
                                        fontWeight: 600,
                                        color: "#0d675b",
                                      }}
                                      label="TERMS AND CONDITIONS"
                                    />
                                  </Divider>
                                  <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                      {/*<Typography variant="h5" sx={{ fontSize: 18, fontWeight: 600, display: "flex", alignItems: "center" }}>*/}
                                      {/*    */}
                                      {/*    <span style={{ marginLeft: "5px" }}>{highlight.title}</span>*/}
                                      {/*</Typography>*/}
                                      <Box
                                        className="overview-sendmail"
                                        sx={{ fontSize: "16px" }}
                                        dangerouslySetInnerHTML={{
                                          __html: item.term.content ?? "",
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Box>
                              )}
                            </Box>
                          </TimelineContent>
                        </TimelineItem>
                      </Timeline>
                    </Grid>
                  </Paper>
                </Box>
              );
            })}
        </Box>
                  <Box sx={{ borderTop: "1px solid #ccc", pt: 3 }} mt={5}>
                    <Grid container>
                      <Grid item md={6} mb={3}>
                        <Typography variant="body1">
                          © Copyright 2024 - Powered by{" "}
                          {
                            companyData?.data.find(
                              (item: any) => item.key === "companyName"
                            )?.value
                          }
                        </Typography>
                      </Grid>
                      <Grid item md={6} mb={3}>
                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems={"center"}
                          justifyContent={"flex-end"}
                        >
                          <Typography variant="body2">We Accept</Typography>
                          <img
                            src="/assets/payment-methods/amex.png"
                            alt="amex"
                          />
                          <img
                            src="/assets/payment-methods/visa.png"
                            alt="visa"
                          />
                          <img
                            src="/assets/payment-methods/paypal.png"
                            alt="paypal"
                          />
                          <img
                            src="/assets/payment-methods/mastercard.png"
                            alt="mastercard"
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </Box>
            </>
          )}{" "}
        </>
      )}
    </>
  );
};

export default ViewAccommodation;
