import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";

import { GeneralSettingsRequestInterface, currencyExchangeFormDataInterface, paymentRuleFormDataInterface, paymentRuleInterface, settingInterface } from "../interfaces/setting";

const settingService = {
    paymentRule: {
        getAll: async () => {
            return await apiInstance.get(endPointAPI.SETTING.PAYMENT_RULE.GET_LIST);
        },
        store: async (payload: paymentRuleFormDataInterface) => {
            return await apiInstance.post(endPointAPI.SETTING.PAYMENT_RULE.STORE, payload);
        },
        update: async (payload: paymentRuleFormDataInterface) => {
            return await apiInstance.put(`${endPointAPI.SETTING.PAYMENT_RULE.UPDATE}/${payload.type}`, payload);
        },
        detail: async (type: string) => {
            let request = await apiInstance.get(endPointAPI.SETTING.PAYMENT_RULE.DETAIL + '/' + type);
            let response: paymentRuleInterface = request.data;
            return response;
        }
    },
    passengerRate: {
        store: async (payload: {
            productType: string;
            childRate: number;
            infantRate: number;
        }) => {
            return apiInstance.post(endPointAPI.SETTING.PASSENGER_RATE.STORE, payload)
        },
        findByType: async (type: string) => {
            try{
                let response = await apiInstance.get(`${endPointAPI.SETTING.PASSENGER_RATE.FIND_BY_TYPE}/${type}`);
                return response.data;
            } catch(e){
                return null;
            }
        }
    },
    currencyExchange: {
        getList: async (type: string) => {
            return await apiInstance.get(`${endPointAPI.SETTING.CURRENCY_EXCHANGE.GET_LIST}/${type}`);
        },
        getDetail: async (type: string, code: string) => {
            return await apiInstance.get(`${endPointAPI.SETTING.CURRENCY_EXCHANGE.GET_DETAIL}/${type}/${code}`);
        },
        store: async (payload: currencyExchangeFormDataInterface) => {
            return await apiInstance.post(endPointAPI.SETTING.CURRENCY_EXCHANGE.STORE, payload);
        },
        update: async (payload: currencyExchangeFormDataInterface, id: number) => {
            return await apiInstance.put(`${endPointAPI.SETTING.CURRENCY_EXCHANGE.UPDATE}/${id}`, payload);
        },
        delete: async (id: number) => {
            return await apiInstance.delete(`${endPointAPI.SETTING.CURRENCY_EXCHANGE.DELETE}/${id}`);
        },
        getHistory: async (id: number) => {
            return await apiInstance.get(`${endPointAPI.SETTING.CURRENCY_EXCHANGE.GET_HISTORY}/${id}`);
        },
    },
    commissionRate: {
        store: async (payload: settingInterface[]) => {
            return apiInstance.post(endPointAPI.SETTING.COMMISSION_RATE.STORE, { settings: payload })
        },
        findByKeys: async (payload: string[]) => {
            return apiInstance.post(endPointAPI.SETTING.COMMISSION_RATE.GET_BY_KEYS, { keys: payload })
        }
    },
    store: async (group: string, payload: settingInterface[]) => {
        return apiInstance.post(endPointAPI.SETTING.STORE, { group: group, settings: payload })
    },
    findByGroup: async (group: string) => {
        return apiInstance.get(endPointAPI.SETTING.GET_BY_GROUP + `/${group}`);
    },
    generalSettings: {
        getByAllKeys: async (group: string) => {
            return await apiInstance.get(`${endPointAPI.SETTING.GENERAL_SETTINGS.GET_ALL_KEYS}/${group}`);
        },
        getByKey: async (group: string, key: string) => {
            return await apiInstance.get(`${endPointAPI.SETTING.GENERAL_SETTINGS.GET_BY_KEY}/${group}/${key}`);
        },
        store: async (payload: GeneralSettingsRequestInterface) => {
            return await apiInstance.post(endPointAPI.SETTING.GENERAL_SETTINGS.STORE, payload);
        },
    },
    companySettings: {
        store: async (payload: any) => {
            return await apiInstance.post(endPointAPI.SETTING.COMPANY_SETTINGS.STORE, payload);
        },
        storePayment: async (payload: any) => {
            return await apiInstance.post(endPointAPI.SETTING.COMPANY_SETTINGS.STORE_PAYMENT, payload);
        },
        getCompany: async () => {
            return await apiInstance.get(endPointAPI.SETTING.COMPANY_SETTINGS.GET_COMPANY_SETTINS);
        },
        getPayment: async () => {
            return await apiInstance.get(endPointAPI.SETTING.COMPANY_SETTINGS.GET_PAYMENT_OPTIONS);
        }
    },
    policy: {
        store: async (payload: any) => {
            return await apiInstance.post(endPointAPI.SETTING.POLICY.STORE, payload);
        },
        update: async (id: number, payload: any) => {
            return await apiInstance.put(`${endPointAPI.SETTING.POLICY.UPDATE}/${id}`, payload);
        },
        getAllPolicy: async () => {
            return await apiInstance.get(`${endPointAPI.SETTING.POLICY.GET_ALL}`);
        },
        getAllHistory: async (id: number) => {
            return await apiInstance.get(`${endPointAPI.SETTING.POLICY.GET_ALL_HISTORY}/${id}`);
        },
        getPolicyByType: async (type: string) => {
            return await apiInstance.get(`${endPointAPI.SETTING.POLICY.GET_BY_TYPE}/${type}`);
        },
        getPolicyById: async (id: number) => {
            return await apiInstance.get(`${endPointAPI.SETTING.POLICY.GET_BY_ID}/${id}`);
        },
        deleteById: async (id: number) => {
            return await apiInstance.delete(`${endPointAPI.SETTING.POLICY.DELETE}/${id}`);
        }
    },
    term: {
        store: async (payload: any) => {
            return await apiInstance.post(endPointAPI.SETTING.TERM.STORE, payload);
        },
        update: async (id: number, payload: any) => {
            return await apiInstance.put(`${endPointAPI.SETTING.TERM.UPDATE}/${id}`, payload);
        },
        getAllTerm: async () => {
            return await apiInstance.get(`${endPointAPI.SETTING.TERM.GET_ALL}`);
        },
        getAllHistory: async (id: number) => {
            return await apiInstance.get(`${endPointAPI.SETTING.TERM.GET_ALL_HISTORY}/${id}`);
        },
        getTermByType: async (type: string) => {
            return await apiInstance.get(`${endPointAPI.SETTING.TERM.GET_BY_TYPE}/${type}`);
        },
        getTermById: async (id: number) => {
            return await apiInstance.get(`${endPointAPI.SETTING.TERM.GET_BY_ID}/${id}`);
        },
        deleteById: async (id: number) => {
            return await apiInstance.delete(`${endPointAPI.SETTING.TERM.DELETE}/${id}`);
        }
    },
    emailConfig: {
        store: async (payload: any) => {
            return await apiInstance.post(endPointAPI.SETTING.EMAIL_CONFIG.STORE, payload);
        },
        update: async (id: number, payload: any) => {
            return await apiInstance.put(`${endPointAPI.SETTING.EMAIL_CONFIG.UPDATE}/${id}`, payload);
        },
        getAll: async () => {
            return await apiInstance.get(`${endPointAPI.SETTING.EMAIL_CONFIG.FIND_ALL}`);
        },
        getAllHistory: async (id: number) => {
            return await apiInstance.get(`${endPointAPI.SETTING.EMAIL_CONFIG.FIND_HISTORY_BY_ID}/${id}`);
        },
        getById: async (id: number | null) => {
            return await apiInstance.get(`${endPointAPI.SETTING.EMAIL_CONFIG.FIND_BY_ID}/${id}`);
        },
        deleteById: async (id: number) => {
            return await apiInstance.delete(`${endPointAPI.SETTING.EMAIL_CONFIG.DELETE}/${id}`);
        },
    },
    feedback: {
        // Question
        storeQuestion: async (payload: any) => {
            return await apiInstance.post(endPointAPI.SETTING.FEEDBACK.STORE_QUESTION, payload);
        },
        updateQuestion: async (id: number, payload: any) => {
            return await apiInstance.put(`${endPointAPI.SETTING.FEEDBACK.UPDATE_QUESTION}/${id}`, payload);
        },
        getListQuestion: async () => {
            return await apiInstance.get(`${endPointAPI.SETTING.FEEDBACK.GET_LIST_QUESTION}`);
        },
        getHistoryQuestion: async (id: number) => {
            return await apiInstance.get(`${endPointAPI.SETTING.FEEDBACK.GET_HISTORY_QUESTION}/${id}`);
        },
        getDetailQuestion: async (id: number | null) => {
            return await apiInstance.get(`${endPointAPI.SETTING.FEEDBACK.GET_DETAIL_QUESTION}/${id}`);
        },
        deleteQuestion: async (id: number) => {
            return await apiInstance.delete(`${endPointAPI.SETTING.FEEDBACK.DELETE_QUESTION}/${id}`);
        },
        // Rating
        storeRating: async (payload: any) => {
            return await apiInstance.post(endPointAPI.SETTING.FEEDBACK.STORE_RATING, payload);
        },
        updateRating: async (id: number, payload: any) => {
            return await apiInstance.put(`${endPointAPI.SETTING.FEEDBACK.UPDATE_RATING}/${id}`, payload);
        },
        getListRating: async () => {
            return await apiInstance.get(`${endPointAPI.SETTING.FEEDBACK.GET_LIST_RATING}`);
        },
        getHistoryRating: async (id: number) => {
            return await apiInstance.get(`${endPointAPI.SETTING.FEEDBACK.GET_HISTORY_RATING}/${id}`);
        },
        getDetailRating: async (id: number | null) => {
            return await apiInstance.get(`${endPointAPI.SETTING.FEEDBACK.GET_DETAIL_RATING}/${id}`);
        },
        deleteRating: async (id: number) => {
            return await apiInstance.delete(`${endPointAPI.SETTING.FEEDBACK.DELETE_RATING}/${id}`);
        },
    }
}

export default settingService;
