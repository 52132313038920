
const validationMessage = {
    required: "This field is required",
    email: "Invalid email address",
    phone: "Invalid phone number",
    passwordConfirmNotMatch: "Passwords must match",
    passwordRule: "Minimum eight characters, at least one letter, one number and one special character (@,$,!,%,*,#,?,&)",
    passwordMin: "Password minimum eight characters",
    passwordOneLowercase: "Password must has One Lowercase character",
    passwordOneUppercase: "Password must has One Uppercase character",
    passwordOneSpecial: "Password must has One Special character",
    passwordOneNumber: "Password must has One Number",
}
export const validateType = {
    email: new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
      ),
}
export default validationMessage;
