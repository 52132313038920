import apiInstance from "../config/apiInstance";
import {httpMultipartInstance} from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";
import { GolfCourseHandlePayload } from "../interfaces/golfCourse";
import { SightseeingDeletePayload, SightseeingPayload, SightseeingPriceRequest, SightseeingPriceUpdateRequest } from "../interfaces/price";
import { CategoryType, PricingCruiseInterface, PricingEditRequestInteface, PricingManageInterface, RequestSightseeing, SightseeingReservationPayload, UpdateMultiDiscountPricingRequest, UpdateMultiMarkupPricingRequest, requestProductPost, requestSafetyNet } from "../interfaces/product";
import { buildQueryString } from "../utils";

const productService = {
  getLocationMap: async (payload: {countries: string[], destinations: string[]}) => {
    return await apiInstance.post(endPointAPI.PRODUCT.GET_LOCATION_MAP, payload);
  },
  getListCountries: async () => {
    return await apiInstance.get(endPointAPI.PRODUCT.GET_LIST_COUNTRIES);
  },
  getListDestinationsByCountries: async (payload: {
    countriesId: (number | undefined)[];
  }) => {
    try {
      return await apiInstance.post(
      endPointAPI.PRODUCT.GET_DESTINATIONS_BY_COUNTRIES,
      payload
    );
    } catch (error) {
      return null
    }

  },

  //   tour
  getList: async (payload: {
    keyword: string;
    pageNumber: number;
    pageSize: number;
    supplierId?: number;
    country?: string;
    status?: string;
    userId?: string;
  }) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_LIST}?${buildQueryString(payload)}`
    );
  },
  getSimpleList: async (type: 'golf' | 'tour' | 'cruise' | 'sightseeing-activity' | 'package' | 'safety-net') => {
    let endPoint = '';
    if ( type === "golf") endPoint = `${endPointAPI.PRODUCT.GET_SIMPLE_LIST_GOLF}`;
    if ( type === "tour") endPoint = `${endPointAPI.PRODUCT.GET_SIMPLE_LIST_TOUR}`;
    if ( type === "cruise") endPoint = `${endPointAPI.PRODUCT.GET_SIMPLE_LIST_CRUISE}`;
    if ( type === "sightseeing-activity") endPoint = `${endPointAPI.PRODUCT.GET_SIMPLE_LIST_SIGHTSEEING}`;
    if ( type === "package") endPoint = `${endPointAPI.PRODUCT.GET_SIMPLE_LIST_PACKAGE}`;
    if ( type === "safety-net") endPoint = `${endPointAPI.PRODUCT.GET_SIMPLE_LIST_SAFETY_NET}`;
    try {
      let response = await apiInstance.get(endPoint);
      return response.data;
    } catch (e){
      return [];
    }

  },
  getDataFilter: async (type: 'golf' | 'tour' | 'cruise' | 'sightseeing' | 'package' | 'safety-net') => {
    let endPoint = '';
    if ( type === "golf") endPoint = `${endPointAPI.PRODUCT.GET_DATA_FILTER_GOLF}`;
    if ( type === "tour") endPoint = `${endPointAPI.PRODUCT.GET_DATA_FILTER_TOUR}`;
    if ( type === "cruise") endPoint = `${endPointAPI.PRODUCT.GET_DATA_FILTER_CRUISE}`;
    if ( type === "sightseeing") endPoint = `${endPointAPI.PRODUCT.GET_DATA_FILTER_SIGHTSEEING}`;
    if ( type === "package") endPoint = `${endPointAPI.PRODUCT.GET_DATA_FILTER_PACKAGE}`;
    if ( type === "safety-net") endPoint = `${endPointAPI.PRODUCT.GET_DATA_FILTER_SAFETY_NET}`;
    try {
       return await apiInstance.get(endPoint);
    } catch (e){
      return null
    }

  },

  getDetailProduct: async (code: string, language?: string) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_DETAIL}/${code}?language=${language}`
    );
  },

  createProductPost: async (payload: requestProductPost) => {
    return await apiInstance.post(`${endPointAPI.PRODUCT.CREATE}`, payload);
  },
  updateProductPost: async (payload: requestProductPost, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.PRODUCT.UPDATE}/${id}`,
      payload
    );
  },
  checkTripCode: async (code: string) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.CHECK_TRIPCODE_USED}/${code}`
    );
  },
  deleteProductPost: async (id: number) => {
    return await apiInstance.delete(`${endPointAPI.PRODUCT.DELETE}/${id}`);
  },

  // tour price
  getListTourPrice: async (tourId: number, payload: any) => {
    const query = buildQueryString(payload)
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_LIST_TOUR_PRICE}/${tourId}?${query}`
    );
  },
  getAvailableTourPrice: async (tourId: number) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_AVAILABLE_TOUR_PRICE}/${tourId}`
    );
  },
  getDetailTourPrice: async (id: number) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_DETAIL_TOUR_PRICE}/${id}`
    );
  },
  createTourPrice: async (payload: PricingManageInterface, tourId: number) => {
    return await apiInstance.post(`${endPointAPI.PRODUCT.CREATE_TOUR_PRICE}/${tourId}`, payload);
  },
  importTourPrice: async (payload: FormData,tourId: number) => {
    return await httpMultipartInstance.post(`${endPointAPI.PRODUCT.IMPORT_TOUR_PRICE}/${tourId}`, payload);
  },
  updateTourPrice: async (payload: PricingEditRequestInteface, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.PRODUCT.UPDATE_TOUR_PRICE}/${id}`,
      payload
    );
  },
  updateMultiMarkupTourPrice: async (payload: UpdateMultiMarkupPricingRequest) => {
    return await apiInstance.put(
      `${endPointAPI.PRODUCT.UPDATE_TOUR_MULTI_MARKUP_PRICE}`,
      payload
    );
  },
  updateMultiDiscountTourPrice: async (payload: UpdateMultiDiscountPricingRequest) => {
    return await apiInstance.put(
      `${endPointAPI.PRODUCT.UPDATE_TOUR_MULTI_DISCOUNT_PRICE}`,
      payload
    );
  },
  deleteTourPrice: async (payload: {ids: number[]}) => {
    return await apiInstance.post(`${endPointAPI.PRODUCT.DELETE_TOUR_PRICE}`, payload);
  },


  //   golf
  getListGolf: async (payload: {
    keyword: string;
    pageNumber: number;
    pageSize: number;
    supplierId?: number;
    country?: string;
    status?: string;
    userId?: string;
  }) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_LIST_GOLF}?${buildQueryString(payload)}`
    );
  },
  getDetailProductGolf: async (code: string, language?: string) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_DETAIL_GOLF}/${code}?language=${language}`
    );
  },

  createProductPostGolf: async (payload: requestProductPost) => {
    return await apiInstance.post(`${endPointAPI.PRODUCT.CREATE_GOLF}`, payload);
  },
  updateProductPostGolf: async (payload: requestProductPost, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.PRODUCT.UPDATE_GOLF}/${id}`,
      payload
    );
  },
  checkTripCodeGolf: async (code: string) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.CHECK_TRIPCODE_USED_GOLF}/${code}`
    );
  },
  deleteProductPostGolf: async (id: number) => {
    return await apiInstance.delete(`${endPointAPI.PRODUCT.DELETE_GOLF}/${id}`);
  },


    // golf price
    getListGolfPrice: async (golfPackageId: number, payload: any) => {
      const query = buildQueryString(payload)
      return await apiInstance.get(
        `${endPointAPI.PRODUCT.GET_LIST_GOLF_PRICE}/${golfPackageId}?${query}`
      );
    },
    getDetaitGolfPrice: async (id: number) => {
      return await apiInstance.get(
        `${endPointAPI.PRODUCT.GET_DETAIL_GOLF_PRICE}/${id}`
      );
    },
    createGolfPrice: async (payload: PricingManageInterface, golfPackageId: number) => {
      return await apiInstance.post(`${endPointAPI.PRODUCT.CREATE_GOLF_PRICE}/${golfPackageId}`, payload);
    },
    importGolfPrice: async ( payload: FormData, golfPackageId: number) => {
      return await httpMultipartInstance.post(`${endPointAPI.PRODUCT.IMPORT_GOLF_PRICE}/${golfPackageId}`, payload);
    },
    updateGolfPrice: async (payload: PricingEditRequestInteface, id: number) => {
      return await apiInstance.put(
        `${endPointAPI.PRODUCT.UPDATE_GOLF_PRICE}/${id}`,
        payload
      );
    },
    updateMultiMarkupGolfPrice: async (payload: UpdateMultiMarkupPricingRequest) => {
      return await apiInstance.put(
        `${endPointAPI.PRODUCT.UPDATE_GOLF_MULTI_MARKUP_PRICE}`,
        payload
      );
    },
    updateMultiDiscountGolfPrice: async (payload: UpdateMultiDiscountPricingRequest) => {
      return await apiInstance.put(
        `${endPointAPI.PRODUCT.UPDATE_GOLF_MULTI_DISCOUNT_PRICE}`,
        payload
      );
    },
    deleteGolfPrice: async (payload: {ids: number[]}) => {
      return await apiInstance.post(`${endPointAPI.PRODUCT.DELETE_GOLF_PRICE}`, payload);
    },

  //   cruise
  getListCruise: async (payload: {
    keyword: string;
    pageNumber: number;
    pageSize: number;
    supplierId?: number;
    country?: string;
    status?: string;
    userId?: string;
  }) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_LIST_CRUISE}?${buildQueryString(payload)}`
    );
  },
  getDetailProductCruise: async (code: string, language?: string) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_DETAIL_CRUISE}/${code}?language=${language}`
    );
  },

  createProductPostCruise: async (payload: requestProductPost) => {
    return await apiInstance.post(`${endPointAPI.PRODUCT.CREATE_CRUISE}`, payload);
  },
  updateProductPostCruise: async (payload: requestProductPost, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.PRODUCT.UPDATE_CRUISE}/${id}`,
      payload
    );
  },
  checkTripCodeCruise: async (code: string) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.CHECK_TRIPCODE_USED_CRUISE}/${code}`
    );
  },
  deleteProductPostCruise: async (id: number) => {
    return await apiInstance.delete(`${endPointAPI.PRODUCT.DELETE_CRUISE}/${id}`);
  },

    // cruise price
    getListCruisePrice: async (cruiseId: number, payload: any) => {
      const query = buildQueryString(payload)
      return await apiInstance.get(
        `${endPointAPI.PRODUCT.GET_LIST_CRUISE_PRICE}/${cruiseId}?${query}`
      );
    },
    getDetaitCruisePrice: async (id: number) => {
      return await apiInstance.get(
        `${endPointAPI.PRODUCT.GET_DETAIL_CRUISE_PRICE}/${id}`
      );
    },
    createCruisePrice: async (payload: PricingCruiseInterface[], cruiseId: number) => {
      return await apiInstance.post(`${endPointAPI.PRODUCT.CREATE_CRUISE_PRICE}/${cruiseId}`, payload);
    },
    importCruisePrice: async ( payload: FormData, cruiseId: number) => {
      return await httpMultipartInstance.post(`${endPointAPI.PRODUCT.IMPORT_CRUISE_PRICE}/${cruiseId}`, payload);
    },
    updateCruisePrice: async (payload: PricingEditRequestInteface, id: number) => {
      return await apiInstance.put(
        `${endPointAPI.PRODUCT.UPDATE_CRUISE_PRICE}/${id}`,
        payload
      );
    },
    updateMultiMarkupCruisePrice: async (payload: UpdateMultiMarkupPricingRequest) => {
      return await apiInstance.put(
        `${endPointAPI.PRODUCT.UPDATE_CRUISE_MULTI_MARKUP_PRICE}`,
        payload
      );
    },
    updateMultiDiscountCruisePrice: async (payload: UpdateMultiDiscountPricingRequest) => {
      return await apiInstance.put(
        `${endPointAPI.PRODUCT.UPDATE_CRUISE_MULTI_DISCOUNT_PRICE}`,
        payload
      );
    },
    deleteCruisePrice: async (payload: {ids: number[]}) => {
      return await apiInstance.post(`${endPointAPI.PRODUCT.DELETE_CRUISE_PRICE}`, payload);
    },

    //   sightseeing
  getListSightseeing: async (payload: {
    keyword: string;
    pageNumber: number;
    pageSize: number;
    supplierId?: number;
    country?: string;
    status?: string;
    userId?: string;
  }) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_LIST_SIGHTSEEING}?${buildQueryString(payload)}`
    );
  },
  getDetailProductSightseeing: async (code: string, language?: string) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_DETAIL_SIGHTSEEING}/${code}?language=${language}`
    );
  },
  findSightseeingForReservation: async (payload: SightseeingReservationPayload) => {
    const query = buildQueryString(payload);
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.FIND_FOR_RESERVATION}?${query}`
    );
  },

  createProductPostSightseeing: async (payload: RequestSightseeing) => {
    return await apiInstance.post(`${endPointAPI.PRODUCT.CREATE_SIGHTSEEING}`, payload);
  },
  updateProductPostSightseeing: async (payload: RequestSightseeing, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.PRODUCT.UPDATE_SIGHTSEEING}/${id}`,
      payload
    );
  },
  checkTripCodeSightseeing: async (code: string) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.CHECK_TRIPCODE_USED_SIGHTSEEING}/${code}`
    );
  },
  deleteProductPostSightseeing: async (id: number) => {
    return await apiInstance.delete(`${endPointAPI.PRODUCT.DELETE_SIGHTSEEING}/${id}`);
  },

  // sightseeing price
  getDetaitSightseeingPrice: async (payload: SightseeingPayload) => {
    try {
      return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_DETAIL_SIGHTSEEING_PRICE}/${payload.sightseeingId}/${payload.startDate}/${payload.endDate}`
    );
    } catch (error) {
      return null
    }

  },
  createSightseeingPrice: async (sightseeingId: number, request: SightseeingPriceRequest) => {
    try {
      return await apiInstance.post(`${endPointAPI.PRODUCT.CREATE_SIGHTSEEING_PRICE}/${sightseeingId}`, request);
    } catch (error) {
      return null
    }

  },
  updateSightseeingPrice: async (sightseeingId: number, request: SightseeingPriceUpdateRequest) => {
    try {
      return await apiInstance.put(
      `${endPointAPI.PRODUCT.UPDATE_SIGHTSEEING_PRICE}/${sightseeingId}`,
      request
    );
    } catch (error) {
      return null
    }

  },
  deleteSightseeingPrice: async (sightseeingId: number, payload: SightseeingDeletePayload) => {
    try {
      return await apiInstance.post(`${endPointAPI.PRODUCT.DELETE_SIGHTSEEING_PRICE}/${sightseeingId}`, payload);
    } catch (error) {
      return null
    }
  },

  // package
  getListPackage: async (payload: {
    keyword: string;
    pageNumber: number;
    pageSize: number;
    supplierId?: number;
    country?: string;
    status?: string;
    userId?: string;
  }) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_LIST_PACKAGE}?${buildQueryString(payload)}`
    );
  },
getDetailProductPackage: async (code: string, language?: string) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_DETAIL_PACKAGE}/${code}?language=${language}`
    );
  },

  createProductPostPackage: async (payload: requestProductPost) => {
    return await apiInstance.post(`${endPointAPI.PRODUCT.CREATE_PACKAGE}`, payload);
  },
  updateProductPostPackage: async (payload: requestProductPost, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.PRODUCT.UPDATE_PACKAGE}/${id}`,
      payload
    );
  },
  checkTripCodePackage: async (code: string) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.CHECK_TRIPCODE_USED_PACKAGE}/${code}`
    );
  },
  deleteProductPostPackage: async (id: number) => {
    return await apiInstance.delete(`${endPointAPI.PRODUCT.DELETE_PACKAGE}/${id}`);
  },

  // package price
  getListPackagePrice: async (tourId: number, payload: any) => {
    const query = buildQueryString(payload)
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_LIST_PACKAGE_PRICE}/${tourId}?${query}`
    );
  },
  getAvailablePackagePrice: async (tourId: number) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_AVAILABLE_PACKAGE_PRICE}/${tourId}`
    );
  },
  getDetailPackagePrice: async (id: number) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_DETAIL_PACKAGE_PRICE}/${id}`
    );
  },
  createPackagePrice: async (payload: PricingManageInterface, tourId: number) => {
    return await apiInstance.post(`${endPointAPI.PRODUCT.CREATE_PACKAGE_PRICE}/${tourId}`, payload);
  },
  importPackagePrice: async (payload: FormData,tourId: number) => {
    return await httpMultipartInstance.post(`${endPointAPI.PRODUCT.IMPORT_PACKAGE_PRICE}/${tourId}`, payload);
  },
  updatePackagePrice: async (payload: PricingEditRequestInteface, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.PRODUCT.UPDATE_PACKAGE_PRICE}/${id}`,
      payload
    );
  },
  updateMultiMarkupPackagePrice: async (payload: UpdateMultiMarkupPricingRequest) => {
    return await apiInstance.put(
      `${endPointAPI.PRODUCT.UPDATE_PACKAGE_MULTI_MARKUP_PRICE}`,
      payload
    );
  },
  updateMultiDiscountPackagePrice: async (payload: UpdateMultiDiscountPricingRequest) => {
    return await apiInstance.put(
      `${endPointAPI.PRODUCT.UPDATE_PACKAGE_MULTI_DISCOUNT_PRICE}`,
      payload
    );
  },
  deletePackagePrice: async (payload: {ids: number[]}) => {
    return await apiInstance.post(`${endPointAPI.PRODUCT.DELETE_PACKAGE_PRICE}`, payload);
  },

  getListSafetyNet: async (payload: {
    keyword: string;
    pageNumber: number;
    pageSize: number;
    supplierId?: number;
    country?: string;
    status?: string;
    userId?: string;
  }) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_LIST_SAFETY_NET}?${buildQueryString(payload)}`
    );
  },
getDetailProductSafetyNet: async (code: string, language?: string) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_DETAIL_SAFETY_NET}/${code}?language=${language}`
    );
  },

  createProductPostSafetyNet: async (payload: requestSafetyNet) => {
    return await apiInstance.post(`${endPointAPI.PRODUCT.CREATE_SAFETY_NET}`, payload);
  },
  updateProductPostSafetyNet: async (payload: requestSafetyNet, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.PRODUCT.UPDATE_SAFETY_NET}/${id}`,
      payload
    );
  },
  checkTripCodeSafetyNet: async (code: string) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.CHECK_TRIPCODE_USED_SAFETY_NET}/${code}`
    );
  },
  deleteProductPostSafetyNet: async (id: number) => {
    return await apiInstance.delete(`${endPointAPI.PRODUCT.DELETE_SAFETY_NET}/${id}`);
  },

  // categories
  getListCategoriesByType: async (type: string) => {
    return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_LIST_CATEGORIES}/${type}`
    );
  },
  createCategory: async (payload: CategoryType) => {
    return await apiInstance.post(endPointAPI.PRODUCT.CREATE_CATEGORY, payload);
  },
  updateCategory: async (payload: {
    title: string,
    description: string
  }, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.PRODUCT.UPDATE_CATEGORY}/${id}`,
      payload
    );
  },
  deleteCategory: async (id: number) => {
    return await apiInstance.delete(`${endPointAPI.PRODUCT.DELETE_CATEGORY}/${id}`);
  },


  getListGolfCourse: async (payload: any) => {
    try {
      const query = buildQueryString(payload)
      return await apiInstance.get(
      `${endPointAPI.PRODUCT.GET_LIST_GOLF_COURSE}?${query}`
    );
    } catch (error) {
      return null
    }
  },
  getDetailGolfCourse: async (code: string, language?: string) => {
    try {
      return await apiInstance.get(
      `${endPointAPI.PRODUCT.DETAIL_GOLF_COURSE}/${code}?language=${language}`
    );
    } catch (error) {
      return null
    }
  },
  // getHistoryGolfCourse: async (id: number) => {
  //   try {
  //     return await apiInstance.get(
  //     `${endPointAPI.PRODUCT.HISTORY_GOLF_COURSE}/${id}`
  //   );
  //   } catch (error) {
  //     return null
  //   }
  // },
  createGolfCourse: async (payload: GolfCourseHandlePayload) => {
    try {
      return await apiInstance.post(`${endPointAPI.PRODUCT.STORE_GOLF_COURSE}`, payload);
    } catch (error: any) {
      return error?.response
    }
  },
  updateGolfCourse: async (payload: GolfCourseHandlePayload, id: number) => {
    try {
      return await apiInstance.put(`${endPointAPI.PRODUCT.UPDATE_GOLF_COURSE}/${id}`, payload);
    } catch (error: any) {
      return error?.response
    }
  },
  deleteGolfCourse: async (id: number) => {
    return await apiInstance.delete(`${endPointAPI.PRODUCT.DELETE_GOLF_COURSE}/${id}`);
  },

  // common

  getDataQuickSearch: async (keyword: string) => {
    try {
      return await apiInstance.get(
      `${endPointAPI.PRODUCT.QUICK_SEARCH}/${keyword}`
    )
    } catch (error: any) {
      console.log(error)
      return error?.response
    }
    
  },
};

export default productService;
