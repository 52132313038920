import { createBrowserRouter } from "react-router-dom";
import { lazy } from "react";
import ViewShoreExcursion from "../pages/view-shore-excursion";
import ViewAccommodation from "../pages/view-accommodation";
import OneTimeLogin from "../pages/authentication/OneTimeLogin";
import GolfCoursePage from "../pages/dashboard/product/golf-course";
import RegistrationPage from "../pages/authentication/RegistrationPage";
const PurchaseOrderList = lazy(() => import("../pages/reservation/purchase-order/index/PurchaseOrderList"));
const ReservationDetailPOPage = lazy(() => import("../pages/reservation/purchase-order/ReservationDetailPOPage"));
const ForgotPasswordPage = lazy(
  () => import("../pages/authentication/ForgotPasswordPage")
);
const ForgotPasswordRequestPage = lazy(
  () => import("../pages/authentication/ForgotPasswordRequestPage")
);
const SignInPage = lazy(() => import("../pages/authentication/SignInPage"));
const UserProfile = lazy(() => import("../pages/profile/UserProfile"));
const MainSiteLayout = lazy(() => import("../layouts/MainSiteLayout"));
const PlatFormUserPage = lazy(
  () => import("../pages/management-dashboard/platform-user")
);
const ProductPostPage = lazy(
  () => import("../pages/dashboard/product/product-post")
);

const TKGTreeViewDashboard = lazy(() => import("../layouts/menuDashboard"));

const BookingStep = lazy(() => import("../pages/view-itinerary/BookingStep"));
const PreviewProduct = lazy(() => import("../pages/preview-product"));

// @ts-ignore
const router = createBrowserRouter([
  {
    path: "/one-time-login/:code",
    element: <OneTimeLogin />,
  },
  {
    path: "/profile",
    element: <UserProfile />,
  },
  {
    path: "/",
    element: <MainSiteLayout memuList={<TKGTreeViewDashboard />} />,
    // loader: <LoadingPage />,
    children: [
      {
        path: "/",
        element: <PurchaseOrderList />,
      },
      {
        path: "/platform-user",
        element: <PlatFormUserPage />,
      },
      {
        path: "/product",
        children: [
          {
            path: "/product/post",
            element: <ProductPostPage />,
          },

          {
            path: "/product/golf-course",
            element: <GolfCoursePage />,
          },
        ],
      },
    ],
  },
  {
    path: "/purchase-order", element: <MainSiteLayout memuList={<TKGTreeViewDashboard/>}/>, children: [
      {
        path: "/purchase-order",
        element: <PurchaseOrderList/>
      },
      {
        path: "/purchase-order/:reference/:rootPackageId",
        element: <ReservationDetailPOPage/>
      },
    ]
  },
  {
    path: "/sign-in",
    element: <SignInPage />,
  },
  {
    path: "/registration",
    element: <RegistrationPage />,
  },
  {
    path: "/forgot-password-request",
    element: <ForgotPasswordRequestPage />,
  },

  {
    path: "/forgot-password/:token",
    element: <ForgotPasswordPage />,
  },

  {
    path: "/shore-excursion/:packageId/:companyCode",
    element: <ViewShoreExcursion />,
  },
  {
    path: "/accommodation/:packageId/:companyCode",
    element: <ViewAccommodation />,
  },
  {
    path: "/preview-product",
    element: <PreviewProduct />,
  },
  {
    path: "/itinerary/booking",
    element: <BookingStep />,
  },
]);

export default router;
