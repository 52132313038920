import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";
import queryString from "query-string";

import {
  AddMoreExistServicePayload,
  DocumentPayload,
  DocumentRequest,
  FormPackageDetail,
  FormPassengerPayment,
  FormUpdateReservationOverview,
  PackageAmendmentFormData,
  PackageAmendmentUpdateStatusFormData,
  PackageCancelFormData,
} from "../interfaces/reservation";
import {
  AccommodationItinerary,
  AccommodationPayload,
  AddMoreServicePackageContent,
  FlightPayload,
  OtherServiceRequest,
  ReservationAddMorePassengerDataInterface,
  ReservationAddMoreRoomDataInterface, ReservationAddShoreExcursion, ReservationCloneDataInput,
  ReservationPackageContent,
  ReservationPackageContentOfPost,
  TransferPayload,
  TravelInsurancePayload,
  UpdateFlightPayload,
  UpdateTransferPayload,
} from "../interfaces/reservationDataInput";

const reservationService = {
  moveToTrash: async (id: number) => {
    return await apiInstance.delete(endPointAPI.RESERVATION.MOVE_TO_TRASH + "/" + id);
  },
  restore: async (id: number) => {
    return await apiInstance.get(endPointAPI.RESERVATION.RESTORE + "/" + id);
  },
  // manual
  createManualReservation: async (payload: ReservationPackageContent) => {
    return await apiInstance.post(endPointAPI.RESERVATION.MANUAL.STORE_MANUAL_CUSTOM, payload);
  },
  createManualReservationWithProduct: async (payload: ReservationPackageContentOfPost) => {
    return await apiInstance.post(endPointAPI.RESERVATION.MANUAL.STORE_MANUAL_WITH_PRODUCT, payload);
  },
  addMoreExistService: async (reservationId: number, payload: AddMoreExistServicePayload) => {
    return await apiInstance.post(endPointAPI.RESERVATION.ADD_MORE_SERVICE + "/" + reservationId, payload);
  },
  addMoreTravelInsurance: async (reservationId: number, payload: TravelInsurancePayload) => {
    return await apiInstance.post(endPointAPI.RESERVATION.ADD_MORE_INSURANCE + "/" + reservationId, payload);
  },
  addMoreVisa: async (reservationId: number, payload: TravelInsurancePayload) => {
    return await apiInstance.post(endPointAPI.RESERVATION.ADD_MORE_VISA + "/" + reservationId, payload);
  },
  addMoreAccommodation: async (reservationId: number, payload: AccommodationPayload) => {
    return await apiInstance.post(endPointAPI.RESERVATION.ADD_MORE_ACCOMMODATION + "/" + reservationId, payload);
  },
  addMoreTransfer: async (reservationId: number, payload: TransferPayload) => {
    return await apiInstance.post(endPointAPI.RESERVATION.ADD_MORE_TRANSFER + "/" + reservationId, payload);
  },
  addMoreFlight: async (reservationId: number, payload: FlightPayload) => {
    return await apiInstance.post(endPointAPI.RESERVATION.ADD_MORE_FLIGHT + "/" + reservationId, payload);
  },
  addMoreOtherService: async (reservationId: number, payload: OtherServiceRequest) => {
    return await apiInstance.post(endPointAPI.RESERVATION.ADD_MORE_OTHER + "/" + reservationId, payload);
  },

  addMoreExistSightseeingActivityService: async (reservationId: number, payload: ReservationAddShoreExcursion) => {
    return await apiInstance.post(endPointAPI.RESERVATION.ADD_MORE_SIGHTSEEING_ACTIVITY_SERVICE + "/" + reservationId, payload);
  },
  convertToBooking: async (reservationId: number) => {
    return await apiInstance.get(endPointAPI.RESERVATION.CONVERT_TO_BOOKING + "/" + reservationId)
  },
  filter: async () => {
    let request = await apiInstance.get(endPointAPI.RESERVATION.FILTER );
    return request.data;
  },
  addMoreCustomService: async (reservationId: number, payload: AddMoreServicePackageContent) => {
    return await apiInstance.post(endPointAPI.RESERVATION.ADD_MORE_CUSTOM_SERVICE + "/" + reservationId, payload);
  },
  clone: async (id: number, packageIds: number[], reservationInputClone: ReservationCloneDataInput) => {
    try {
      let request = await apiInstance.post(endPointAPI.RESERVATION.CLONE + '/' + id, {
        packageIds: packageIds,
        rooms: reservationInputClone.rooms
      });
      return request.data;
    } catch (e) {
      return null;
    }
  },

  commonData: async () => {
    try {
      let request = await apiInstance.get(endPointAPI.RESERVATION.COMMON_DATA);
      return request.data;
    } catch (e) {
      return {};
    }
  },
  detail: async (ref: string) => {
    try {
      let request = await apiInstance.get(endPointAPI.RESERVATION.DETAIL + "/" + ref);
      return request.data;
    } catch (e) {
      return null;
    }
  },
  updateOverview: async (id: number, data: FormUpdateReservationOverview) => {
    try {
      let request = await apiInstance.put(endPointAPI.RESERVATION.UPDATE_OVERVIEW + "/" + id, data);
      return request.data;
    } catch (e) {
      return null;
    }
  },
  getReservationByContact: async (payload: { contactIds: number[] }) => {
    try {
      return await apiInstance.post(
        `${endPointAPI.RESERVATION.GET_RESERVATION_BY_CONTACT_IDS}`, payload
      );
    } catch (e) {
      return null;
    }
  },
  getDataQuickSearch: async (payload: {
    pageSize: number;
    pageNumber: number;
    keyword: string;
  }) => {
    try {
      return await apiInstance.post(
        `${endPointAPI.RESERVATION.QUICK_SEARCH}`,
        payload
      );
    } catch (e: any) {
      return e?.response;
    }
  },
  roomService: {
    updatePrice: async (id: number, data: FormPassengerPayment) => {
      try {
        let request = await apiInstance.put(endPointAPI.RESERVATION.ROOM.UPDATE_PRICE + "/" + id, data);
        return request.data;
      } catch (e) {
        return null;
      }
    },
    price: async (id: number) => {
      try {
        let request = await apiInstance.get(endPointAPI.RESERVATION.ROOM.PRICE + "/" + id);
        return request.data;
      } catch (e) {
        return null;
      }
    },
    addMorePassenger: async (roomId: number, data: ReservationAddMorePassengerDataInterface) => {
      try {
        await apiInstance.post(endPointAPI.RESERVATION.ROOM.ADD_MORE_PASSENGER + `/${roomId}`, data);
        return true;
      } catch (e) {
        return false;
      }
    },
    deletePassenger: async (roomId: number, passengerId: number) => {
      try {
        await apiInstance.delete(endPointAPI.RESERVATION.ROOM.DELETE_PASSENGER + `/${roomId}/${passengerId}`);
        return true;
      } catch (e) {
        return false;
      }
    },
    deleteRoom: async (roomId: number) => {
      try {
        await apiInstance.delete(endPointAPI.RESERVATION.ROOM.DELETE_ROOM + `/${roomId}`);
        return true;
      } catch (e) {
        return false;
      }
    },
    addMore: async (packageId: number, data: ReservationAddMoreRoomDataInterface) => {
      try {
        await apiInstance.post(endPointAPI.RESERVATION.ROOM.ADD_MORE_ROOM + `/${packageId}`, data);
        return true;
      } catch (e) {
        return false;
      }
    },
    storePackageRoomNote: async (payload: { reservationId: number, roomId: number | null, content: string, attachments: any, type: string }) => {
      try {
        let response = await apiInstance.post(endPointAPI.RESERVATION.ROOM.STORE_ROOM_NOTE, payload);
        return response;
      } catch (e: any) {
        return e?.response;
      }
    },
    editPackageRoomNote: async (id: number, payload: { reservationId: number, roomId: number | null, content: string, attachments: any, type: string }) => {
      try {
        let response = await apiInstance.put(endPointAPI.RESERVATION.ROOM.EDIT_ROOM_NOTE + `/${id}`, payload);
        return response;
      } catch (e: any) {
        return e?.response;
      }
    },
    deletePackageRoomNote: async (id: number) => {
      try {
        let response = await apiInstance.delete(endPointAPI.RESERVATION.ROOM.DELETE_ROOM_NOTE + `/${id}`);
        return response;
      } catch (e: any) {
        return e?.response;
      }
    },
    packageRoomNoteHistory: async (id: number) => {
      try {
        let response = await apiInstance.get(`${endPointAPI.RESERVATION.ROOM.HISTORY_ROOM_NOTE}/${id}`);
        return response.data;
      } catch (e) {
        return null
      }
    },
    packageRoomNote: async (id: number | null, pageNum: number, pageSize: number) => {
      try {
        let response = await apiInstance.get(`${endPointAPI.RESERVATION.ROOM.LIST_ROOM_NOTE}/${id}?pageNumber=${pageNum}&pageSize=${pageSize}`);
        return response.data;
      } catch (e) {
        return null
      }
    },
    historyInvoiceRoom: async (id: number | null, pageNum: number, pageSize: number) => {
      try {
        let response = await apiInstance.get(`${endPointAPI.RESERVATION.ROOM.HISTORY_INVOICE_ROOM}/${id}?pageNumber=${pageNum}&pageSize=${pageSize}`);
        return response.data;
      } catch (e) {
        return null
      }
    },
    getListInvoicePdfRoom: async (roomId?: number | null) => {
      try {
        let response = await apiInstance.get(`${endPointAPI.RESERVATION.ROOM.GET_LIST_INVOICE}/${roomId}`);
        return response.data;
      } catch (e) {
        return null
      }
    },
  },
  packageService: {
    delete: async (id: number) => {
      return await apiInstance.delete(endPointAPI.RESERVATION.PACKAGE.DELETE + "/" + id);
    },
    clone: async (id: number, data: ReservationCloneDataInput, isSamePassenger: boolean) => {
      return await apiInstance.post(endPointAPI.RESERVATION.PACKAGE.CLONE + '/' + id, { ...data, isSamePassenger: isSamePassenger });
    },
    getPackageByReference: async (reference: string) => {
      try {
        let request = await apiInstance.get(endPointAPI.RESERVATION.PACKAGE.LIST_BY_REFERENCE + '/' + reference);
        return request.data;
      } catch (e) {
        return [];
      }
    },
    cancel: async (id: number, data: PackageCancelFormData) => {
      try {
        let request = await apiInstance.post(endPointAPI.RESERVATION.PACKAGE.CANCEL + "/" + id, data);
        return request.data
      } catch (e) {
        return null;
      }
    },
    amendmentRequest: async (id: number, data: PackageAmendmentFormData) => {
      try {
        let request = await apiInstance.post(endPointAPI.RESERVATION.PACKAGE.AMENDMENT_REQUEST + "/" + id, data);
        return request.data
      } catch (e) {
        return null;
      }
    },
    updateStatusAmendmentRequest: async (id: number, data: PackageAmendmentUpdateStatusFormData) => {
      try {
        let request = await apiInstance.put(endPointAPI.RESERVATION.PACKAGE.UPDATE_STATUS_AMENDMENT_REQUEST + "/" + id, data);
        return request.data
      } catch (e) {
        return null;
      }
    },
    update: async (id: number, data: FormPackageDetail) => {
      try {
        let request = await apiInstance.put(endPointAPI.RESERVATION.PACKAGE.UPDATE + '/' + id, data);
        return request.data;
      } catch (e) {
        return null;
      }
    },
    programDetail: async (id: number) => {
      try {
        let request = await apiInstance.get(endPointAPI.RESERVATION.PACKAGE.PROGRAM + '/' + id);
        return request.data;
      } catch (e) {
        return null;
      }
    },
    updateProgram: async (id: number, data: ReservationPackageContent) => {
      try {
        let response = await apiInstance.put(endPointAPI.RESERVATION.PACKAGE.UPDATE_PROGRAM + `/${id}`, data);
        return response.data;
      } catch (e) {
        return null
      }
    },
    getInvoicePdf: async (payload: { reservationId: number, passengerId?: number, roomId?: number, packageId?: number, isAgent?: boolean }) => {
      try {
        let response = await apiInstance.post(endPointAPI.RESERVATION.PACKAGE.GET_INVOICE, payload);
        return response.data;
      } catch (e) {
        return null
      }
    },
    getListInvoicePdf: async (payload: { packageId?: number }) => {
      try {
        let response = await apiInstance.get(`${endPointAPI.RESERVATION.PACKAGE.GET_LIST_INVOICE}/${payload.packageId}`);
        return response.data;
      } catch (e) {
        return null
      }
    },
    getListAllInvoice: async (reservationId: number) => {
      try {
        let response = await apiInstance.get(`${endPointAPI.RESERVATION.PACKAGE.GET_LIST_ALL_INVOICE}/${reservationId}`);
        return response.data;
      } catch (e) {
        return null
      }
    },
    getListInvoicePdfPassenger: async (payload: { passengerId: number | null }) => {
      try {
        let response = await apiInstance.get(`${endPointAPI.RESERVATION.PACKAGE.GET_LIST_INVOICE_PASSENGER}/${payload.passengerId}`);
        return response.data;
      } catch (e) {
        return null
      }
    },
    checkInvoicePdf: async (payload: { invoiceNumber?: string, reservationId: number, passengerId?: number | null, roomId?: number | null, packageId?: number, isAgent?: boolean, status?: string }) => {
      try {
        let response = await apiInstance.post(endPointAPI.RESERVATION.PACKAGE.CHECK_INVOICE, payload);
        return response;
      } catch (e: any) {
        return e?.response;
      }
    },
    storeInvoicePdf: async (payload: { invoiceNumber?: string, reservationId: number, passengerId?: number | null, roomId?: number | null, packageId?: number, isAgent?: boolean, status?: string }) => {
      try {
        let response = await apiInstance.post(endPointAPI.RESERVATION.PACKAGE.STORE_INVOICE, payload);
        return response;
      } catch (e: any) {
        return e?.response;
      }
    },
    deleteInvoicePdf: async (id: number) => {
      try {
        let response = await apiInstance.delete(`${endPointAPI.RESERVATION.PACKAGE.DELETE_INVOICE}/${id}`);
        return response.data;
      } catch (e) {
        return null
      }
    },
    getInvoiceData: async (id: number) => {
      try {
        let response = await apiInstance.post(`${endPointAPI.RESERVATION.PACKAGE.GET_INVOICE_DATA}`, { id: id });
        return response.data;
      } catch (e) {
        return null
      }
    },
    historyPackageInvoice: async (id: number, pageNum: number, pageSize: number) => {
      try {
        let response = await apiInstance.get(`${endPointAPI.RESERVATION.PACKAGE.HISOTRY_INVOICE}/${id}?pageNumber=${pageNum}&pageSize=${pageSize}`);
        return response.data;
      } catch (e) {
        return null
      }
    },
    historyPassengerPackageInvoice: async (id: number | null, pageNum: number, pageSize: number) => {
      try {
        let response = await apiInstance.get(`${endPointAPI.RESERVATION.PACKAGE.HISOTRY_INVOICE_PASSENGER}/${id}?pageNumber=${pageNum}&pageSize=${pageSize}`);
        return response.data;
      } catch (e) {
        return null
      }
    },
    storePackageNote: async (payload: { packageId: number, content: string, attachments: any, type: string }) => {
      try {
        let response = await apiInstance.post(endPointAPI.RESERVATION.PACKAGE.STORE_NOTE, payload);
        return response;
      } catch (e: any) {
        return e?.response;
      }
    },
    editPackageNote: async (id: number, payload: { packageId: number, content: string, attachments: any, type: string }) => {
      try {
        let response = await apiInstance.put(endPointAPI.RESERVATION.PACKAGE.EDIT_NOTE + `/${id}`, payload);
        return response;
      } catch (e: any) {
        return e?.response;
      }
    },
    deletePackageNote: async (id: number) => {
      try {
        let response = await apiInstance.delete(endPointAPI.RESERVATION.PACKAGE.DELETE_NOTE + `/${id}`);
        return response;
      } catch (e: any) {
        return e?.response;
      }
    },
    packageNoteHistory: async (id: number) => {
      try {
        let response = await apiInstance.get(`${endPointAPI.RESERVATION.PACKAGE.HISTORY_NOTE}/${id}`);
        return response.data;
      } catch (e) {
        return null
      }
    },
    packageNote: async (id: number, pageNum: number, pageSize: number) => {
      try {
        let response = await apiInstance.get(`${endPointAPI.RESERVATION.PACKAGE.LIST_NOTE}/${id}?pageNumber=${pageNum}&pageSize=${pageSize}`);
        return response.data;
      } catch (e) {
        return null
      }
    },
    storePackageHistoryEmailItinery: async (payload: any) => {
      try {
        let response = await apiInstance.post(endPointAPI.RESERVATION.PACKAGE.STORE_HISTORY_EMAIL_ITINERARY, payload);
        return response;
      } catch (e: any) {
        return e?.response;
      }
    },
    getListPackageHistoryEmailItinery: async (reservationId: number, packageId: number) => {
      try {
        let response = await apiInstance.get(`${endPointAPI.RESERVATION.PACKAGE.GET_LIST_HISTORY_EMAIL_ITINERARY}/${reservationId}/${packageId}`);
        return response.data;
      } catch (e) {
        return null
      }
    },

    // Master invoice + note
    storeInvoiceMasterPdf: async (payload: {
      reservationId: number;
      isAgent?: boolean;
      status?: string;
    }) => {
      try {
        let response = await apiInstance.post(
          endPointAPI.RESERVATION.PACKAGE.STORE_INVOICE_MASTER,
          payload
        );
        return response;
      } catch (e: any) {
        return e?.response;
      }
    },
    deleteInvoiceMasterPdf: async (id: number) => {
      try {
        let response = await apiInstance.delete(
          `${endPointAPI.RESERVATION.PACKAGE.DELETE_INVOICE_MASTER}/${id}`
        );
        return response.data;
      } catch (e) {
        return null;
      }
    },
    getInvoiceMasterData: async (id: number) => {
      try {
        let response = await apiInstance.get(
          `${endPointAPI.RESERVATION.PACKAGE.GET_INVOICE_MASTER_DATA}/${id}`
        );
        return response.data;
      } catch (e) {
        return null;
      }
    },
    historyReservationInvoiceMaster: async (
      id: number,
      pageNum: number,
      pageSize: number
    ) => {
      try {
        let response = await apiInstance.get(
          `${endPointAPI.RESERVATION.PACKAGE.HISOTRY_INVOICE_MASTER}/${id}?pageNumber=${pageNum}&pageSize=${pageSize}`
        );
        return response.data;
      } catch (e) {
        return null;
      }
    },
    storeReservationNoteMaster: async (payload: {
      reservationId: number;
      content: string;
      type: string;
    }) => {
      try {
        let response = await apiInstance.post(
          endPointAPI.RESERVATION.PACKAGE.STORE_NOTE_MASTER,
          payload
        );
        return response;
      } catch (e: any) {
        return e?.response;
      }
    },
    reservationNoteMaster: async (
      id: number,
      pageNum: number,
      pageSize: number
    ) => {
      try {
        let response = await apiInstance.get(
          `${endPointAPI.RESERVATION.PACKAGE.LIST_NOTE_MASTER}/${id}?pageNumber=${pageNum}&pageSize=${pageSize}`
        );
        return response.data;
      } catch (e) {
        return null;
      }
    },
    getProgramSightseeingActivity: async (
      packageId: number,
    ) => {
      try {
        let response = await apiInstance.get(
          `${endPointAPI.RESERVATION.PACKAGE.GET_PROGRAM_SIGHTSEEING_ACTIVITY}/${packageId}`
        );
        return response;
      } catch (e) {
        return null;
      }
    },

    // Travel Insurance
    getTravelInsurance: async (packageId: number) => {
      try {
        let response = await apiInstance.get(
          `${endPointAPI.RESERVATION.PACKAGE.GET_PROGRAM_TRAVEL_INSURANCE}/${packageId}`
        );
        return response;
      } catch (e) {
        return null;
      }
    },
    updateTravelInsurance: async (packageId: number, payload: TravelInsurancePayload) => {
      try {
        let response = await apiInstance.put(
          `${endPointAPI.RESERVATION.PACKAGE.UPDATE_PROGRAM_INSURANCE}/${packageId}`, payload
        );
        return response;
      } catch (e) {
        return null;
      }
    },
    // Visa
    getVisa: async (packageId: number) => {
      try {
        let response = await apiInstance.get(
          `${endPointAPI.RESERVATION.PACKAGE.GET_PROGRAM_VISA}/${packageId}`
        );
        return response;
      } catch (e) {
        return null;
      }
    },
    updateVisa: async (packageId: number, payload: TravelInsurancePayload) => {
      try {
        let response = await apiInstance.put(
          `${endPointAPI.RESERVATION.PACKAGE.UPDATE_PROGRAM_VISA}/${packageId}`, payload
        );
        return response;
      } catch (e) {
        return null;
      }
    },

        // Accommodation
        getAccommodation: async (packageId: number) => {
          try {
            let response = await apiInstance.get(
              `${endPointAPI.RESERVATION.PACKAGE.GET_PROGRAM_ACCOMMODATION}/${packageId}`
            );
            return response;
          } catch (e) {
            return null;
          }
        },
        updateAccommodation: async (packageId: number, payload: {itineraries: AccommodationItinerary[]}) => {
          try {
            let response = await apiInstance.put(
              `${endPointAPI.RESERVATION.PACKAGE.UPDATE_PROGRAM_ACCOMMODATION}/${packageId}`, payload
            );
            return response;
          } catch (e) {
            return null;
          }
        },
        // Transfer
        getTransfer: async (packageId: number) => {
          try {
            let response = await apiInstance.get(
              `${endPointAPI.RESERVATION.PACKAGE.GET_PROGRAM_TRANSFER}/${packageId}`
            );
            return response;
          } catch (e) {
            return null;
          }
        },
        updateTransfer: async (packageId: number, payload: UpdateTransferPayload) => {
          try {
            let response = await apiInstance.put(
              `${endPointAPI.RESERVATION.PACKAGE.UPDATE_PROGRAM_TRANSFER}/${packageId}`, payload
            );
            return response;
          } catch (e) {
            return null;
          }
        },
        // Flight
        getFlight: async (packageId: number) => {
          try {
            let response = await apiInstance.get(
              `${endPointAPI.RESERVATION.PACKAGE.GET_PROGRAM_FLIGHT}/${packageId}`
            );
            return response;
          } catch (e) {
            return null;
          }
        },
        updateFlight: async (packageId: number, payload: UpdateFlightPayload) => {
          try {
            let response = await apiInstance.put(
              `${endPointAPI.RESERVATION.PACKAGE.UPDATE_PROGRAM_FLIGHT}/${packageId}`, payload
            );
            return response;
          } catch (e) {
            return null;
          }
        },
        // Other
        getOtherService: async (packageId: number) => {
          try {
            let response = await apiInstance.get(
              `${endPointAPI.RESERVATION.PACKAGE.GET_PROGRAM_OTHER}/${packageId}`
            );
            return response;
          } catch (e) {
            return null;
          }
        },
        updateOtherService: async (packageId: number, payload: OtherServiceRequest) => {
          try {
            let response = await apiInstance.put(
              `${endPointAPI.RESERVATION.PACKAGE.UPDATE_PROGRAM_OTHER}/${packageId}`, payload
            );
            return response;
          } catch (e) {
            return null;
          }
        },
  },
  passengerService: {
    price: async (id: number) => {
      try {
        let request = await apiInstance.get(
          endPointAPI.RESERVATION.PASSENGER.PRICE + "/" + id
        );
        return request.data;
      } catch (e) {
        return null;
      }
    },
    updatePrice: async (id: number, data: FormPassengerPayment) => {
      try {
        let request = await apiInstance.put(
          endPointAPI.RESERVATION.PASSENGER.UPDATE_PRICE + "/" + id,
          data
        );
        return request.data;
      } catch (e) {
        return null;
      }
    },
    setContact: async (id: number, data: { contactId: number }) => {
      return await apiInstance.put(
        endPointAPI.RESERVATION.PASSENGER.SET_CONTACT + "/" + id,
        data
      );
    },
    getReservationById: async (id: number) => {
      return await apiInstance.get(
        endPointAPI.RESERVATION.PASSENGER.GET_RESERVATION_BY_ID + "/" + id
      );
    },
    storePackagePassengerNote: async (payload: {
      reservationId: number;
      packagePassengerId: number | null;
      content: string;
      attachments: any;
      type: string;
    }) => {
      try {
        let response = await apiInstance.post(
          endPointAPI.RESERVATION.PASSENGER.STORE_PASSENGER_NOTE,
          payload
        );
        return response;
      } catch (e: any) {
        return e?.response;
      }
    },
    editPackagePassengerNote: async (id: number, payload: {
      reservationId: number;
      packagePassengerId: number | null;
      content: string;
      attachments: any;
      type: string;
    }) => {
      try {
        let response = await apiInstance.put(endPointAPI.RESERVATION.PASSENGER.EDIT_PASSENGER_NOTE + `/${id}`, payload);
        return response;
      } catch (e: any) {
        return e?.response;
      }
    },
    deletePackagePassengerNote: async (id: number) => {
      try {
        let response = await apiInstance.delete(endPointAPI.RESERVATION.PASSENGER.DELETE_PASSENGER_NOTE + `/${id}`);
        return response;
      } catch (e: any) {
        return e?.response;
      }
    },
    packagePassengerNoteHistory: async (id: number) => {
      try {
        let response = await apiInstance.get(`${endPointAPI.RESERVATION.PASSENGER.HISTORY_PASSENGER_NOTE}/${id}`);
        return response.data;
      } catch (e) {
        return null
      }
    },
    packagePassengerNote: async (
      id: number | null,
      pageNum: number,
      pageSize: number
    ) => {
      try {
        let response = await apiInstance.get(
          `${endPointAPI.RESERVATION.PASSENGER.LIST_PASSENGER_NOTE}/${id}?pageNumber=${pageNum}&pageSize=${pageSize}`
        );
        return response.data;
      } catch (e) {
        return null;
      }
    },

    // document
    getDocument: async (payload: DocumentPayload) => {
      try {
        return await apiInstance.get(
          `${endPointAPI.RESERVATION.DOCUMENT.GET_LIST}/${payload.bookingRef}/${payload.roomCode}/${payload.passengerCode}`
        );
      } catch (e) {
        return null;
      }
    },
    storeDocument: async (
      payload: DocumentPayload,
      request: DocumentRequest
    ) => {
      try {
        return await apiInstance.post(
          `${endPointAPI.RESERVATION.DOCUMENT.STORE}/${payload.bookingRef}/${payload.roomCode}/${payload.passengerCode}`,
          request
        );
      } catch (e) {
        return null;
      }
    },
    deleteDocument: async (id: number) => {
      try {
        return await apiInstance.delete(
          `${endPointAPI.RESERVATION.DOCUMENT.DELETE}/${id}`
        );
      } catch (e) {
        return null;
      }
    },
  },
  actualPayment: {
    getByStatus: async (id: number, type: string) => {
      try {
        let request = await apiInstance.get(
          endPointAPI.RESERVATION.ACTUAL_PAYMENT.BY_STATUS +
          "/" +
          id +
          "/" +
          type
        );
        return request.data;
      } catch (e) {
        return [];
      }
    },
  },
};
export default reservationService;
