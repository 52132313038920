import { Box, CircularProgress, InputAdornment, Stack, TextField, Typography, styled } from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { AddToPhotos, CloudUpload, UploadFile } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import imageService from "../../services/imageService";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { overviewPrdPost, overviewSightseeing } from "../../interfaces/product";
import validationMessage from "../../constants/validationMessage";

interface Props {
  type: "single" | "multi";
  onChange: (data: any) => void;
  label: string;
  height: number | string | null;
  width: number | string | null;
  maxFileSize?: number;
  maxFile?: number;
  folder: string;
  id: string;
  name?: string;
  defaultValue?: string | null;
  value?: string | null;
  register?: UseFormRegister<any>;
  errors?: FieldErrors<overviewPrdPost>;
  isEdit?: boolean;
  placeholder?: string;
  isError?: boolean;
}

const SmartImageUpload = (param: Props) => {
  const {
    isEdit = true,
    placeholder = "Input Image URL",
    isError,
    defaultValue = ""
  } = param;
  const [loading, setLoading] = useState(false);
  const [urlFile, setUrlFile] = useState("");
  const refInputFile = useRef<HTMLInputElement>(null);

  const changeInputFile = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLoading(true);
    const files = (e.target as HTMLInputElement).files;
    if (files && files.length) {
      const file = files[0];
      const reader = new FileReader();
      let errorMessage = "";
      if (!file.type.startsWith("image/")) {
        errorMessage = "Only image files are allowed.";
      }
      if (
        file.size / 1024 >
        Number(process.env.REACT_APP_PUBLIC_IMAGE_MAX_SIZE)
      ) {
        errorMessage =
          "Max file size is " +
          Number(process.env.REACT_APP_PUBLIC_IMAGE_MAX_SIZE) / 1024 +
          "MB!";
      }
      if (errorMessage) {
        Swal.fire({
          icon: "error",
          text: errorMessage,
          timer: 2000,
          showConfirmButton: false,
          showCancelButton: false,
        });
        setLoading(false);
        return;
      }
      reader.onload = async () => {
        const res = await imageService.uploadImage(
          file,
          param.folder,
          param.width,
          param.height,
          process.env.REACT_APP_BUCKET_S3,
          process.env.REACT_APP_BUCKET_FOLDER,
          localStorage.getItem("companyCode")
        );
        if (res && res.status === 200) {
          if (res.data.response) {
            const splitLink = res.data.response.path.split("/");

            const imagePath = splitLink.slice(2).join("/");
            if (imagePath) setUrlFile(`/${imagePath}`);
          }
          setLoading(false);
          await Swal.fire("Upload Image Successfully!", "", "success");
        } else {
          await Swal.fire("Upload Image Fail!", "", "error");
        }
        if (refInputFile.current) refInputFile.current.value = "";
        setLoading(false);
      };
      reader.readAsDataURL(file);
    } else {
      Swal.fire({
        icon: "error",
        text: "Please select an image.",
        timer: 2000,
        showConfirmButton: false,
        showCancelButton: false,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (urlFile) {

      param.onChange(urlFile);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlFile]);

  useEffect(() => {
    console.log(defaultValue, typeof defaultValue);

    if (defaultValue) {
      setUrlFile(defaultValue);
    } else {
      setUrlFile("");
    }
  }, [defaultValue]);

  const VisuallyHiddenInput = styled("input")({
    height: "100%",
    width: "100%",
    cursor: "pointer",
    position: "absolute",
    fontSize: "13px",
    opacity: 0,
  });

  return (
    <>
      <TextField
        // className="mb-0"
        size={"small"}
        fullWidth
        placeholder={placeholder}
        value={urlFile}
        onChange={(e) => {
          setUrlFile(e.target.value);
        }}
        disabled={!isEdit}
        label={`${param.label}`}
        InputLabelProps={{ shrink: true }}
        sx={{
          ".MuiInputBase-root": {
            pl: 1,
          },
        }}

        error={
          (param.errors && param.errors.coverImage !== undefined) || isError
        }
        helperText={
          (param.errors && param.errors.coverImage !== undefined) || isError
            ? validationMessage.required
            : ""
        }
      />
      {urlFile ? <Box mt={"10px"}>
        <img
          src={
            !urlFile.includes("https")
              ? `${process.env.REACT_APP_URL_S3_IMAGE}/${process.env.REACT_APP_BUCKET_FOLDER}${urlFile}`
              : urlFile
          }
          alt="avatar"
          style={{
            objectFit: "contain",
            maxHeight: "200px",
            width: param.type === "multi" ? "33.33%" : "100%",
            borderRadius: "8px",
            maxWidth: "fit-content",
          }}
        />
      </Box> : <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        border={"1px dashed #d9d9d9"}
        borderRadius={"8px"}
        sx={{ background: "#f5f5f5", p: 2, overflow: "hidden", position: "relative", zIndex: !isEdit ? "-1" : "1" }}


      >
        {loading ? <CircularProgress /> : <>
          <AddToPhotos sx={{ fontSize: "35px", color: "#174d75" }} />
          <Typography variant="body1" sx={{ fontWeight: 600, color: "#414141", mt: 1 }}>Add Image</Typography>
          <Typography variant="body2" sx={{ color: "#414141" }}>or Drag & Drop</Typography>
          <VisuallyHiddenInput
            ref={refInputFile}
            type="file"
            onChange={changeInputFile}
            id={param.id}
            accept={"image/png, image/jpeg"}
          />
        </>}

      </Box>}
    </>
  );
};

export default SmartImageUpload;
