const endPointAPI = {
    AUTHENTICATION: {
        SEND_SIGN_IN_CODE: "/auth/send-sign-in-code",
        VERIFY_SIGN_IN_CODE: "/auth/verify-sign-in-code",
        SEND_FORGOT_PASSWORD: "/auth/send-forgot-password-request",
        VERIFY_FORGOT_PASSWORD_TOKEN:
            "/auth/verify-forgot-password-token",
        UPDATE_PASSWORD: "/auth/forgot-password",
        REGISTRATION: "/registration/store",
    },
    USER: {
      CURRENT: "/user/current",
      COMPANY: "/user/company",
        FIND_SIMPLE: "/user/find-simple",
    },
    MASTER: {
        COMPANY_DETAIL: "/company/detail-by-code",
    },
    PERMISSION: {
        GET: "/role-and-permission/get-permission",
        GET_BY_FEATURE: "/role-and-permission/get-permission-by-feature",
        CREATE: "/role-and-permission/store-permission",
        UPDATE: "/role-and-permission/update-permission",
        DELETE: "/role-and-permission/delete-permission",
    },
  PURCHASE_ORDER: {
    LIST: "/reservation/purchase-order/orders",
    UPDATE_OVERVIEW: "/reservation/purchase-order/update-overview",
    STORE: "reservation/purchase-order/store",
    ADD_MORE_SERVICE: "reservation/purchase-order/add-more-service",
    DETAIL: "reservation/purchase-order/detail",
    COMMON_DATA: "/reservation/purchase-order/common-data",
    PACKAGE: {
      CANCEL: "/reservation/purchase-order/package/cancel",
      DELETE: "/reservation/purchase-order/package/delete",
      INFORMATION: "/reservation/purchase-order/package/information",
      LIST_BY_REFERENCE: "reservation/purchase-order/package/find-by-reference",
      UPDATE: "/reservation/purchase-order/package/update",
      UPDATE_INFORMATION: "/reservation/purchase-order/package/update-information",
      STORE_NOTE: "/reservation/purchase-order/package/store-package-note",
      EDIT_NOTE: "/reservation/purchase-order/package/edit-package-note",
      DELETE_NOTE: "/reservation/purchase-order/package/delete-package-note",
      LIST_NOTE: "/reservation/purchase-order/package/package-note",
      STORE_INVOICE: "/reservation/purchase-order/package/store-package-invoice",
      LIST_INVOICE: "/reservation/purchase-order/package/package-invoice",
      DELETE_INVOICE: "/reservation/purchase-order/package/delete-package-invoice",
      PROGRAM: "/reservation/purchase-order/package/program",
       // Travel Insurance
       GET_PROGRAM_TRAVEL_INSURANCE: "/reservation/purchase-order/package/program-insurance",
       UPDATE_PROGRAM_INSURANCE: "/reservation/purchase-order/package/update-program-insurance",
       // Visa
       GET_PROGRAM_VISA: "/reservation/purchase-order/package/program-visa",
       UPDATE_PROGRAM_VISA: "/reservation/purchase-order/package/update-program-visa",
       // Accommodation
       GET_PROGRAM_ACCOMMODATION: "/reservation/purchase-order/package/program-accommodation",
       UPDATE_PROGRAM_ACCOMMODATION:
        "/reservation/purchase-order/package/update-program-accommodation",
       // Transfer
       GET_PROGRAM_TRANSFER: "/reservation/purchase-order/package/program-transfer",
       UPDATE_PROGRAM_TRANSFER:
        "/reservation/purchase-order/package/update-program-transfer",
       // Flight
       GET_PROGRAM_FLIGHT: "/reservation/purchase-order/package/program-flight",
       UPDATE_PROGRAM_FLIGHT:
        "/reservation/purchase-order/package/update-program-flight",
       // Other service
       GET_PROGRAM_OTHER: "/reservation/purchase-order/package/program-other-service",
       UPDATE_PROGRAM_OTHER:
        "/reservation/purchase-order/package/update-program-other-service",
    }
  },
    ROLE: {
        GET: "/role-and-permission/get-role",
        CREATE: "/role-and-permission/store-role",
        UPDATE: "/role-and-permission/update-role",
        DELETE: "/role-and-permission/delete-role",
        ROLE_TO_PERMISSION: "/role-and-permission/get-role-to-permission",
        ACTION_ROLE_TO_PERMISSION: "/role-and-permission/role-to-permission",
        GET_ACCESS_FEATURE: "/role-and-permission/get-access-feature",
        GET_ACCESS_FEATURE_TO_ROLE:
            "/role-and-permission/get-access-feature-to-role",
        GET_ACCESS_FEATURE_TO_ROLE_BY_ROLE_ID:
            "/role-and-permission/get-access-feature-to-role-by-role-id",
        ACTION_ACCESS_FEATURE_TO_ROLE:
            "/role-and-permission/store-access-feature-to-role",
    },
    PLATFORM_USER: {
      GET: "/user/index",
      GET_COMPANIES: "/user/companies",
        GET_USER: "/user/index-user",
        CREATE: "/user/store",
        UPDATE: "/user/update",
        UPDATE_STATUS: "/user/update-status",
        DELETE: "/user/delete",
        CREATE_KPI: '/user/store-kpi',
        UPDATE_KPI: '/user/update-kpi',
        UPDATE_RATE_KPI: '/user/update-rate-kpi',
        CREATE_COMMISSION: '/user/store-commission',
        UPDATE_COMMISSION: '/user/update-commission',
        GET_KPI: '/user/kpi',
        GET_COMMISSION: '/user/commission',
        GET_KPI_HISTORY: '/user/history-kpi',
        GET_COMMISSION_HISTORY: '/user/history-commission',
        GET_COMMISSION_NOTE: '/user/commission-note',
        STORE_COMMISSION_NOTE: '/user/store-commission-note',
    },

  IMAGE: {
    UPLOAD_IMAGE: "api/components/upload-image",
  },
  PDF: {
    PASSENGER_INVOICE: "/invoice/passenger",
    ROOM_INVOICE: "/invoice/room",
    PACKAGE_INVOICE: "/invoice/package",
    MASTER_INVOICE: "/invoice/master",
    DELETE_INVOICE: "/invoice/delete",
    INTINERARIES: "/invoice/itineraries",
  },
  MAIL: {
    SEND_MAIL: "/set-schedule",
    STORE_RECEIVER: "/setting/mail-receiver/store",
  },
  COMPANY_CRM: {
    GET_LIST: "/crm/company",
    GET_LIST_SIMPLE: "/crm/company/list-simple",
    SUPPLIERS: "/crm/company/suppliers",
    GET_ESTIMATE_SALE: "/crm/company/estimate-sale",
    GET_COMMISSION_LEVEL: "/crm/company/commission-level",
    GET_DETAIL: "/crm/company/show",
    CREATE: "/crm/company/store",
    CREATE_ESTIMATE_SALE: "/crm/company/store-estimate-sale",
    UPDATE: "/crm/company/update",
    UPDATE_ESTIMATE_SALE: "/crm/company/update-estimate-sale",
    DELETE: "/crm/company/delete",
    STORE_NOTE: "/crm/company/store-note",
    SEND_EMAIL: "/crm/company/send-email",
    GET_NOTES: "/crm/company/notes",
    GET_HISTORY: "/crm/company/history",
    GET_HISTORY_ESTIMATE_SALE: "/crm/company/history-estimate-sale",
    GET_HISTORY_COMMISSION_LEVEL: "/crm/company/history-commission-level",
    GET_EMAIL_SENT: "/crm/company/email-sent",
    GET_LIST_DOCUMENTS: "/crm/company/documents",
    GET_ALL_DOCUMENTS: "/crm/company/list-documents",
    UPLOAD_DOCS: "/crm/company/upload-document",
    DELETE_DOCS: "/crm/company/delete-document",
    GET_COMPANY_BY_MAIN_CONTACT: "/crm/company/find-by-main-contact",
  },
  CONTACT_CRM: {
    GET_LIST: "/crm/contact",
    GET_LIST_SIMPLE: "/crm/contact/list-simple",
    GET_LIST_SIMPLE_COMPANY: "/crm/contact/list-simple-company",
    GET_KPI: "/crm/contact/kpi",
    GET_EMERGENCY: "/crm/contact/emergency",
    GET_DETAIL: "/crm/contact/show",
    CREATE: "/crm/contact/store",
    CREATE_IN_PASSENGER: "/crm/contact/store-in-passenger",
    CREATE_KPI: "/crm/contact/store-kpi",
    UPDATE: "/crm/contact/update",
    UPDATE_TYPE: "/crm/contact/update-type",
    UPDATE_KPI: "/crm/contact/update-kpi",
    DELETE: "/crm/contact/delete",
    STORE_NOTE: "/crm/contact/store-note",
    SEND_EMAIL: "/crm/contact/send-email",
    GET_NOTES: "/crm/contact/notes",
    GET_HISTORY: "/crm/contact/history",
    GET_HISTORY_KPI: "/crm/contact/history-kpi",
    GET_HISTORY_EMERGENCY: "/crm/contact/history-emergency",
    GET_EMAIL_SENT: "/crm/contact/email-sent",
    GET_LIST_DOCUMENTS: "/crm/contact/documents",
    UPLOAD_DOCS: "/crm/contact/upload-document",
    DELETE_DOCS: "/crm/contact/delete-document",
    FIND_SIMPLE: "/crm/contact/find-simple",
    CHECK_HAS_ONLINE_FORM:
      "reservation/package/check-has-form-online-by-contact-id",
  },

  SOLUTION: {
    GET_LIST: "solution",
  },

  PRODUCT: {
    // tour
    GET_LIST: "/product/tour",
    GET_SIMPLE_LIST_TOUR: "/product/tour/find-simple",
    GET_SIMPLE_LIST_GOLF: "/product/golf/find-simple",
    GET_SIMPLE_LIST_CRUISE: "/product/cruise/find-simple",
    GET_SIMPLE_LIST_SIGHTSEEING: "/product/sightseeing-activity/find-simple",
    GET_SIMPLE_LIST_PACKAGE: "/product/package/find-simple",
    GET_SIMPLE_LIST_SAFETY_NET: "/product/safety-net/find-simple",

    GET_DETAIL: "/product/tour/detail",
    CREATE: "/product/tour/store",
    UPDATE: "/product/tour/update",
    CHECK_TRIPCODE_USED: "/product/tour/check-trip-code-used",
    DELETE: "/product/tour/delete",
    GET_DESTINATIONS_BY_COUNTRIES:
      "/product/location/destinations-by-countries",
    GET_LIST_COUNTRIES: "/product/location/countries",
    GET_DATA_FILTER_TOUR: "/product/tour/filter",
    GET_LOCATION_MAP: "/product/location/map",


    // tour price
    GET_LIST_TOUR_PRICE: "/product/price/tour/list",
    GET_AVAILABLE_TOUR_PRICE: "/product/price/tour/available",
    GET_DETAIL_TOUR_PRICE: "/product/price/tour/detail",
    CREATE_TOUR_PRICE: "/product/price/tour/store",
    UPDATE_TOUR_PRICE: "/product/price/tour/update",
    DELETE_TOUR_PRICE: "/product/price/tour/delete",
    IMPORT_TOUR_PRICE: "/product/price/tour/import",
    UPDATE_TOUR_MULTI_MARKUP_PRICE: "/product/price/tour/multi-markup",
    UPDATE_TOUR_MULTI_DISCOUNT_PRICE: "/product/price/tour/multi-discount",

    // golf
    GET_LIST_GOLF: "/product/golf",
    GET_DETAIL_GOLF: "/product/golf/detail",
    CREATE_GOLF: "/product/golf/store",
    UPDATE_GOLF: "/product/golf/update",
    CHECK_TRIPCODE_USED_GOLF: "/product/golf/check-trip-code-used",
    DELETE_GOLF: "/product/golf/delete",
    GET_DATA_FILTER_GOLF: "/product/golf/filter",
    // golf price
    GET_LIST_GOLF_PRICE: "/product/price/golf/list",
    GET_DETAIL_GOLF_PRICE: "/product/price/golf/detail",
    CREATE_GOLF_PRICE: "/product/price/golf/store",
    UPDATE_GOLF_PRICE: "/product/price/golf/update",
    DELETE_GOLF_PRICE: "/product/price/golf/delete",
    IMPORT_GOLF_PRICE: "/product/price/golf/import",
    UPDATE_GOLF_MULTI_MARKUP_PRICE: "/product/price/golf/multi-markup",
    UPDATE_GOLF_MULTI_DISCOUNT_PRICE: "/product/price/golf/multi-discount",

    // cruise
    GET_LIST_CRUISE: "/product/cruise",
    GET_DETAIL_CRUISE: "/product/cruise/detail",
    CREATE_CRUISE: "/product/cruise/store",
    UPDATE_CRUISE: "/product/cruise/update",
    CHECK_TRIPCODE_USED_CRUISE: "/product/cruise/check-trip-code-used",
    DELETE_CRUISE: "/product/cruise/delete",
    GET_DATA_FILTER_CRUISE: "/product/cruise/filter",
    GET_PACKAGE_CONTENT_CRUISE: "/product/cruise/reservation-package-content",
    // cruise price
    GET_LIST_CRUISE_PRICE: "/product/price/cruise/list",
    GET_DETAIL_CRUISE_PRICE: "/product/price/cruise/detail",
    CREATE_CRUISE_PRICE: "/product/price/cruise/store",
    UPDATE_CRUISE_PRICE: "/product/price/cruise/update",
    DELETE_CRUISE_PRICE: "/product/price/cruise/delete",
    IMPORT_CRUISE_PRICE: "/product/price/cruise/import",
    UPDATE_CRUISE_MULTI_MARKUP_PRICE: "/product/price/cruise/multi-markup",
    UPDATE_CRUISE_MULTI_DISCOUNT_PRICE: "/product/price/cruise/multi-discount",

    // sightseeing
    GET_LIST_SIGHTSEEING: "/product/sightseeing-activity",
    GET_DETAIL_SIGHTSEEING: "/product/sightseeing-activity/detail",
    CREATE_SIGHTSEEING: "/product/sightseeing-activity/store",
    UPDATE_SIGHTSEEING: "/product/sightseeing-activity/update",
    CHECK_TRIPCODE_USED_SIGHTSEEING:
      "/product/sightseeing-activity/check-trip-code-used",
    DELETE_SIGHTSEEING: "/product/sightseeing-activity/delete",
    GET_DATA_FILTER_SIGHTSEEING: "/product/sightseeing-activity/filter",
    FIND_FOR_RESERVATION: "/product/sightseeing-activity/find-for-reservation",

    // sightseeing price
    GET_DETAIL_SIGHTSEEING_PRICE: "/product/sightseeing-activity/price/find",
    CREATE_SIGHTSEEING_PRICE: "/product/sightseeing-activity/price/store",
    UPDATE_SIGHTSEEING_PRICE: "/product/sightseeing-activity/price/update",
    DELETE_SIGHTSEEING_PRICE: "/product/sightseeing-activity/price/delete",

    // package
    GET_LIST_PACKAGE: "/product/package",
    GET_DETAIL_PACKAGE: "/product/package/detail",
    CREATE_PACKAGE: "/product/package/store",
    UPDATE_PACKAGE: "/product/package/update",
    CHECK_TRIPCODE_USED_PACKAGE: "/product/package/check-trip-code-used",
    DELETE_PACKAGE: "/product/package/delete",
    GET_DESTINATIONS_BY_COUNTRIES_PACKAGE:
      "/product/location/destinations-by-countries",
    GET_LIST_COUNTRIES_PACKAGE: "/product/location/countries",
    GET_DATA_FILTER_PACKAGE: "/product/package/filter",

    // package price
    GET_LIST_PACKAGE_PRICE: "/product/price/package/list",
    GET_AVAILABLE_PACKAGE_PRICE: "/product/price/package/available",
    GET_DETAIL_PACKAGE_PRICE: "/product/price/package/detail",
    CREATE_PACKAGE_PRICE: "/product/price/package/store",
    UPDATE_PACKAGE_PRICE: "/product/price/package/update",
    DELETE_PACKAGE_PRICE: "/product/price/package/delete",
    IMPORT_PACKAGE_PRICE: "/product/price/package/import",
    UPDATE_PACKAGE_MULTI_MARKUP_PRICE: "/product/price/package/multi-markup",
    UPDATE_PACKAGE_MULTI_DISCOUNT_PRICE: "/product/price/package/multi-discount",


    // Safety Net
    GET_LIST_SAFETY_NET: "/product/safety-net",
    GET_DETAIL_SAFETY_NET: "/product/safety-net/detail",
    CREATE_SAFETY_NET: "/product/safety-net/store",
    UPDATE_SAFETY_NET: "/product/safety-net/update",
    CHECK_TRIPCODE_USED_SAFETY_NET: "/product/safety-net/check-trip-code-used",
    DELETE_SAFETY_NET: "/product/safety-net/delete",
    GET_DATA_FILTER_SAFETY_NET: "/product/safety-net/filter",

    //  categories
    GET_LIST_CATEGORIES: "/setting/category",
    CREATE_CATEGORY: "/product/category/store",
    UPDATE_CATEGORY: "/product/category/update",
    DELETE_CATEGORY: "/product/category/delete",

    // Golf course
    GET_LIST_GOLF_COURSE: "/golf-course",
    STORE_GOLF_COURSE: "/golf-course/store",
    UPDATE_GOLF_COURSE: "/golf-course/update",
    DETAIL_GOLF_COURSE: "/golf-course/detail",
    HISTORY_GOLF_COURSE: "/golf-course/history",
    DELETE_GOLF_COURSE: "/golf-course/delete",

        // common
    QUICK_SEARCH: "/product/common/quick-search"
  },
  SETTING: {
    PAYMENT_RULE: {
      GET_LIST: "/setting/payment-rule",
      STORE: "/setting/payment-rule/store",
      UPDATE: "/setting/payment-rule/update",
      DETAIL: "/setting/payment-rule/detail",
    },
    PASSENGER_RATE: {
      STORE: "/setting/passenger-rate/store",
      FIND_BY_TYPE: "/setting/passenger-rate/find-by-type",
    },
    CURRENCY_EXCHANGE: {
      GET_LIST: "/setting/currency-rate/list",
      GET_DETAIL: "/setting/currency-rate/detail",
      STORE: "/setting/currency-rate/store",
      UPDATE: "/setting/currency-rate/update",
      DELETE: "/setting/currency-rate/delete",
      GET_HISTORY: "/setting/currency-rate/get-list-history",
    },
    COMMISSION_RATE: {
      GET_BY_KEYS: "/setting/commission/find-all-by-keys",
      STORE: "/setting/commission/store",
    },
    GET_BY_GROUP: "/setting/find-by-group",
    STORE: "/setting/store",
    GENERAL_SETTINGS: {
      GET_ALL_KEYS: "/setting/find-by-group",
      GET_BY_KEY: "/setting/find-by-key-group",
      STORE: "/setting/store",
    },
    COMPANY_SETTINGS: {
      STORE: "/setting/store-company-settings",
      STORE_PAYMENT: "/setting/store-payment-options",
      GET_COMPANY_SETTINS: "/setting/company",
      GET_PAYMENT_OPTIONS: "/setting/find-payment-option",
    },
    POLICY: {
      STORE: "/setting/store-policy",
      UPDATE: "/setting/update-policy",
      GET_ALL: "/setting/find-all-policy",
      GET_ALL_HISTORY: "/setting/find-all-policy-history",
      GET_BY_TYPE: "/setting/find-policy-by-type",
      GET_BY_ID: "/setting/find-policy-by-id",
      DELETE: "/setting/delete-policy",
    },
    TERM: {
      STORE: "/setting/store-term",
      UPDATE: "/setting/update-term",
      GET_ALL: "/setting/find-all-term",
      GET_ALL_HISTORY: "/setting/find-all-term-history",
      GET_BY_TYPE: "/setting/public/find-term-by-type",
      GET_BY_ID: "/setting/find-term-by-id",
      DELETE: "/setting/delete-term",
    },
    EMAIL_CONFIG: {
      FIND_ALL: "/setting/email-config/find-all",
      FIND_BY_ID: "/setting/email-config/find-by-id",
      FIND_HISTORY_BY_ID: "/setting/email-config/find-history-by-id",
      STORE: "/setting/email-config/store",
      UPDATE: "/setting/email-config/update",
      DELETE: "/setting/email-config/delete",
    },
    FEEDBACK: {
      STORE_QUESTION: "setting/feedback/store-question",
      UPDATE_QUESTION: "setting/feedback/update-question",
      GET_LIST_QUESTION: "setting/public/feedback/get-list-question",
      GET_HISTORY_QUESTION: "setting/feedback/get-list-history-question",
      GET_DETAIL_QUESTION: "setting/feedback/get-detail-question",
      DELETE_QUESTION: "setting/feedback/delete-question",
      STORE_RATING: "setting/feedback/store-rating",
      UPDATE_RATING: "setting/feedback/update-rating",
      GET_LIST_RATING: "setting/public/feedback/get-list-rating",
      GET_HISTORY_RATING: "setting/feedback/get-list-history-rating",
      GET_DETAIL_RATING: "setting/feedback/get-detail-rating",
      DELETE_RATING: "setting/feedback/delete-rating",
    },
  },

  RESERVATION: {
    LIST_BOOKING: "reservation/bookings",
    LIST_QUOTATION: "reservation/quotations",
    ADD_MORE_SERVICE: "reservation/add-more-exist-service",
    ADD_MORE_INSURANCE: "reservation/add-more-insurance",
    ADD_MORE_VISA: "reservation/add-more-visa",
    ADD_MORE_ACCOMMODATION: "reservation/add-more-accommodation",
    ADD_MORE_TRANSFER: "reservation/add-more-transfer",
    ADD_MORE_FLIGHT: "reservation/add-more-flight",
    ADD_MORE_OTHER: "reservation/add-more-other-service",
    QUICK_SEARCH: "reservation/quick-search",

    ADD_MORE_SIGHTSEEING_ACTIVITY_SERVICE:
      "reservation/add-more-exist-sightseeing-activity-service",
    CONVERT_TO_BOOKING: "reservation/convert-to-booking",
    ADD_MORE_CUSTOM_SERVICE: "reservation/add-more-service",
    FILTER: "reservation/filter",
    CLONE: "reservation/clone",
    MOVE_TO_TRASH: "/reservation/move-to-trash",
    RESTORE: "/reservation/restore",
    MANUAL: {
      STORE_MANUAL_CUSTOM: "/reservation/store-manual-custom",
      STORE_MANUAL_WITH_PRODUCT: "/reservation/store-with-product",
    },
    GET_RESERVATION_BY_CONTACT_IDS: "/reservation/get-by-contact-ids",
    PACKAGE: {
      DELETE: "/reservation/package/delete",
      LIST_BY_REFERENCE: "/reservation/package/find-by-reference",
      CLONE: "/reservation/package/clone",
      UPDATE: "/reservation/package/update",
      PROGRAM: "/reservation/package/program",
      CANCEL: "/reservation/package/cancel",
      AMENDMENT_REQUEST: "/reservation/package/amendment-request",
      UPDATE_STATUS_AMENDMENT_REQUEST:
        "/reservation/package/amendment-request/update",
      UPDATE_PROGRAM: "/reservation/package/update-program",
      GET_INVOICE: "/reservation/package-invoice/get-invoice-pdf",
      GET_LIST_INVOICE: "/reservation/package-invoice/get-list-invoice-pdf",
      GET_LIST_ALL_INVOICE: "/reservation/package-invoice/get-list-all-invoice",
      GET_LIST_INVOICE_PASSENGER:
        "/reservation/package-invoice/get-list-invoice-pdf-passenger",
      CHECK_INVOICE: "/reservation/package-invoice/check-invoice-pdf",
      STORE_INVOICE: "/reservation/package-invoice/store-invoice-pdf",
      DELETE_INVOICE: "/reservation/package-invoice/delete-invoice",
      GET_INVOICE_DATA: "/reservation/package/invoice-data",
      HISOTRY_INVOICE: "/reservation/package-invoice/package-invoice-history",
      HISOTRY_INVOICE_PASSENGER:
        "/reservation/package-invoice/package-passenger-invoice-history",
      STORE_NOTE: "/reservation/package/store-package-note",
      EDIT_NOTE: "/reservation/package/edit-package-note",
      DELETE_NOTE: "/reservation/package/delete-package-note",
      HISTORY_NOTE: "/reservation/package/package-note-history",
      LIST_NOTE: "/reservation/package/package-note",

      GET_INVOICE_MASTER_DATA: "/reservation/master/list-invoice",
      STORE_INVOICE_MASTER: "/reservation/master/store-invoice",
      DELETE_INVOICE_MASTER: "/reservation/master/delete-invoice",
      HISOTRY_INVOICE_MASTER: "/reservation/master/history-invoice",
      STORE_NOTE_MASTER: "/reservation/master/store-note",
      LIST_NOTE_MASTER: "/reservation/master/list-note",
      GET_LIST_HISTORY_EMAIL_ITINERARY:
        "/reservation/package/history-email-itinerary",
      STORE_HISTORY_EMAIL_ITINERARY:
        "/reservation/package/store-history-email-itinerary",
      GET_PROGRAM_SIGHTSEEING_ACTIVITY:
        "/reservation/package/program-sightseeing-activity",
      // Travel Insurance
      GET_PROGRAM_TRAVEL_INSURANCE: "/reservation/package/program-insurance",
      UPDATE_PROGRAM_INSURANCE: "/reservation/package/update-program-insurance",
      // Visa
      GET_PROGRAM_VISA: "/reservation/package/program-visa",
      UPDATE_PROGRAM_VISA: "/reservation/package/update-program-visa",
      // Accommodation
      GET_PROGRAM_ACCOMMODATION: "/reservation/package/program-accommodation",
      UPDATE_PROGRAM_ACCOMMODATION:
        "/reservation/package/update-program-accommodation",
      // Transfer
      GET_PROGRAM_TRANSFER: "/reservation/package/program-transfer",
      UPDATE_PROGRAM_TRANSFER:
        "/reservation/package/update-program-transfer",
      // Flight
      GET_PROGRAM_FLIGHT: "/reservation/package/program-flight",
      UPDATE_PROGRAM_FLIGHT:
        "/reservation/package/update-program-flight",
      // Other service
      GET_PROGRAM_OTHER: "/reservation/package/program-other-service",
      UPDATE_PROGRAM_OTHER:
        "/reservation/package/update-program-other-service",
    },
    PASSENGER: {
      PRICE: "/reservation/passenger/price",
      SET_CONTACT: "reservation/passenger/set-contact",
      GET_RESERVATION_BY_ID: "reservation/package/get-all-by-contact-id",
      STORE_PASSENGER_NOTE: "/reservation/package/store-package-passenger-note",
      LIST_PASSENGER_NOTE: "/reservation/package/package-passenger-note",
      EDIT_PASSENGER_NOTE: "/reservation/package/edit-package-passenger-note",
      DELETE_PASSENGER_NOTE:
        "/reservation/package/delete-package-passenger-note",
      HISTORY_PASSENGER_NOTE:
        "/reservation/package/package-passenger-note-history",
      UPDATE_PRICE: "/reservation/passenger/price/update",
    },
    ROOM: {
      UPDATE_PRICE: "/reservation/room/payment/update",
      PRICE: "/reservation/room/payment/detail",
      ADD_MORE_PASSENGER: "/reservation/room/add-more-passenger",
      DELETE_PASSENGER: "/reservation/room/delete-passenger",
      DELETE_ROOM: "/reservation/room/delete",
      ADD_MORE_ROOM: "/reservation/room/add-more",
      STORE_ROOM_NOTE: "/reservation/package/store-room-note",
      LIST_ROOM_NOTE: "/reservation/package/room-note",
      EDIT_ROOM_NOTE: "/reservation/package/edit-package-room-note",
      DELETE_ROOM_NOTE: "/reservation/package/delete-package-room-note",
      HISTORY_ROOM_NOTE: "/reservation/package/package-room-note-history",
      HISTORY_INVOICE_ROOM:
        "/reservation/package-invoice/package-room-invoice-history",
      GET_LIST_INVOICE:
        "/reservation/package-invoice/get-list-invoice-pdf-room",
    },
    ACTUAL_PAYMENT: {
      BY_STATUS: "/reservation/package/payments-by-status",
    },
    COMMON_DATA: "/reservation/common-data",
    DETAIL: "/reservation/detail",
    UPDATE_OVERVIEW: "/reservation/update/overview",
    DOCUMENT: {
      GET_LIST: "/reservation/document/public",
      STORE: "reservation/document/public/store-document",
      DELETE: "reservation/document/public/delete-document",
    },
  },
  PRICE: {
    TOUR: {
      AVAILABLE: "/product/price/tour/available",
    },
    GOLF: {
      AVAILABLE: "/product/price/golf/available",
    },
  },
  CONTENT: {
    GOLF_COURSE: "/contentplatform/golf-course",
    GET_CONTENT_BY_ID: "/contentplatform/golf-course/show",
    GET_CONTENT_SIMPLE: "/contentplatform/golf-course/content/find-simple",
  },
  FEEDBACK: {
    GET_ROOM: "reservation/feedback/public/find-by-room",
    GET_PASSENGER: "reservation/feedback/public/find-by-passenger",
    STORE_ROOM: "reservation/feedback/public/store-room",
    STORE_PASSENGER: "reservation/feedback/public/store-passenger",
    GET_DETAIL: "reservation/feedback/public/detail",
    CHECK_HAS_FEEDBACK: "reservation/feedback/public/check-has-feedback",
  },
  PASSENGER_FORM: {
    GET_DATA_RESERVATION:
      "reservation/passenger-online-form/public/find-all-passenger",
    GET_BY_PASSENGER:
      "reservation/passenger-online-form/public/find-by-passenger",
    GET_BY_ROOM: "reservation/passenger-online-form/public/find-by-room",
    GET_BY_RESERVATION:
      "reservation/passenger-online-form/public/find-by-reservation",
    STORE: "reservation/passenger-online-form/public/store",
    CHECK_HAS_FORM:
      "reservation/passenger-online-form/public/check-has-passenger",
  },

  TASK: {
    GET_LIST: "/task/get-list",
    GET_LIST_COMPLETE: "/task/task-list",
    CREATE: "/task/store",
    DELETE: "/task/delete",
    DETAIL: "/task/detail",
    UPDATE: "/task/update",
    FAVORITE: "/task/favorite",
    UPDATE_DEADLINE: "/task/update-deadline",
    FINISH: "/task/finish-task",
    HISTORY: "/task/history",
    ANALYTICS: "/task/analytics/total-task",
  },
};

export default endPointAPI;
