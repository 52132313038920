// eslint-disable-next-line import/no-anonymous-default-export
export default {
  rolesList: [
    {
      id: 1,
      name: "Admin",
      slug: "admin",
      createdBy: 5,
      createdAt: "2023-11-15 00:39",
      userCreate: {
        id: 5,
        firstName: "Dominic",
        lastName: "Le",
        email: "dominic@tweetworldtravel.com",
      },
      roleToPermissions: [],
      roleHistoryEvents: [],
    },
    {
      id: 2,
      name: "User",
      slug: "user",
      createdBy: 5,
      createdAt: "2023-11-15 00:40",
      userCreate: {
        id: 5,
        firstName: "Dominic",
        lastName: "Le",
        email: "dominic@tweetworldtravel.com",
      },
      roleToPermissions: [],
      roleHistoryEvents: [],
    },
  ],
  overviewType: [
    {
      label: "Plain Text",
      value: "text",
    },
    {
      label: "Dropdown",
      value: "select",
    },
    {
      label: "Linked Content Post (Related)",
      value: "content",
    },
    {
      label: "Linked Product Post (Related)",
      value: "product",
    },
    {
      label: "Date",
      value: "date",
    },
    {
      label: "Time",
      value: "time",
    },
    {
      label: "Date/Time",
      value: "datetime",
    },
    {
      label: "Date Range",
      value: "daterange",
    },
  ],
  contentPosts: [
    {
      label: "All Types",
      value: "all_type",
    },
    {
      label: "About Us",
      value: "about_us",
    },
    {
      label: "Blog",
      value: "blog",
    },
    {
      label: "Destination",
      value: "destination",
    },
    {
      label: "Golf Tour",
      value: "golf_tour",
    },
    {
      label: "Investor Proposal",
      value: "investor_proposal",
    },
    {
      label: "News",
      value: "news",
    },
    {
      label: "Term Conditions",
      value: "term_conditions",
    },
    {
      label: "Press Release",
      value: "press_release",
    },
  ],
  productPosts: [
    {
      label: "Accommodation",
      value: "accommodation",
    },
    {
      label: "Cruise",
      value: "cruise",
    },
    {
      label: "Tour",
      value: "tour",
    },
  ],
  inclusionsTitle: ["accommodation", "first_class_service", "sightseeing"],
  defaultOverviewFields: [
    { title: "Trip Code", desc: "Unique Generated Code for an Article" },
    { title: "Title", desc: "The title of the Post" },
    { title: "Slug", desc: "Automatically Generated" },
    { title: "Product Summary", desc: "The summary for the Post" },
    {
      title: "Itinerary Notes",
      desc: "Only included if the Itinerary product type is selected, is appended to itinerary days",
    },
    { title: "Categories", desc: "The Categories for the Post" },
    {
      title: "Brief Of Destination",
      desc: "A short description of the Destination",
    },
    { title: "Upload Cover Image", desc: "The cover image for the Post" },
    { title: "Product Public End Date", desc: "Product Public End Date" },
    {
      title: "Publish On",
      desc: "When to automatically turn a draft into a published post",
    },
    { title: "Status", desc: "Whether the post is a draft or published" },
    { title: "Upload Map Image", desc: "Upload Map Image" },
  ],
  beddingConfiguarations: [
    { label: "Single", value: "Single" },
    { label: "Double", value: "Double" },
    { label: "Triple", value: "Triple" },
    { label: "Twin", value: "Twin" },
    { label: "Quadruple", value: "Quadruple" },
  ],
  defaultItineraryFields: [
    { title: "Day", desc: "Day" },
    { title: "Icon", desc: "Icon" },
    { title: "Date", desc: "Date" },
    { title: "Title", desc: "Title" },
    { title: "Body", desc: "Body" },
    { title: "Upload Image", desc: "Upload Image" },
  ],
  relatedTrip: [
    {
      title:
        "Highlights of Sydney, Adelaide,  Kangaroo Island and the Murray River- rail and cruise package",
      tripcode: "TRMC-9395",
    },
    {
      title:
        "Unforgettable Darwin, Adelaide and River Murray rail and cruise package",
      tripcode: "TREB-8785",
    },
    {
      title:
        "Journey of Discovery from Melbourne to Adelaide rail and cruise package",
      tripcode: "TRJT-1365",
    },
    {
      title: "Best of Australia's Southeast Rail and Cruise Package",
      tripcode: "RLAH-4054",
    },
    {
      title: "Luxury Small Group Tour India Golden Triangle With Bharatpur",
      tripcode: "OCT01ID",
    },
    {
      title:
        "ULTIMATE LUXURY FULLY ESCORTED GOLF CRUISE TOUR WITH HELI GOLF (FROM SIEM REAP TO HANOI)",
      tripcode: "17DGLFCRS",
    },
    {
      title:
        "Wonders of the Great Barrier Reef, Brisbane and South Australia- Rail & Cruise Package",
      tripcode: "PCKHL-3726",
    },
    {
      title: "12 DAY VIETNAM AND CAMBODIA MEKONG RIVER CRUISE | 2-For-1 Deal",
      tripcode: "LD2021T",
    },
  ],
  productDetailTypes: ["Luxury", "Premium", "Standard"],
  activeLevel: ["Leisurely", "Active", "Challenging"],
  guideType: ["Fully Guided", "Self Guided", "Partially Guided"],
  titles: [
    { label: "Mr", value: "Mr" },
    { label: "Mrs", value: "Mrs" },
    { label: "Ms", value: "Ms" },
    { label: "Mstr", value: "Mstr" },
    { label: "Miss", value: "Miss" },
    { label: "Dr", value: "Dr" },
    { label: "Prof", value: "Prof" },
    { label: "Sir", value: "Sir" },
    { label: "Lady", value: "Lady" },
    { label: "Other", value: "Other" },
    // { label: "Infant", value: "Infant" },
  ],
  titlesChild: [{ label: "Child", value: "Child" }],
  titlesInfant: [{ label: "Infant", value: "Infant" }],
  productTypes: [
    { label: "Tour", value: "tour" },
    { label: "Golf Package", value: "golf" },
  ],
  statusProductPost: [
    { label: "Draft", value: "DRAFT" },
    { label: "Publish", value: "PUBLISH" },
    // {label: "Expired", value: "EXPIRED"},
  ],
  pricingMethod: [
    { label: "NET", value: "NET" },
    { label: "GROSS", value: "GROSS" },
  ],
  icons: [
    { label: "Castle", value: "Castle" },
    { label: "Deck", value: "Deck" },
    { label: "AirplanemodeActive", value: "AirplanemodeActive" },
  ],
  contactTypes: [
    { label: "Employee", value: "Employee" },
    { label: "Travel Agent", value: "Travel Agent" },
    { label: "Business Partner", value: "Business Partner" },
    { label: "Distributor", value: "Distributor" },
    { label: "Tour Operator", value: "Tour Operator" },
    { label: "Corporate", value: "Corporate" },
    { label: "Supplier", value: "Supplier" },
    { label: "Passenger", value: "Passenger" },
    { label: "Emergency", value: "Emergency" },
    { label: "Client", value: "Client" },
    { label: "Other", value: "Other" },
  ],
  onlinePortalAccesses: [
    { label: "Passenger Portal", value: "Passenger Portal" },
    { label: "Agent Portal", value: "Agent Portal" },
    { label: "Supplier Portal", value: "Supplier Portal" },
  ],
  contactPhoneTypes: [
    { label: "Work Phone", value: "Work Phone" },
    { label: "Mobile", value: "Mobile" },
    { label: "Fax", value: "Fax" },
    { label: "SMS Marketing", value: "SMS Marketing" },
    { label: "Home Phone", value: "Home Phone" },
  ],
  emailTypes: [
    { label: "Work", value: "Work" },
    { label: "Newsletter", value: "Newsletter" },
    { label: "Personal", value: "Personal" },
    { label: "Other", value: "Other" },
  ],
  status: [
    { label: "Contacting", value: "contacting" },
    { label: "Demo Request", value: "demoRequest" },
    { label: "Free Trials", value: "freeTrials" },
    { label: "Waiting For Setup Demo", value: "waitingForSetupDemo" },
    {
      label: "Waiting For Setup Production",
      value: "waitingForSetupProduction",
    },
    { label: "Active", value: "active" },
    { label: "Maintenance", value: "maintenance" },
    { label: "Deactivated", value: "deactivated" },
  ],
  companyType: [
    // Travel Agent
    { label: "Online Travel Agent", value: "Online Travel Agent" },
    { label: "Retail Travel Agent", value: "Retail Travel Agent" },
    { label: "Travel Managers", value: "Travel Managers" },
    { label: "Mobile Travel Agent", value: "Mobile Travel Agent" },
    { label: "Corporate Travel Company", value: "Corporate Travel Company" },

    { label: "Tour Operator", value: "Tour Operator" },
    { label: "Wholesaler", value: "Wholesaler" },
    { label: "Cruise Line", value: "Cruise Line" },
    {
      label: "Destination Management Company",
      value: "Destination Management Company",
    },
    { label: "Inbound Tour Operator", value: "Inbound Tour Operator" },
    { label: "Outbound Tour Operator", value: "Outbound Tour Operator" },
    { label: "Accommodation", value: "Accommodation" },
    { label: "Airline", value: "Airline" },

    { label: "Channel Manager", value: "Channel Manager" },
    { label: "Distributor", value: "Distributor" },
    { label: "Franchisee", value: "Franchisee" },
    { label: "Competitor", value: "Competitor" },
    { label: "Partner", value: "Partner" },
    { label: "Supplier", value: "Supplier" },
    { label: "Client", value: "Client" },
    { label: "Other", value: "Other" },
  ],
  companiesCallMessType: [
    { label: "Reservation", value: "reservation" },
    { label: "General Enquiries", value: "generalEnquiries" },
    { label: "Sales & Marketing Department", value: "saleMarketingDepartment" },
    {
      label: "Account & Finance Department",
      value: "accountFinanceDepartment",
    },
    {
      label: "Product & Commercial Department",
      value: "productCommercialDepartment",
    },
    { label: "FIT Department", value: "FITDepartment" },
    { label: "Group Department", value: "groupDepartment" },
    { label: "Retail Department", value: "retailDepartment" },
    { label: "Wholesale Department", value: "wholesaleDepartment" },
    { label: "Cruise Department", value: "cruiseDepartment" },
    { label: "Tour Department", value: "tourDepartment" },
    { label: "MICE Department", value: "MICEDepartment" },
    { label: "Human Resource Department", value: "humanResourceDepartment" },
    { label: "Inbound Department", value: "inboundDepartment" },
    { label: "Outbound Department", value: "outboundDepartment" },
    { label: "Operations Department", value: "operationsDepartment" },
    { label: "Emergency Department", value: "emergencyDepartment" },
    {
      label: "IT and Support Department Other",
      value: "ITAndSupportDepartment Other",
    },
  ],
  departmentOptions: [
    "Sales & Marketing Department",
    "Account & Finance Department",
    "Product & Commercial Department",
    "FIT Department",
    "Group Department",
    "Retail Department",
    "Wholesale Department",
    "Cruise Department",
    "Tour Department",
    "MICE Department",
    "Human Resource Department",
    "Inbound Department",
    "Outbound Department",
    "Operations Department",
    "Emergency Department",
    "IT and Support Department Other",
  ],
  pgaOptions: ["N/A", "Yes", "No"],
  industries: [
    { label: "Travel", value: "Travel" },
    { label: "Information Technology", value: "Information Technology" },
    { label: "Banking Services", value: "Banking Services" },
    { label: "Finance", value: "Finance" },
    { label: "Consulting", value: "Consulting" },
    { label: "Government", value: "Government" },
    { label: "Delivery", value: "Delivery" },
    { label: "Entertainment", value: "Entertainment" },
    { label: "Non-Profit", value: "Non-Profit" },
    { label: "Hospitality", value: "Hospitality" },
    { label: "Transport", value: "Transport" },
    { label: "Other", value: "Other" },
  ],
  websiteTypes: [
    { label: "Corporate", value: "Corporate" },
    { label: "Personal", value: "Personal" },
    { label: "Social Network", value: "Social Network" },
    { label: "Other", value: "Other" },
  ],
  socialMediaTypes: [
    { label: "Facebook", value: "Facebook" },
    { label: "Twitter", value: "Twitter" },
    { label: "Skype", value: "Skype" },
    { label: "Instagram", value: "Instagram" },
    { label: "Other", value: "Other" },
  ],
  moreEmployees: [
    {
      label: "Small company: 1 - 9 employees",
      value: "Small company: 1 - 9 employees",
    },
    {
      label: "Small enterprise: 10 - 49 employees",
      value: "Small enterprise: 10 - 49 employees",
    },
    {
      label: "Medium enterprise: 50 - 249 employees",
      value: "Medium enterprise: 50 - 249 employees",
    },
    {
      label: "Large enterprise: 250 employees or more",
      value: "Large enterprise: 250 employees or more",
    },
  ],
  moreStatus: [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
    { label: "Unknown", value: "Unknown" },
  ],
  sourcesCompany: [
    { label: "Phone Call", value: "Phone Call" },
    { label: "Email", value: "Email" },
    { label: "Website", value: "Website" },
    { label: "Newsletter", value: "Newsletter" },
    { label: "Advertising", value: "Advertising" },
    { label: "Google Search Engine", value: "Google Search Engine" },
    { label: "Roadshow/Exhibition", value: "Roadshow/Exhibition" },
    { label: "Existing Customer", value: "Existing Customer" },
    { label: "By Recommendation", value: "By Recommendation" },
    { label: "CRM Form", value: "CRM Form" },
    { label: "Sales Boost Program", value: "Sales Boost Program" },
    { label: "Online Sites", value: "Online Sites" },
    {
      label: "Supplier Register Tweet World Travel Website",
      value: "Supplier Register Tweet World Travel Website",
    },
    { label: "Other", value: "Other" },
  ],
  contactOf: [
    { label: "Tweet World Travel B2B", value: "Tweet World Travel B2B" },
    { label: "Tweet World Vacation", value: "Tweet World Vacation" },
    { label: "TKG Platform", value: "TKG Platform" },
    { label: "Undefined", value: "Undefined" },
  ],
  currency: [
    { label: "USD", value: "USD" },
    { label: "AUD", value: "AUD" },
    { label: "EUR", value: "EUR" },
    { label: "NZD", value: "NZD" },
    { label: "GBP", value: "GBP" },
    { label: "CAD", value: "CAD" },
  ],
  specialities: [
    { group: "ACCOMMODATION", value: "Hotels" },
    { group: "ACCOMMODATION", value: "Resorts" },
    { group: "ACCOMMODATION", value: "Apartment" },
    { group: "ACCOMMODATION", value: "Villa" },
    { group: "ACCOMMODATION", value: "Hostels" },
    { group: "ACCOMMODATION", value: "Guest House" },
    { group: "ACCOMMODATION", value: "Cottage" },
    { group: "ACCOMMODATION", value: "Lodge" },
    { group: "ACCOMMODATION", value: "Motel" },
    { group: "ACCOMMODATION", value: "Campsite" },
    { group: "TRANSPORTATION", value: "Air" },
    { group: "TRANSPORTATION", value: "Train" },
    { group: "TRANSPORTATION", value: "High-speed rail" },
    { group: "TRANSPORTATION", value: "SIC Transfers" },
    { group: "TRANSPORTATION", value: "Bus/Van/Car" },
    { group: "TRANSPORTATION", value: "Boat/Ferry" },
    { group: "TRANSPORTATION", value: "Airport Transfer" },
    { group: "TRANSPORTATION", value: "Private Transfers" },
    { group: "TRANSPORTATION", value: "Sea Plane" },
    { group: "TRANSPORTATION", value: "Helicopter " },
    { group: "RESTAURANT", value: "Fine Dining" },
    { group: "RESTAURANT", value: "Restaurant" },
    { group: "RESTAURANT", value: "Diner" },
    { group: "TOURING/ACTIVITY", value: "SIC" },
    { group: "TOURING/ACTIVITY", value: "GROUP" },
    { group: "TOURING/ACTIVITY", value: "GUIDE" },
    { group: "TOURING/ACTIVITY", value: "TAILOR -MADE" },
    { group: "TOURING/ACTIVITY", value: "MICE" },
    { group: "TOURING/ACTIVITY", value: "Hiking & biking" },
    { group: "TOURING/ACTIVITY", value: "Golf" },
    { group: "TOURING/ACTIVITY", value: "Wellness" },
    { group: "TOURING/ACTIVITY", value: "Educational" },
    { group: "TOURING/ACTIVITY", value: "Wildlife & Safari " },
    { group: "TOURING/ACTIVITY", value: "Shore Excursion" },
  ],
  sources: [
    { label: "Phone Call", value: "Phone Call" },
    { label: "Email", value: "Email" },
    { label: "Website", value: "Website" },
    { label: "Newsletter", value: "Newsletter" },
    { label: "Advertising", value: "Advertising" },
    { label: "Google Search Engine", value: "Google Search Engine" },
    { label: "Roadshow/Exhibition", value: "Roadshow/Exhibition" },
    { label: "Existing Customer", value: "Existing Customer" },
    { label: "By Recommendation", value: "By Recommendation" },
    { label: "Reservation", value: "Reservation" },
    { label: "Sales Boost Program", value: "Sales Boost Program" },
    { label: "Online Sites", value: "Online Sites" },
    { label: "Other", value: "Other" },
  ],
  finacialYears: [
    {
      label: new Date().getFullYear() - 2,
      value: new Date().getFullYear() - 2,
    },
    {
      label: new Date().getFullYear() - 1,
      value: new Date().getFullYear() - 1,
    },
    { label: new Date().getFullYear(), value: new Date().getFullYear() },
    {
      label: new Date().getFullYear() + 1,
      value: new Date().getFullYear() + 1,
    },
    {
      label: new Date().getFullYear() + 2,
      value: new Date().getFullYear() + 2,
    },
    {
      label: new Date().getFullYear() + 3,
      value: new Date().getFullYear() + 3,
    },
  ],
  genders: [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Other", value: "other" },
  ],
  companies: [
    { name: "", id: "" },
    { name: "Company 1", id: "1" },
    { name: "Company 2", id: "2" },
    { name: "Company 3", id: "3" },
  ],

  termTypes: [
    { value: "general", label: "General" },
    { value: "tour", label: "Tour" },
    { value: "golf", label: "Golf Package" },
    { value: "cruise", label: "Cruise" },
    { value: "accommodation", label: "Accommodation" },
    { value: "passengerOnlineForm", label: "Passenger Online Form" },
    { value: "onlinePortal", label: "Online Portal" },
    { value: "twoForOneDeal", label: "2 for 1 Deals" },
  ],
  currencyExchange: [
    {
      symbol: "$",
      name: "US Dollar",
      // "symbol_native": "$",
      decimal_digits: 2,
      rounding: 0,
      code: "USD",
      // "name_plural": "US dollars"
    },
    {
      symbol: "CA$",
      name: "Canadian Dollar",
      // "symbol_native": "$",
      decimal_digits: 2,
      rounding: 0,
      code: "CAD",
      // "name_plural": "Canadian dollars"
    },
    {
      symbol: "€",
      name: "Euro",
      // "symbol_native": "€",
      decimal_digits: 2,
      rounding: 0,
      code: "EUR",
      // "name_plural": "euros"
    },
    {
      symbol: "AED",
      name: "United Arab Emirates Dirham",
      // "symbol_native": "د.إ.‏",
      decimal_digits: 2,
      rounding: 0,
      code: "AED",
      // "name_plural": "UAE dirhams"
    },
    {
      symbol: "Af",
      name: "Afghan Afghani",
      // "symbol_native": "؋",
      decimal_digits: 0,
      rounding: 0,
      code: "AFN",
      // "name_plural": "Afghan Afghanis"
    },
    {
      symbol: "ALL",
      name: "Albanian Lek",
      // "symbol_native": "Lek",
      decimal_digits: 0,
      rounding: 0,
      code: "ALL",
      // "name_plural": "Albanian lekë"
    },
    {
      symbol: "AMD",
      name: "Armenian Dram",
      // "symbol_native": "դր.",
      decimal_digits: 0,
      rounding: 0,
      code: "AMD",
      // "name_plural": "Armenian drams"
    },
    {
      symbol: "AR$",
      name: "Argentine Peso",
      // "symbol_native": "$",
      decimal_digits: 2,
      rounding: 0,
      code: "ARS",
      // "name_plural": "Argentine pesos"
    },
    {
      symbol: "AU$",
      name: "Australian Dollar",
      // "symbol_native": "$",
      decimal_digits: 2,
      rounding: 0,
      code: "AUD",
      // "name_plural": "Australian dollars"
    },
    {
      symbol: "man.",
      name: "Azerbaijani Manat",
      // "symbol_native": "ман.",
      decimal_digits: 2,
      rounding: 0,
      code: "AZN",
      // "name_plural": "Azerbaijani manats"
    },
    {
      symbol: "KM",
      name: "Bosnia-Herzegovina Convertible Mark",
      // "symbol_native": "KM",
      decimal_digits: 2,
      rounding: 0,
      code: "BAM",
      // "name_plural": "Bosnia-Herzegovina convertible marks"
    },
    {
      symbol: "Tk",
      name: "Bangladeshi Taka",
      // "symbol_native": "৳",
      decimal_digits: 2,
      rounding: 0,
      code: "BDT",
      // "name_plural": "Bangladeshi takas"
    },
    {
      symbol: "BGN",
      name: "Bulgarian Lev",
      // "symbol_native": "лв.",
      decimal_digits: 2,
      rounding: 0,
      code: "BGN",
      // "name_plural": "Bulgarian leva"
    },
    {
      symbol: "BD",
      name: "Bahraini Dinar",
      // "symbol_native": "د.ب.‏",
      decimal_digits: 3,
      rounding: 0,
      code: "BHD",
      // "name_plural": "Bahraini dinars"
    },
    {
      symbol: "FBu",
      name: "Burundian Franc",
      // "symbol_native": "FBu",
      decimal_digits: 0,
      rounding: 0,
      code: "BIF",
      // "name_plural": "Burundian francs"
    },
    {
      symbol: "BN$",
      name: "Brunei Dollar",
      // "symbol_native": "$",
      decimal_digits: 2,
      rounding: 0,
      code: "BND",
      // "name_plural": "Brunei dollars"
    },
    {
      symbol: "Bs",
      name: "Bolivian Boliviano",
      // "symbol_native": "Bs",
      decimal_digits: 2,
      rounding: 0,
      code: "BOB",
      // "name_plural": "Bolivian bolivianos"
    },
    {
      symbol: "R$",
      name: "Brazilian Real",
      // "symbol_native": "R$",
      decimal_digits: 2,
      rounding: 0,
      code: "BRL",
      // "name_plural": "Brazilian reals"
    },
    {
      symbol: "BWP",
      name: "Botswanan Pula",
      // "symbol_native": "P",
      decimal_digits: 2,
      rounding: 0,
      code: "BWP",
      // "name_plural": "Botswanan pulas"
    },
    {
      symbol: "Br",
      name: "Belarusian Ruble",
      // "symbol_native": "руб.",
      decimal_digits: 2,
      rounding: 0,
      code: "BYN",
      // "name_plural": "Belarusian rubles"
    },
    {
      symbol: "BZ$",
      name: "Belize Dollar",
      // "symbol_native": "$",
      decimal_digits: 2,
      rounding: 0,
      code: "BZD",
      // "name_plural": "Belize dollars"
    },
    {
      symbol: "CDF",
      name: "Congolese Franc",
      // "symbol_native": "FrCD",
      decimal_digits: 2,
      rounding: 0,
      code: "CDF",
      // "name_plural": "Congolese francs"
    },
    {
      symbol: "CHF",
      name: "Swiss Franc",
      // "symbol_native": "CHF",
      decimal_digits: 2,
      rounding: 0.05,
      code: "CHF",
      // "name_plural": "Swiss francs"
    },
    {
      symbol: "CL$",
      name: "Chilean Peso",
      // "symbol_native": "$",
      decimal_digits: 0,
      rounding: 0,
      code: "CLP",
      // "name_plural": "Chilean pesos"
    },
    {
      symbol: "CN¥",
      name: "Chinese Yuan",
      // "symbol_native": "CN¥",
      decimal_digits: 2,
      rounding: 0,
      code: "CNY",
      // "name_plural": "Chinese yuan"
    },
    {
      symbol: "CO$",
      name: "Colombian Peso",
      // "symbol_native": "$",
      decimal_digits: 0,
      rounding: 0,
      code: "COP",
      // "name_plural": "Colombian pesos"
    },
    {
      symbol: "₡",
      name: "Costa Rican Colón",
      // "symbol_native": "₡",
      decimal_digits: 0,
      rounding: 0,
      code: "CRC",
      // "name_plural": "Costa Rican colóns"
    },
    {
      symbol: "CV$",
      name: "Cape Verdean Escudo",
      // "symbol_native": "CV$",
      decimal_digits: 2,
      rounding: 0,
      code: "CVE",
      // "name_plural": "Cape Verdean escudos"
    },
    {
      symbol: "Kč",
      name: "Czech Republic Koruna",
      // "symbol_native": "Kč",
      decimal_digits: 2,
      rounding: 0,
      code: "CZK",
      // "name_plural": "Czech Republic korunas"
    },
    {
      symbol: "Fdj",
      name: "Djiboutian Franc",
      // "symbol_native": "Fdj",
      decimal_digits: 0,
      rounding: 0,
      code: "DJF",
      // "name_plural": "Djiboutian francs"
    },
    {
      symbol: "Dkr",
      name: "Danish Krone",
      // "symbol_native": "kr",
      decimal_digits: 2,
      rounding: 0,
      code: "DKK",
      // "name_plural": "Danish kroner"
    },
    {
      symbol: "RD$",
      name: "Dominican Peso",
      // "symbol_native": "RD$",
      decimal_digits: 2,
      rounding: 0,
      code: "DOP",
      // "name_plural": "Dominican pesos"
    },
    {
      symbol: "DA",
      name: "Algerian Dinar",
      // "symbol_native": "د.ج.‏",
      decimal_digits: 2,
      rounding: 0,
      code: "DZD",
      // "name_plural": "Algerian dinars"
    },
    {
      symbol: "Ekr",
      name: "Estonian Kroon",
      // "symbol_native": "kr",
      decimal_digits: 2,
      rounding: 0,
      code: "EEK",
      // "name_plural": "Estonian kroons"
    },
    {
      symbol: "EGP",
      name: "Egyptian Pound",
      // "symbol_native": "ج.م.‏",
      decimal_digits: 2,
      rounding: 0,
      code: "EGP",
      // "name_plural": "Egyptian pounds"
    },
    {
      symbol: "Nfk",
      name: "Eritrean Nakfa",
      // "symbol_native": "Nfk",
      decimal_digits: 2,
      rounding: 0,
      code: "ERN",
      // "name_plural": "Eritrean nakfas"
    },
    {
      symbol: "Br",
      name: "Ethiopian Birr",
      // "symbol_native": "Br",
      decimal_digits: 2,
      rounding: 0,
      code: "ETB",
      // "name_plural": "Ethiopian birrs"
    },
    {
      symbol: "£",
      name: "British Pound Sterling",
      // "symbol_native": "£",
      decimal_digits: 2,
      rounding: 0,
      code: "GBP",
      // "name_plural": "British pounds sterling"
    },
    {
      symbol: "GEL",
      name: "Georgian Lari",
      // "symbol_native": "GEL",
      decimal_digits: 2,
      rounding: 0,
      code: "GEL",
      // "name_plural": "Georgian laris"
    },
    {
      symbol: "GH₵",
      name: "Ghanaian Cedi",
      // "symbol_native": "GH₵",
      decimal_digits: 2,
      rounding: 0,
      code: "GHS",
      // "name_plural": "Ghanaian cedis"
    },
    {
      symbol: "FG",
      name: "Guinean Franc",
      // "symbol_native": "FG",
      decimal_digits: 0,
      rounding: 0,
      code: "GNF",
      // "name_plural": "Guinean francs"
    },
    {
      symbol: "GTQ",
      name: "Guatemalan Quetzal",
      // "symbol_native": "Q",
      decimal_digits: 2,
      rounding: 0,
      code: "GTQ",
      // "name_plural": "Guatemalan quetzals"
    },
    {
      symbol: "HK$",
      name: "Hong Kong Dollar",
      // "symbol_native": "$",
      decimal_digits: 2,
      rounding: 0,
      code: "HKD",
      // "name_plural": "Hong Kong dollars"
    },
    {
      symbol: "HNL",
      name: "Honduran Lempira",
      // "symbol_native": "L",
      decimal_digits: 2,
      rounding: 0,
      code: "HNL",
      // "name_plural": "Honduran lempiras"
    },
    {
      symbol: "kn",
      name: "Croatian Kuna",
      // "symbol_native": "kn",
      decimal_digits: 2,
      rounding: 0,
      code: "HRK",
      // "name_plural": "Croatian kunas"
    },
    {
      symbol: "Ft",
      name: "Hungarian Forint",
      // "symbol_native": "Ft",
      decimal_digits: 0,
      rounding: 0,
      code: "HUF",
      // "name_plural": "Hungarian forints"
    },
    {
      symbol: "Rp",
      name: "Indonesian Rupiah",
      // "symbol_native": "Rp",
      decimal_digits: 0,
      rounding: 0,
      code: "IDR",
      // "name_plural": "Indonesian rupiahs"
    },
    {
      symbol: "₪",
      name: "Israeli New Sheqel",
      // "symbol_native": "₪",
      decimal_digits: 2,
      rounding: 0,
      code: "ILS",
      // "name_plural": "Israeli new sheqels"
    },
    {
      symbol: "Rs",
      name: "Indian Rupee",
      // "symbol_native": "টকা",
      decimal_digits: 2,
      rounding: 0,
      code: "INR",
      // "name_plural": "Indian rupees"
    },
    {
      symbol: "IQD",
      name: "Iraqi Dinar",
      // "symbol_native": "د.ع.‏",
      decimal_digits: 0,
      rounding: 0,
      code: "IQD",
      // "name_plural": "Iraqi dinars"
    },
    {
      symbol: "IRR",
      name: "Iranian Rial",
      // "symbol_native": "﷼",
      decimal_digits: 0,
      rounding: 0,
      code: "IRR",
      // "name_plural": "Iranian rials"
    },
    {
      symbol: "Ikr",
      name: "Icelandic Króna",
      // "symbol_native": "kr",
      decimal_digits: 0,
      rounding: 0,
      code: "ISK",
      // "name_plural": "Icelandic krónur"
    },
    {
      symbol: "J$",
      name: "Jamaican Dollar",
      // "symbol_native": "$",
      decimal_digits: 2,
      rounding: 0,
      code: "JMD",
      // "name_plural": "Jamaican dollars"
    },
    {
      symbol: "JD",
      name: "Jordanian Dinar",
      // "symbol_native": "د.أ.‏",
      decimal_digits: 3,
      rounding: 0,
      code: "JOD",
      // "name_plural": "Jordanian dinars"
    },
    {
      symbol: "¥",
      name: "Japanese Yen",
      // "symbol_native": "￥",
      decimal_digits: 0,
      rounding: 0,
      code: "JPY",
      // "name_plural": "Japanese yen"
    },
    {
      symbol: "Ksh",
      name: "Kenyan Shilling",
      // "symbol_native": "Ksh",
      decimal_digits: 2,
      rounding: 0,
      code: "KES",
      // "name_plural": "Kenyan shillings"
    },
    {
      symbol: "KHR",
      name: "Cambodian Riel",
      // "symbol_native": "៛",
      decimal_digits: 2,
      rounding: 0,
      code: "KHR",
      // "name_plural": "Cambodian riels"
    },
    {
      symbol: "CF",
      name: "Comorian Franc",
      // "symbol_native": "FC",
      decimal_digits: 0,
      rounding: 0,
      code: "KMF",
      // "name_plural": "Comorian francs"
    },
    {
      symbol: "₩",
      name: "South Korean Won",
      // "symbol_native": "₩",
      decimal_digits: 0,
      rounding: 0,
      code: "KRW",
      // "name_plural": "South Korean won"
    },
    {
      symbol: "KD",
      name: "Kuwaiti Dinar",
      // "symbol_native": "د.ك.‏",
      decimal_digits: 3,
      rounding: 0,
      code: "KWD",
      // "name_plural": "Kuwaiti dinars"
    },
    {
      symbol: "KZT",
      name: "Kazakhstani Tenge",
      // "symbol_native": "тңг.",
      decimal_digits: 2,
      rounding: 0,
      code: "KZT",
      // "name_plural": "Kazakhstani tenges"
    },
    {
      symbol: "L.L.",
      name: "Lebanese Pound",
      // "symbol_native": "ل.ل.‏",
      decimal_digits: 0,
      rounding: 0,
      code: "LBP",
      // "name_plural": "Lebanese pounds"
    },
    {
      symbol: "SLRs",
      name: "Sri Lankan Rupee",
      // "symbol_native": "SL Re",
      decimal_digits: 2,
      rounding: 0,
      code: "LKR",
      // "name_plural": "Sri Lankan rupees"
    },
    {
      symbol: "Lt",
      name: "Lithuanian Litas",
      // "symbol_native": "Lt",
      decimal_digits: 2,
      rounding: 0,
      code: "LTL",
      // "name_plural": "Lithuanian litai"
    },
    {
      symbol: "Ls",
      name: "Latvian Lats",
      // "symbol_native": "Ls",
      decimal_digits: 2,
      rounding: 0,
      code: "LVL",
      // "name_plural": "Latvian lati"
    },
    {
      symbol: "LD",
      name: "Libyan Dinar",
      // "symbol_native": "د.ل.‏",
      decimal_digits: 3,
      rounding: 0,
      code: "LYD",
      // "name_plural": "Libyan dinars"
    },
    {
      symbol: "MAD",
      name: "Moroccan Dirham",
      // "symbol_native": "د.م.‏",
      decimal_digits: 2,
      rounding: 0,
      code: "MAD",
      // "name_plural": "Moroccan dirhams"
    },
    {
      symbol: "MDL",
      name: "Moldovan Leu",
      // "symbol_native": "MDL",
      decimal_digits: 2,
      rounding: 0,
      code: "MDL",
      // "name_plural": "Moldovan lei"
    },
    {
      symbol: "MGA",
      name: "Malagasy Ariary",
      // "symbol_native": "MGA",
      decimal_digits: 0,
      rounding: 0,
      code: "MGA",
      // "name_plural": "Malagasy Ariaries"
    },
    {
      symbol: "MKD",
      name: "Macedonian Denar",
      // "symbol_native": "MKD",
      decimal_digits: 2,
      rounding: 0,
      code: "MKD",
      // "name_plural": "Macedonian denari"
    },
    {
      symbol: "MMK",
      name: "Myanma Kyat",
      // "symbol_native": "K",
      decimal_digits: 0,
      rounding: 0,
      code: "MMK",
      // "name_plural": "Myanma kyats"
    },
    {
      symbol: "MOP$",
      name: "Macanese Pataca",
      // "symbol_native": "MOP$",
      decimal_digits: 2,
      rounding: 0,
      code: "MOP",
      // "name_plural": "Macanese patacas"
    },
    {
      symbol: "MURs",
      name: "Mauritian Rupee",
      // "symbol_native": "MURs",
      decimal_digits: 0,
      rounding: 0,
      code: "MUR",
      // "name_plural": "Mauritian rupees"
    },
    {
      symbol: "MX$",
      name: "Mexican Peso",
      // "symbol_native": "$",
      decimal_digits: 2,
      rounding: 0,
      code: "MXN",
      // "name_plural": "Mexican pesos"
    },
    {
      symbol: "RM",
      name: "Malaysian Ringgit",
      // "symbol_native": "RM",
      decimal_digits: 2,
      rounding: 0,
      code: "MYR",
      // "name_plural": "Malaysian ringgits"
    },
    {
      symbol: "MTn",
      name: "Mozambican Metical",
      // "symbol_native": "MTn",
      decimal_digits: 2,
      rounding: 0,
      code: "MZN",
      // "name_plural": "Mozambican meticals"
    },
    {
      symbol: "N$",
      name: "Namibian Dollar",
      // "symbol_native": "N$",
      decimal_digits: 2,
      rounding: 0,
      code: "NAD",
      // "name_plural": "Namibian dollars"
    },
    {
      symbol: "₦",
      name: "Nigerian Naira",
      // "symbol_native": "₦",
      decimal_digits: 2,
      rounding: 0,
      code: "NGN",
      // "name_plural": "Nigerian nairas"
    },
    {
      symbol: "C$",
      name: "Nicaraguan Córdoba",
      // "symbol_native": "C$",
      decimal_digits: 2,
      rounding: 0,
      code: "NIO",
      // "name_plural": "Nicaraguan córdobas"
    },
    {
      symbol: "Nkr",
      name: "Norwegian Krone",
      // "symbol_native": "kr",
      decimal_digits: 2,
      rounding: 0,
      code: "NOK",
      // "name_plural": "Norwegian kroner"
    },
    {
      symbol: "NPRs",
      name: "Nepalese Rupee",
      // "symbol_native": "नेरू",
      decimal_digits: 2,
      rounding: 0,
      code: "NPR",
      // "name_plural": "Nepalese rupees"
    },
    {
      symbol: "NZ$",
      name: "New Zealand Dollar",
      // "symbol_native": "$",
      decimal_digits: 2,
      rounding: 0,
      code: "NZD",
      // "name_plural": "New Zealand dollars"
    },
    {
      symbol: "OMR",
      name: "Omani Rial",
      // "symbol_native": "ر.ع.‏",
      decimal_digits: 3,
      rounding: 0,
      code: "OMR",
      // "name_plural": "Omani rials"
    },
    {
      symbol: "B/.",
      name: "Panamanian Balboa",
      // "symbol_native": "B/.",
      decimal_digits: 2,
      rounding: 0,
      code: "PAB",
      // "name_plural": "Panamanian balboas"
    },
    {
      symbol: "S/.",
      name: "Peruvian Nuevo Sol",
      // "symbol_native": "S/.",
      decimal_digits: 2,
      rounding: 0,
      code: "PEN",
      // "name_plural": "Peruvian nuevos soles"
    },
    {
      symbol: "₱",
      name: "Philippine Peso",
      // "symbol_native": "₱",
      decimal_digits: 2,
      rounding: 0,
      code: "PHP",
      // "name_plural": "Philippine pesos"
    },
    {
      symbol: "PKRs",
      name: "Pakistani Rupee",
      // "symbol_native": "₨",
      decimal_digits: 0,
      rounding: 0,
      code: "PKR",
      // "name_plural": "Pakistani rupees"
    },
    {
      symbol: "zł",
      name: "Polish Zloty",
      // "symbol_native": "zł",
      decimal_digits: 2,
      rounding: 0,
      code: "PLN",
      // "name_plural": "Polish zlotys"
    },
    {
      symbol: "₲",
      name: "Paraguayan Guarani",
      // "symbol_native": "₲",
      decimal_digits: 0,
      rounding: 0,
      code: "PYG",
      // "name_plural": "Paraguayan guaranis"
    },
    {
      symbol: "QR",
      name: "Qatari Rial",
      // "symbol_native": "ر.ق.‏",
      decimal_digits: 2,
      rounding: 0,
      code: "QAR",
      // "name_plural": "Qatari rials"
    },
    {
      symbol: "RON",
      name: "Romanian Leu",
      // "symbol_native": "RON",
      decimal_digits: 2,
      rounding: 0,
      code: "RON",
      // "name_plural": "Romanian lei"
    },
    {
      symbol: "din.",
      name: "Serbian Dinar",
      // "symbol_native": "дин.",
      decimal_digits: 0,
      rounding: 0,
      code: "RSD",
      // "name_plural": "Serbian dinars"
    },
    {
      symbol: "RUB",
      name: "Russian Ruble",
      // "symbol_native": "₽.",
      decimal_digits: 2,
      rounding: 0,
      code: "RUB",
      // "name_plural": "Russian rubles"
    },
    {
      symbol: "RWF",
      name: "Rwandan Franc",
      // "symbol_native": "FR",
      decimal_digits: 0,
      rounding: 0,
      code: "RWF",
      // "name_plural": "Rwandan francs"
    },
    {
      symbol: "SR",
      name: "Saudi Riyal",
      // "symbol_native": "ر.س.‏",
      decimal_digits: 2,
      rounding: 0,
      code: "SAR",
      // "name_plural": "Saudi riyals"
    },
    {
      symbol: "SDG",
      name: "Sudanese Pound",
      // "symbol_native": "SDG",
      decimal_digits: 2,
      rounding: 0,
      code: "SDG",
      // "name_plural": "Sudanese pounds"
    },
    {
      symbol: "Skr",
      name: "Swedish Krona",
      // "symbol_native": "kr",
      decimal_digits: 2,
      rounding: 0,
      code: "SEK",
      // "name_plural": "Swedish kronor"
    },
    {
      symbol: "S$",
      name: "Singapore Dollar",
      // "symbol_native": "$",
      decimal_digits: 2,
      rounding: 0,
      code: "SGD",
      // "name_plural": "Singapore dollars"
    },
    {
      symbol: "Ssh",
      name: "Somali Shilling",
      // "symbol_native": "Ssh",
      decimal_digits: 0,
      rounding: 0,
      code: "SOS",
      // "name_plural": "Somali shillings"
    },
    {
      symbol: "SY£",
      name: "Syrian Pound",
      // "symbol_native": "ل.س.‏",
      decimal_digits: 0,
      rounding: 0,
      code: "SYP",
      // "name_plural": "Syrian pounds"
    },
    {
      symbol: "฿",
      name: "Thai Baht",
      // "symbol_native": "฿",
      decimal_digits: 2,
      rounding: 0,
      code: "THB",
      // "name_plural": "Thai baht"
    },
    {
      symbol: "DT",
      name: "Tunisian Dinar",
      // "symbol_native": "د.ت.‏",
      decimal_digits: 3,
      rounding: 0,
      code: "TND",
      // "name_plural": "Tunisian dinars"
    },
    {
      symbol: "T$",
      name: "Tongan Paʻanga",
      // "symbol_native": "T$",
      decimal_digits: 2,
      rounding: 0,
      code: "TOP",
      // "name_plural": "Tongan paʻanga"
    },
    {
      symbol: "TL",
      name: "Turkish Lira",
      // "symbol_native": "TL",
      decimal_digits: 2,
      rounding: 0,
      code: "TRY",
      // "name_plural": "Turkish Lira"
    },
    {
      symbol: "TT$",
      name: "Trinidad and Tobago Dollar",
      // "symbol_native": "$",
      decimal_digits: 2,
      rounding: 0,
      code: "TTD",
      // "name_plural": "Trinidad and Tobago dollars"
    },
    {
      symbol: "NT$",
      name: "New Taiwan Dollar",
      // "symbol_native": "NT$",
      decimal_digits: 2,
      rounding: 0,
      code: "TWD",
      // "name_plural": "New Taiwan dollars"
    },
    {
      symbol: "TSh",
      name: "Tanzanian Shilling",
      // "symbol_native": "TSh",
      decimal_digits: 0,
      rounding: 0,
      code: "TZS",
      // "name_plural": "Tanzanian shillings"
    },
    {
      symbol: "₴",
      name: "Ukrainian Hryvnia",
      // "symbol_native": "₴",
      decimal_digits: 2,
      rounding: 0,
      code: "UAH",
      // "name_plural": "Ukrainian hryvnias"
    },
    {
      symbol: "USh",
      name: "Ugandan Shilling",
      // "symbol_native": "USh",
      decimal_digits: 0,
      rounding: 0,
      code: "UGX",
      // "name_plural": "Ugandan shillings"
    },
    {
      symbol: "$U",
      name: "Uruguayan Peso",
      // "symbol_native": "$",
      decimal_digits: 2,
      rounding: 0,
      code: "UYU",
      // "name_plural": "Uruguayan pesos"
    },
    {
      symbol: "UZS",
      name: "Uzbekistan Som",
      // "symbol_native": "UZS",
      decimal_digits: 0,
      rounding: 0,
      code: "UZS",
      // "name_plural": "Uzbekistan som"
    },
    {
      symbol: "Bs.F.",
      name: "Venezuelan Bolívar",
      // "symbol_native": "Bs.F.",
      decimal_digits: 2,
      rounding: 0,
      code: "VEF",
      // "name_plural": "Venezuelan bolívars"
    },
    {
      symbol: "₫",
      name: "Vietnamese Dong",
      // "symbol_native": "₫",
      decimal_digits: 0,
      rounding: 0,
      code: "VND",
      // "name_plural": "Vietnamese dong"
    },
    {
      symbol: "FCFA",
      name: "CFA Franc BEAC",
      // "symbol_native": "FCFA",
      decimal_digits: 0,
      rounding: 0,
      code: "XAF",
      // "name_plural": "CFA francs BEAC"
    },
    {
      symbol: "CFA",
      name: "CFA Franc BCEAO",
      // "symbol_native": "CFA",
      decimal_digits: 0,
      rounding: 0,
      code: "XOF",
      // "name_plural": "CFA francs BCEAO"
    },
    {
      symbol: "YR",
      name: "Yemeni Rial",
      // "symbol_native": "ر.ي.‏",
      decimal_digits: 0,
      rounding: 0,
      code: "YER",
      // "name_plural": "Yemeni rials"
    },
    {
      symbol: "R",
      name: "South African Rand",
      // "symbol_native": "R",
      decimal_digits: 2,
      rounding: 0,
      code: "ZAR",
      // "name_plural": "South African rand"
    },
    {
      symbol: "ZK",
      name: "Zambian Kwacha",
      // "symbol_native": "ZK",
      decimal_digits: 0,
      rounding: 0,
      code: "ZMK",
      // "name_plural": "Zambian kwachas"
    },
    {
      symbol: "ZWL$",
      name: "Zimbabwean Dollar",
      // "symbol_native": "ZWL$",
      decimal_digits: 0,
      rounding: 0,
      code: "ZWL",
      // "name_plural": "Zimbabwean Dollar"
    },
  ],
  country: [
    {
      label: "Afghanistan",
      value: "+93",
      code: "AF",
    },
    {
      label: "Aland Islands",
      value: "+358",
      code: "AX",
    },
    {
      label: "Albania",
      value: "+355",
      code: "AL",
    },
    {
      label: "Algeria",
      value: "+213",
      code: "DZ",
    },
    {
      label: "AmericanSamoa",
      value: "+1684",
      code: "AS",
    },
    {
      label: "Andorra",
      value: "+376",
      code: "AD",
    },
    {
      label: "Angola",
      value: "+244",
      code: "AO",
    },
    {
      label: "Anguilla",
      value: "+1264",
      code: "AI",
    },
    {
      label: "Antarctica",
      value: "+672",
      code: "AQ",
    },
    {
      label: "Antigua and Barbuda",
      value: "+1268",
      code: "AG",
    },
    {
      label: "Argentina",
      value: "+54",
      code: "AR",
    },
    {
      label: "Armenia",
      value: "+374",
      code: "AM",
    },
    {
      label: "Aruba",
      value: "+297",
      code: "AW",
    },
    {
      label: "Australia",
      value: "+61",
      code: "AU",
    },
    {
      label: "Austria",
      value: "+43",
      code: "AT",
    },
    {
      label: "Azerbaijan",
      value: "+994",
      code: "AZ",
    },
    {
      label: "Bahamas",
      value: "+1242",
      code: "BS",
    },
    {
      label: "Bahrain",
      value: "+973",
      code: "BH",
    },
    {
      label: "Bangladesh",
      value: "+880",
      code: "BD",
    },
    {
      label: "Barbados",
      value: "+1246",
      code: "BB",
    },
    {
      label: "Belarus",
      value: "+375",
      code: "BY",
    },
    {
      label: "Belgium",
      value: "+32",
      code: "BE",
    },
    {
      label: "Belize",
      value: "+501",
      code: "BZ",
    },
    {
      label: "Benin",
      value: "+229",
      code: "BJ",
    },
    {
      label: "Bermuda",
      value: "+1441",
      code: "BM",
    },
    {
      label: "Bhutan",
      value: "+975",
      code: "BT",
    },
    {
      label: "Bolivia, Plurinational State of",
      value: "+591",
      code: "BO",
    },
    {
      label: "Bosnia and Herzegovina",
      value: "+387",
      code: "BA",
    },
    {
      label: "Botswana",
      value: "+267",
      code: "BW",
    },
    {
      label: "Brazil",
      value: "+55",
      code: "BR",
    },
    {
      label: "British Indian Ocean Territory",
      value: "+246",
      code: "IO",
    },
    {
      label: "Brunei Darussalam",
      value: "+673",
      code: "BN",
    },
    {
      label: "Bulgaria",
      value: "+359",
      code: "BG",
    },
    {
      label: "Burkina Faso",
      value: "+226",
      code: "BF",
    },
    {
      label: "Burundi",
      value: "+257",
      code: "BI",
    },
    {
      label: "Cambodia",
      value: "+855",
      code: "KH",
    },
    {
      label: "Cameroon",
      value: "+237",
      code: "CM",
    },
    {
      label: "Canada",
      value: "+1",
      code: "CA",
    },
    {
      label: "Cape Verde",
      value: "+238",
      code: "CV",
    },
    {
      label: "Cayman Islands",
      value: "+ 345",
      code: "KY",
    },
    {
      label: "Central African Republic",
      value: "+236",
      code: "CF",
    },
    {
      label: "Chad",
      value: "+235",
      code: "TD",
    },
    {
      label: "Chile",
      value: "+56",
      code: "CL",
    },
    {
      label: "China",
      value: "+86",
      code: "CN",
    },
    {
      label: "Christmas Island",
      value: "+61",
      code: "CX",
    },
    {
      label: "Cocos (Keeling) Islands",
      value: "+61",
      code: "CC",
    },
    {
      label: "Colombia",
      value: "+57",
      code: "CO",
    },
    {
      label: "Comoros",
      value: "+269",
      code: "KM",
    },
    {
      label: "Congo",
      value: "+242",
      code: "CG",
    },
    {
      label: "Congo, The Democratic Republic of the Congo",
      value: "+243",
      code: "CD",
    },
    {
      label: "Cook Islands",
      value: "+682",
      code: "CK",
    },
    {
      label: "Costa Rica",
      value: "+506",
      code: "CR",
    },
    {
      label: "Cote d'Ivoire",
      value: "+225",
      code: "CI",
    },
    {
      label: "Croatia",
      value: "+385",
      code: "HR",
    },
    {
      label: "Cuba",
      value: "+53",
      code: "CU",
    },
    {
      label: "Cyprus",
      value: "+357",
      code: "CY",
    },
    {
      label: "Czech Republic",
      value: "+420",
      code: "CZ",
    },
    {
      label: "Denmark",
      value: "+45",
      code: "DK",
    },
    {
      label: "Djibouti",
      value: "+253",
      code: "DJ",
    },
    {
      label: "Dominica",
      value: "+1767",
      code: "DM",
    },
    {
      label: "Dominican Republic",
      value: "+1849",
      code: "DO",
    },
    {
      label: "Ecuador",
      value: "+593",
      code: "EC",
    },
    {
      label: "Egypt",
      value: "+20",
      code: "EG",
    },
    {
      label: "El Salvador",
      value: "+503",
      code: "SV",
    },
    {
      label: "Equatorial Guinea",
      value: "+240",
      code: "GQ",
    },
    {
      label: "Eritrea",
      value: "+291",
      code: "ER",
    },
    {
      label: "Estonia",
      value: "+372",
      code: "EE",
    },
    {
      label: "Ethiopia",
      value: "+251",
      code: "ET",
    },
    {
      label: "Falkland Islands (Malvinas)",
      value: "+500",
      code: "FK",
    },
    {
      label: "Faroe Islands",
      value: "+298",
      code: "FO",
    },
    {
      label: "Fiji",
      value: "+679",
      code: "FJ",
    },
    {
      label: "Finland",
      value: "+358",
      code: "FI",
    },
    {
      label: "France",
      value: "+33",
      code: "FR",
    },
    {
      label: "French Guiana",
      value: "+594",
      code: "GF",
    },
    {
      label: "French Polynesia",
      value: "+689",
      code: "PF",
    },
    {
      label: "Gabon",
      value: "+241",
      code: "GA",
    },
    {
      label: "Gambia",
      value: "+220",
      code: "GM",
    },
    {
      label: "Georgia",
      value: "+995",
      code: "GE",
    },
    {
      label: "Germany",
      value: "+49",
      code: "DE",
    },
    {
      label: "Ghana",
      value: "+233",
      code: "GH",
    },
    {
      label: "Gibraltar",
      value: "+350",
      code: "GI",
    },
    {
      label: "Greece",
      value: "+30",
      code: "GR",
    },
    {
      label: "Greenland",
      value: "+299",
      code: "GL",
    },
    {
      label: "Grenada",
      value: "+1473",
      code: "GD",
    },
    {
      label: "Guadeloupe",
      value: "+590",
      code: "GP",
    },
    {
      label: "Guam",
      value: "+1671",
      code: "GU",
    },
    {
      label: "Guatemala",
      value: "+502",
      code: "GT",
    },
    {
      label: "Guernsey",
      value: "+44",
      code: "GG",
    },
    {
      label: "Guinea",
      value: "+224",
      code: "GN",
    },
    {
      label: "Guinea-Bissau",
      value: "+245",
      code: "GW",
    },
    {
      label: "Guyana",
      value: "+595",
      code: "GY",
    },
    {
      label: "Haiti",
      value: "+509",
      code: "HT",
    },
    {
      label: "Holy See (Vatican City State)",
      value: "+379",
      code: "VA",
    },
    {
      label: "Honduras",
      value: "+504",
      code: "HN",
    },
    {
      label: "Hong Kong",
      value: "+852",
      code: "HK",
    },
    {
      label: "Hungary",
      value: "+36",
      code: "HU",
    },
    {
      label: "Iceland",
      value: "+354",
      code: "IS",
    },
    {
      label: "India",
      value: "+91",
      code: "IN",
    },
    {
      label: "Indonesia",
      value: "+62",
      code: "ID",
    },
    {
      label: "Iran, Islamic Republic of Persian Gulf",
      value: "+98",
      code: "IR",
    },
    {
      label: "Iraq",
      value: "+964",
      code: "IQ",
    },
    {
      label: "Ireland",
      value: "+353",
      code: "IE",
    },
    {
      label: "Isle of Man",
      value: "+44",
      code: "IM",
    },
    {
      label: "Israel",
      value: "+972",
      code: "IL",
    },
    {
      label: "Italy",
      value: "+39",
      code: "IT",
    },
    {
      label: "Jamaica",
      value: "+1876",
      code: "JM",
    },
    {
      label: "Japan",
      value: "+81",
      code: "JP",
    },
    {
      label: "Jersey",
      value: "+44",
      code: "JE",
    },
    {
      label: "Jordan",
      value: "+962",
      code: "JO",
    },
    {
      label: "Kazakhstan",
      value: "+77",
      code: "KZ",
    },
    {
      label: "Kenya",
      value: "+254",
      code: "KE",
    },
    {
      label: "Kiribati",
      value: "+686",
      code: "KI",
    },
    {
      label: "Korea, Democratic People's Republic of Korea",
      value: "+850",
      code: "KP",
    },
    {
      label: "Korea, Republic of South Korea",
      value: "+82",
      code: "KR",
    },
    {
      label: "Kuwait",
      value: "+965",
      code: "KW",
    },
    {
      label: "Kyrgyzstan",
      value: "+996",
      code: "KG",
    },
    {
      label: "Laos",
      value: "+856",
      code: "LA",
    },
    {
      label: "Latvia",
      value: "+371",
      code: "LV",
    },
    {
      label: "Lebanon",
      value: "+961",
      code: "LB",
    },
    {
      label: "Lesotho",
      value: "+266",
      code: "LS",
    },
    {
      label: "Liberia",
      value: "+231",
      code: "LR",
    },
    {
      label: "Libyan Arab Jamahiriya",
      value: "+218",
      code: "LY",
    },
    {
      label: "Liechtenstein",
      value: "+423",
      code: "LI",
    },
    {
      label: "Lithuania",
      value: "+370",
      code: "LT",
    },
    {
      label: "Luxembourg",
      value: "+352",
      code: "LU",
    },
    {
      label: "Macao",
      value: "+853",
      code: "MO",
    },
    {
      label: "Macedonia",
      value: "+389",
      code: "MK",
    },
    {
      label: "Madagascar",
      value: "+261",
      code: "MG",
    },
    {
      label: "Malawi",
      value: "+265",
      code: "MW",
    },
    {
      label: "Malaysia",
      value: "+60",
      code: "MY",
    },
    {
      label: "Maldives",
      value: "+960",
      code: "MV",
    },
    {
      label: "Mali",
      value: "+223",
      code: "ML",
    },
    {
      label: "Malta",
      value: "+356",
      code: "MT",
    },
    {
      label: "Marshall Islands",
      value: "+692",
      code: "MH",
    },
    {
      label: "Martinique",
      value: "+596",
      code: "MQ",
    },
    {
      label: "Mauritania",
      value: "+222",
      code: "MR",
    },
    {
      label: "Mauritius",
      value: "+230",
      code: "MU",
    },
    {
      label: "Mayotte",
      value: "+262",
      code: "YT",
    },
    {
      label: "Mexico",
      value: "+52",
      code: "MX",
    },
    {
      label: "Micronesia, Federated States of Micronesia",
      value: "+691",
      code: "FM",
    },
    {
      label: "Moldova",
      value: "+373",
      code: "MD",
    },
    {
      label: "Monaco",
      value: "+377",
      code: "MC",
    },
    {
      label: "Mongolia",
      value: "+976",
      code: "MN",
    },
    {
      label: "Montenegro",
      value: "+382",
      code: "ME",
    },
    {
      label: "Montserrat",
      value: "+1664",
      code: "MS",
    },
    {
      label: "Morocco",
      value: "+212",
      code: "MA",
    },
    {
      label: "Mozambique",
      value: "+258",
      code: "MZ",
    },
    {
      label: "Myanmar",
      value: "+95",
      code: "MM",
    },
    {
      label: "Namibia",
      value: "+264",
      code: "NA",
    },
    {
      label: "Nauru",
      value: "+674",
      code: "NR",
    },
    {
      label: "Nepal",
      value: "+977",
      code: "NP",
    },
    {
      label: "Netherlands",
      value: "+31",
      code: "NL",
    },
    {
      label: "Netherlands Antilles",
      value: "+599",
      code: "AN",
    },
    {
      label: "New Caledonia",
      value: "+687",
      code: "NC",
    },
    {
      label: "New Zealand",
      value: "+64",
      code: "NZ",
    },
    {
      label: "Nicaragua",
      value: "+505",
      code: "NI",
    },
    {
      label: "Niger",
      value: "+227",
      code: "NE",
    },
    {
      label: "Nigeria",
      value: "+234",
      code: "NG",
    },
    {
      label: "Niue",
      value: "+683",
      code: "NU",
    },
    {
      label: "Norfolk Island",
      value: "+672",
      code: "NF",
    },
    {
      label: "Northern Mariana Islands",
      value: "+1670",
      code: "MP",
    },
    {
      label: "Norway",
      value: "+47",
      code: "NO",
    },
    {
      label: "Oman",
      value: "+968",
      code: "OM",
    },
    {
      label: "Pakistan",
      value: "+92",
      code: "PK",
    },
    {
      label: "Palau",
      value: "+680",
      code: "PW",
    },
    {
      label: "Palestinian Territory, Occupied",
      value: "+970",
      code: "PS",
    },
    {
      label: "Panama",
      value: "+507",
      code: "PA",
    },
    {
      label: "Papua New Guinea",
      value: "+675",
      code: "PG",
    },
    {
      label: "Paraguay",
      value: "+595",
      code: "PY",
    },
    {
      label: "Peru",
      value: "+51",
      code: "PE",
    },
    {
      label: "Philippines",
      value: "+63",
      code: "PH",
    },
    {
      label: "Pitcairn",
      value: "+872",
      code: "PN",
    },
    {
      label: "Poland",
      value: "+48",
      code: "PL",
    },
    {
      label: "Portugal",
      value: "+351",
      code: "PT",
    },
    {
      label: "Puerto Rico",
      value: "+1939",
      code: "PR",
    },
    {
      label: "Qatar",
      value: "+974",
      code: "QA",
    },
    {
      label: "Romania",
      value: "+40",
      code: "RO",
    },
    {
      label: "Russia",
      value: "+7",
      code: "RU",
    },
    {
      label: "Rwanda",
      value: "+250",
      code: "RW",
    },
    {
      label: "Reunion",
      value: "+262",
      code: "RE",
    },
    {
      label: "Saint Barthelemy",
      value: "+590",
      code: "BL",
    },
    {
      label: "Saint Helena, Ascension and Tristan Da Cunha",
      value: "+290",
      code: "SH",
    },
    {
      label: "Saint Kitts and Nevis",
      value: "+1869",
      code: "KN",
    },
    {
      label: "Saint Lucia",
      value: "+1758",
      code: "LC",
    },
    {
      label: "Saint Martin",
      value: "+590",
      code: "MF",
    },
    {
      label: "Saint Pierre and Miquelon",
      value: "+508",
      code: "PM",
    },
    {
      label: "Saint Vincent and the Grenadines",
      value: "+1784",
      code: "VC",
    },
    {
      label: "Samoa",
      value: "+685",
      code: "WS",
    },
    {
      label: "San Marino",
      value: "+378",
      code: "SM",
    },
    {
      label: "Sao Tome and Principe",
      value: "+239",
      code: "ST",
    },
    {
      label: "Saudi Arabia",
      value: "+966",
      code: "SA",
    },
    {
      label: "Senegal",
      value: "+221",
      code: "SN",
    },
    {
      label: "Serbia",
      value: "+381",
      code: "RS",
    },
    {
      label: "Seychelles",
      value: "+248",
      code: "SC",
    },
    {
      label: "Sierra Leone",
      value: "+232",
      code: "SL",
    },
    {
      label: "Singapore",
      value: "+65",
      code: "SG",
    },
    {
      label: "Slovakia",
      value: "+421",
      code: "SK",
    },
    {
      label: "Slovenia",
      value: "+386",
      code: "SI",
    },
    {
      label: "Solomon Islands",
      value: "+677",
      code: "SB",
    },
    {
      label: "Somalia",
      value: "+252",
      code: "SO",
    },
    {
      label: "South Africa",
      value: "+27",
      code: "ZA",
    },
    {
      label: "South Sudan",
      value: "+211",
      code: "SS",
    },
    {
      label: "South Georgia and the South Sandwich Islands",
      value: "+500",
      code: "GS",
    },
    {
      label: "Spain",
      value: "+34",
      code: "ES",
    },
    {
      label: "Sri Lanka",
      value: "+94",
      code: "LK",
    },
    {
      label: "Sudan",
      value: "+249",
      code: "SD",
    },
    {
      label: "Suriname",
      value: "+597",
      code: "SR",
    },
    {
      label: "Svalbard and Jan Mayen",
      value: "+47",
      code: "SJ",
    },
    {
      label: "Swaziland",
      value: "+268",
      code: "SZ",
    },
    {
      label: "Sweden",
      value: "+46",
      code: "SE",
    },
    {
      label: "Switzerland",
      value: "+41",
      code: "CH",
    },
    {
      label: "Syrian Arab Republic",
      value: "+963",
      code: "SY",
    },
    {
      label: "Taiwan",
      value: "+886",
      code: "TW",
    },
    {
      label: "Tajikistan",
      value: "+992",
      code: "TJ",
    },
    {
      label: "Tanzania, United Republic of Tanzania",
      value: "+255",
      code: "TZ",
    },
    {
      label: "Thailand",
      value: "+66",
      code: "TH",
    },
    {
      label: "Timor-Leste",
      value: "+670",
      code: "TL",
    },
    {
      label: "Togo",
      value: "+228",
      code: "TG",
    },
    {
      label: "Tokelau",
      value: "+690",
      code: "TK",
    },
    {
      label: "Tonga",
      value: "+676",
      code: "TO",
    },
    {
      label: "Trinidad and Tobago",
      value: "+1868",
      code: "TT",
    },
    {
      label: "Tunisia",
      value: "+216",
      code: "TN",
    },
    {
      label: "Turkey",
      value: "+90",
      code: "TR",
    },
    {
      label: "Turkmenistan",
      value: "+993",
      code: "TM",
    },
    {
      label: "Turks and Caicos Islands",
      value: "+1649",
      code: "TC",
    },
    {
      label: "Tuvalu",
      value: "+688",
      code: "TV",
    },
    {
      label: "Uganda",
      value: "+256",
      code: "UG",
    },
    {
      label: "Ukraine",
      value: "+380",
      code: "UA",
    },
    {
      label: "United Arab Emirates",
      value: "+971",
      code: "AE",
    },
    {
      label: "United Kingdom",
      value: "+44",
      code: "GB",
    },
    {
      label: "United States",
      value: "+1",
      code: "US",
    },
    {
      label: "Uruguay",
      value: "+598",
      code: "UY",
    },
    {
      label: "Uzbekistan",
      value: "+998",
      code: "UZ",
    },
    {
      label: "Vanuatu",
      value: "+678",
      code: "VU",
    },
    {
      label: "Venezuela, Bolivarian Republic of Venezuela",
      value: "+58",
      code: "VE",
    },
    {
      label: "Vietnam",
      value: "+84",
      code: "VN",
    },
    {
      label: "Virgin Islands, British",
      value: "+1284",
      code: "VG",
    },
    {
      label: "Virgin Islands, U.S.",
      value: "+1340",
      code: "VI",
    },
    {
      label: "Wallis and Futuna",
      value: "+681",
      code: "WF",
    },
    {
      label: "Yemen",
      value: "+967",
      code: "YE",
    },
    {
      label: "Zambia",
      value: "+260",
      code: "ZM",
    },
    {
      label: "Zimbabwe",
      value: "+263",
      code: "ZW",
    },
  ],
  categories: [
    {
      id: 1,
      value: "Tour",
      name: "Tour",
      children: [
        {
          id: 7,
          parent: 1,
          value: "Tour Types",
          name: "Tour Types",
          children: [
            {
              id: 13,
              parent: 7,
              value: "Type of Tours",
              name: "Type of Tours",
              children: [
                {
                  id: 17,
                  parent: 13,
                  value: "Luxury Tours",
                  name: "Luxury Tours",
                },
              ],
            },
            {
              id: 14,
              parent: 7,
              value: "Group Tours",
              name: "Group Tours",
            },
          ],
        },
        {
          id: 8,
          parent: 1,
          value: "Tour Destinations",
          name: "Tour Destinations",
          children: [
            {
              id: 15,
              parent: 8,
              value: "Asia Tours",
              name: "Asia Tours",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      value: "Cruise",
      name: "Cruise",
      children: [
        {
          id: 9,
          parent: 2,
          value: "/config/app.js",
          name: "app.js",
        },
        {
          id: 10,
          parent: 2,
          value: "/config/database.js",
          name: "database.js",
        },
      ],
    },
    {
      id: 3,
      value: "ShoreExcursion",
      name: "ShoreExcursion",
    },
    {
      id: 4,
      value: "Holiday Package",
      name: "Holiday Package",
      children: [
        {
          id: 11,
          parent: 4,
          value: "/public/assets/",
          name: "assets",
          children: [
            {
              id: 16,
              parent: 11,
              value: "/public/assets/style.css",
              name: "style.css",
            },
          ],
        },
        {
          id: 12,
          parent: 4,
          value: "/public/index.html",
          name: "index.html",
        },
      ],
    },

    {
      id: 5,
      value: "Exclusive Package",
      name: "Exclusive Package",
    },
    {
      id: 6,
      value: "Visa",
      name: "Visa",
    },
  ],
  listIcons: [
    {
      label: "Accommodation",
      value: `assets/svg-icons/accommodation.svg`,
    },
    {
      label: "Cruise",
      value: `assets/svg-icons/cruise.svg`,
    },
    {
      label: "First Class",
      value: `assets/svg-icons/first_class.svg`,
    },
    {
      label: "Golf",
      value: `assets/svg-icons/golf.svg`,
    },
    {
      label: "Guide",
      value: `assets/svg-icons/guide.svg`,
    },
    {
      label: "Map",
      value: `assets/svg-icons/map.svg`,
    },
    {
      label: "Local",
      value: `assets/svg-icons/local.svg`,
    },
    {
      label: "Meal",
      value: `assets/svg-icons/meal.svg`,
    },
    {
      label: "Medical",
      value: `assets/svg-icons/medical.svg`,
    },
    {
      label: "Other",
      value: `assets/svg-icons/other-black.svg`,
    },
    {
      label: "Shopping And Cooking",
      value: `assets/svg-icons/shoppingandcookingclass.svg`,
    },
    {
      label: "Sightseeing",
      value: `assets/svg-icons/sightseeing.svg`,
    },
    {
      label: "Spa",
      value: `assets/svg-icons/spa.svg`,
    },
    {
      label: "Therapists",
      value: `assets/svg-icons/therapists.svg`,
    },
    {
      label: "Transport",
      value: `assets/svg-icons/transport.svg`,
    },
    {
      label: "Visa",
      value: `assets/svg-icons/visa-black.svg`,
    },
    {
      label: "Wifi",
      value: `assets/svg-icons/wifi.svg`,
    },
    {
      label: "Flight",
      value: `assets/svg-icons/flight.svg`,
    },
  ],
  transferVia: [
    "On Your Own Arrangement",
    "Domestic Flight",
    "Train",
    "Ferry",
    "Boat",
    "Helicopter",
    "Seaplane",
    "Private Jet",
      "Private Car",
      "Shuttle",
      "International Flight"
  ],
  pickupTransfer: [
    "Private Car",
    "Private Vehicle",
    "Taxi",
    "Seat In Coach",
    "Ferry",
    "Boat",
    "Seaplane",
    "Train",
    "Private Jet",
  ],
  pickUpOption: ["One-Way", "Return"],
  airfareType: ["First Class", "Business", "Economy", "Premium Economy", "Mix"],
  golfCartHire: ["Single", "Shared"],
  productType: [
    {
      label: "Tour",
      value: "tour",
    },
    {
      label: "Golf Package",
      value: "golf",
    },
  ],
  packageCancelType: [
    {
      label: "Passenger",
      value: "PASSENGER",
    },
    {
      label: "Supplier",
      value: "SUPPLIER",
    },
    {
      label: "System",
      value: "SYSTEM",
    },
  ],
  packageAmendmentType: [
    {
      label: "Passenger",
      value: "PASSENGER",
    },
    {
      label: "Supplier",
      value: "SUPPLIER",
    },
  ],
  packageAmendmentStatus: [
    {
      label: "Request",
      value: "REQUEST",
    },
    {
      label: "Processing",
      value: "PROCESSING",
    },
    {
      label: "Accepted",
      value: "ACCEPTED",
    },
    {
      label: "Declined",
      value: "DECLINED",
    },
  ],

  portTransfer: ["inclusion", "exclusion"],
  accessibility: [
    "Public Transport Nearby",
    "Wheelchair Accessible",
    "Stroller / Pram Accesible",
    "Limited Mobility Access",
    "Not suitable for wheelchairs or pushchairs",
    "Limited Accessibility",
    "Limited Sight Accessibility",
  ],

  addedExtras: [
    "Skip-The-Line",
    "Entrance Fees Included",
    "Kids-Go-Free",
    "Fast Track",
    "U-18 Go-Free",
    "Free Cancellation",
  ],
  adventureLevel: ["Extreme / High Adrenaline", "Relaxing"],
  foodAndDrink: ["Local Produce", "Professional Chef"],
  foodAndDrinkIncluded: [
    "Meals Included",
    "Dinner Included",
    "Lunch Included",
    "Breakfast Included",
    "Water",
    "Snacks",
  ],
  goodToKnow: [
    "E-Voucher",
    "Morning",
    "Day Time",
    "Pet Friendly",
    "Passport Required",
    "Printed Voucher",
    "Night Time",
    "Dresscode",
  ],
  language: [
    "English",
    "French",
    "Spanish",
    "Portugese",
    "Polish",

    "Italian",
    "Dutch",
    "German",
    "Mandarin",
    "Japanese",
    "Russian",
  ],
  meetingType: ["Meet On Location", "Pick-Up Included", "Drop-Off Included"],
  season: ["Winter", "Wedding", "Christmas"],
  style: [
    "VIP",
    "City break",
    "City",
    "Outdoor",
    "Luxury",
    "Indoor",
    "Eco-Friendly",
    "Romantic",
    "Rainy Day",
    "Exclusive",
  ],
  theme: ["Art / Culture"],
  timeRequire: ["Full-day", "Day Trip", "Half-day", "Multi-Day"],
  tourType: [
    "Guided",
    "Private",
    "Local Touch",
    "Audio Guide",
    "Self-Guided",
    "Video Guide",
    "Outdoor",
    "Interactive",
    "At A Kitchen",
  ],
  transport: ["Transport Included"],
  travelerType: [
    "Groups",
    "Small Group",
    "Couples",
    "Family Friendly",
    "Child Friendly",
    "Youth",
    "Senior / Retired",
    "Adult Only",
  ],
  orderStatus: [
    { label: "Pending", value: "PENDING" },
    { label: "Requested", value: "REQUESTED" },
    { label: "Confirmed", value: "CONFIRMED" },
    { label: "Credit On Hold", value: "CREDIT_ON_HOLD" },
    { label: "Cancel Request", value: "CANCEL_REQUEST" },
    { label: "Cancelled", value: "CANCELLED" },
    { label: "Amendment Request", value: "AMENDMENT_REQUEST" },
    { label: "Amendment Declined", value: "AMENDMENT_DECLINED" }
  ],

};
