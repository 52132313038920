import { Box } from "@mui/material";
import React, { useState } from "react";
import ContentComponent from "../Editor/ContentComponent";
interface Props {
  value: string;
  onChange: (value: string) => void;
  index?: number;
  isCreate: boolean;
  isEdit: boolean;
  height?: number;
  rows?: number;
  error?: boolean;
}
const TextAreaComponent = ({
  value,
  onChange,
  index,
  isCreate,
  isEdit,
  height = 400,
  rows = 8,
  error
}: Props) => {
  const [isEditText, setIsEditText] = useState(false);

  return (
    <>
    <ContentComponent error={error} data={value} onChange={onChange} disabled={!isEdit && !isCreate} heightContent={`${height}px`} />
      {/* {isEditText ? (
        <ContentComponent error={error} data={value} onChange={onChange} disabled={!isEdit && !isCreate} heightContent={`${height}px`} />
      ) : (
        <Box

          sx={{
            width: "100%",
            height: height,
            overflowY: "auto",
            resize: "block",
            p: 3,
            fontSize: "15px",
            borderRadius: "8px",
            border: `1px solid ${!!error ? "#dc3545" : "#ccc"}`,
            bgcolor: isEdit || isCreate ? "#fff" : "#f5f5f5",
          }}
          onClick={() => {
            if (isEdit || isCreate) {
              setIsEditText(true);
            }
          }}

          className=""
          dangerouslySetInnerHTML={{
            __html: value ? value: "Click to edit",
          }}
        />
      )} */}
    </>
  );
};

export default TextAreaComponent;
