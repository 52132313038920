import "./styles/main.scss";
import React, { useState, Suspense } from "react";
import 'react-quill/dist/quill.snow.css';

import { RouterProvider } from "react-router-dom";
import router from "./routes";
import {
  ThemeProvider,
  createTheme,
  Box,
  CircularProgress,
} from "@mui/material";
import {
  CompanyEditModalProps,
  ContactEditModalProps,
  MyGlobalContext,
  PackageProgramProps,
  PackagePurchaseProgramProps,
} from "./context/globalContext";
import { LicenseInfo } from "@mui/x-date-pickers-pro";
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY as string);
function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#174D75",
      },
    },
    components: {
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            "& .MuiSvgIcon-root": {
              width: ".8em",
              height: ".8em",
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          listbox: {
            fontSize: 14,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& fieldset": {
              borderColor: "#d5d5d5",
            },
            "&.MuiInputBase-adornedStart input": {
              borderLeft: "1px solid #d5d5d5",
              borderTopLeftRadius: "0 !important",
              borderBottomLeftRadius: "0 !important",
            },
            "&.MuiInputBase-adornedStart.MuiAutocomplete-inputRoot input": {
              borderLeft: "none",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: "primary",
            },
            "&.MuiInputLabel-root": {
              fontWeight: 600,
              color: "black",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
    },
  });
  const [productType, setProductType] = useState<
    {
      label: string;
      value: string;
    }[]
  >([
    {
      label: "Tour",
      value: "tour",
    },
    {
      label: "Golf Package",
      value: "golf-package",
    },
  ]);
  const [language, setLanguage] = useState<{
    label: string;
    value: string;
  }>({
    label: "English",
    value: "en",
  });
  const [currency, setCurrency] = useState<{
    label: string;
    value: string;
  }>({
    label: "USD",
    value: "$",
  });

  const [mode, setMode] = useState(localStorage.getItem("mode") ?? "light");
  const [companyName, setCompanyName] = useState(
    localStorage.getItem("companyName") ?? ""
  );
  const [companyLogo, setCompanyLogo] = useState(
    localStorage.getItem("companyLogo") ?? ""
  );
  const [companyEditModal, setCompanyEditModal] =
    useState<CompanyEditModalProps>({
      companyId: null,
      openEditUser: false,
      showDetail: false,
      isEditButton: false,
    });
  const [contactEditModal, setContactEditModal] =
    useState<ContactEditModalProps>({
      contactId: null,
      openEditUser: false,
      showDetail: false,
      isEditButton: false,
      editInReservation: false,
      getListContact: () => { },
    });
  const [showPackageProgram, setShowPackageProgram] =
    useState<PackageProgramProps>({
      packageItem: null,
      isOpenPackageProgram: false,
      isOpenEditProgram: false,
      disabled: false,
      type: "",
      reservation: null,
      showActionButtons: false,
    });
  const [showPackagePurchaseProgram, setShowPackagePurchaseProgram] =
    useState<PackagePurchaseProgramProps>({
      packageItem: null,
      isOpenPackageProgram: false,
      isOpenEditProgram: false,
      disabled: false,
      type: "",
      reservation: null,
      showActionButtons: false,
    });
  const [newNotification, setNewNotification] = useState(false);

  const [openChartUser, setOpenChartUser] = useState(false);

  const [userGuide, setUserGuide] = useState(false);

  return (
    // <React.StrictMode>
    <MyGlobalContext.Provider
      value={{
        mode,
        setMode,
        productType,
        setProductType,
        language,
        setLanguage,
        currency,
        setCurrency,
        companyName,
        setCompanyName,
        companyLogo,
        setCompanyLogo,
        companyEditModal,
        setCompanyEditModal,
        contactEditModal,
        setContactEditModal,
        showPackageProgram,
        setShowPackageProgram,
        showPackagePurchaseProgram,
        setShowPackagePurchaseProgram,
        newNotification,
        setNewNotification,
        openChartUser,
        setOpenChartUser,
        userGuide,
        setUserGuide,
      }}
    >
      <ThemeProvider theme={theme}>
        <Suspense
          fallback={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                width: "100vw",
                backgroundColor: "transparent",
              }}
            >
              <CircularProgress sx={{ color: "black" }} />
            </Box>
          }
        >
          <RouterProvider router={router} />
        </Suspense>
      </ThemeProvider>
    </MyGlobalContext.Provider>
    // </React.StrictMode>
  );
}

export default App;
